import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUser } from "./context";
import Login from './auth/Login';
import Home from './Home';
import Signup from './auth/Signup';
import VerificationPage from './auth/VerificationPage';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Verify from './auth/Verify';
import PhoneVerify from './auth/PhoneVerify';
import ChangeEmail from './auth/ChangeEmail ';
import ChangeNumber from './auth/changeNumber';
import Kyc from './Kyc';
import Profile from './Profile/Profile';
import Dashboard from './dashboard';
import Unauthorized from './Unauthorized';
import Navbar from './Navbar';
import Footer from './Footer';
import ProtectedRoute from './ProtectedRoute';
import WalletDashboard from './wallet/WalletDashboard';
import TransferBonusDash from './wallet/transactions/TransferBonusDash';
import TransferImpactPointsDash from './wallet/transactions/TransferImpactPointsDash';
import PurchasePage from './wallet/PurchasePage';
import Chat from './wallet/Chat';
import SellerPortal from './wallet/SellerPortal';
import SellerHistory from './wallet/SellerHistory';
import Donations from './Donations';
import DonationsPreviewPage from './DonationsPreviewPage';
import EditDonationLink from './EditDonationLink';
import CreateImpact from './CreateImpact';
import ImpactDetail from './ImpactDetail';
import AdminPanelContainer from './Admin/AdminPanelContainer';
import ReviewList from './Admin/Reviews/ReviewList';
import UserDetails from './Admin/customers/UserDetails';
import PendingWithdrawals from './Admin/customers/PendingWithdrawals';
import Deposits from './Admin/customers/Deposits';
import Reminders from './Admin/customers/Reminders';
import CampaignCreate from './Admin/campaigns/CampaignCreate';
import CampaignList from './Admin/campaigns/CampaignList';
import CampaignEdit from './Admin/campaigns/CampaignEdit';
import DonationLinks from './Admin/donations/DonationLinks';
import DonationLinkDetail from './Admin/donations/DonationLinkDetail';
import DonationLinkEdit from './Admin/donations/DonationLinkEdit';
import GrantsList from './Admin/grants/AdminGrantList';
import GrantDetails from './Admin/grants/AdminGrantDetails';
import UserEdit from './Admin/customers/UserEdit';
import KYCEdit from './Admin/customers/KYCEdit';
import EditReview from './Admin/Reviews/EditReview';
import PostReview from './Admin/Reviews/PostReview';
import HelpPage from './Help/Help';
import AdminHelpPage from './Help/AdminHelpPage';
import EditFaq from './Help/EditFaq';
import PostFaq from './Help/PostFaq';
import AboutUsPage from './Help/AboutUs';
import HowToApply from './HowToApply';

const AppLayout = () => {
  const { user } = useUser();
  const location = useLocation();
  const excludedRoutes = ['/login', '/signup', '/KYC', '/kyc', '/changeEmail', '/donate/*', '/forgot-password', '/verification', '/reset-password', '/verify', '/phone-verify', '/changeNumber', '/not-authorized'];
  const dynamicRoutesToExclude = ['/donate', '/donation-link'];

  const isExcludedRoute = excludedRoutes.includes(location.pathname) ||
    dynamicRoutesToExclude.some(route => location.pathname.startsWith(route));

  useEffect(() => {
    // Google Analytics setup
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=G-2QMM6FDKBF`;
    document.head.appendChild(gtagScript);

    const gtagInitScript = document.createElement('script');
    gtagInitScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-2QMM6FDKBF');
      `;
    document.head.appendChild(gtagInitScript);
  }, []);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('reattach_activator');
      window.Intercom('update', intercomSettings());
    } else {
      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', intercomSettings());
        } else {
          const i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;

          const s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/dme65p1h";
          const x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        }
      })();
    }

    return () => {
      // Find the Intercom script in the document
      const intercomScript = document.querySelector('script[src="https://widget.intercom.io/widget/dme65p1h"]');
      if (intercomScript && intercomScript.parentNode) {
        // Remove the script element from its parent node
        intercomScript.parentNode.removeChild(intercomScript);
      }

      // Shutdown Intercom session when component unmounts
      window.Intercom && window.Intercom('shutdown');
    };
  }, []);

  useEffect(() => {
    if (window.Intercom && user) {
      window.Intercom('update', intercomSettings());
    }
  }, [user]);

  function intercomSettings() {
    return {
      api_base: "https://api-iam.intercom.io",
      app_id: "dme65p1h",
      name: user ? `${user.primaryInfo?.firstName} ${user.primaryInfo?.lastName}` : '',
      email: user ? user.primaryInfo?.email : '',
      user_id: user ? user._id : '',
      created_at: user ? user.createdAt : '',
      pay_id: user ? user?.payId : '',
      phone: user ? user.phoneNumber : ''
    };
  }







  return (
    <>
      {!isExcludedRoute && <Navbar />}
      <div className={!isExcludedRoute ? "pt-12 md:pt-14 font-harmattan font-12" : ""} >
        <Routes>
          {/* Standard User Routes */}
          <Route path="*" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/not-authorized" element={<Unauthorized />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/phone-verify" element={<PhoneVerify />} />
          <Route path="/kyc" element={<Kyc />} />
          <Route path="/changeNumber" element={<ChangeNumber />} />
          <Route path="/changeEmail" element={<ChangeEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/wallet" element={<ProtectedRoute><WalletDashboard /></ProtectedRoute>} />
          <Route path="/purchase" element={<ProtectedRoute><PurchasePage /></ProtectedRoute>} />
          <Route path="/chat/:tradeId" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
          <Route path="/portal/:tradeId" element={<ProtectedRoute><SellerPortal /></ProtectedRoute>} />
          <Route path="/donate/:uniqueIdentifier" element={<Donations />} />
          <Route path="/donation-link/:id" element={<ProtectedRoute><DonationsPreviewPage /></ProtectedRoute>} />
          <Route path="/link/edit/:id" element={<ProtectedRoute><EditDonationLink /></ProtectedRoute>} />
          <Route path="/transferImpact" element={<ProtectedRoute><TransferImpactPointsDash /></ProtectedRoute>} />
          <Route path="/CreateImpact" element={<ProtectedRoute><CreateImpact /></ProtectedRoute>} />
          <Route path="/impact/:id" element={<ImpactDetail />} />
          <Route path="/help" element={<ProtectedRoute><HelpPage /></ProtectedRoute>} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/how-to-apply" element={<HowToApply />} />

          {/* Admin-specific Routes - Protected and only accessible by admins */}
          <Route path="/hamerling" element={<ProtectedRoute isAdminRoute={true}><AdminPanelContainer /></ProtectedRoute>} />
          <Route path="/brookebo" element={<ProtectedRoute isAdminRoute={true}><TransferBonusDash /></ProtectedRoute>} />

          <Route path="/sellerTrades" element={<ProtectedRoute isAdminRoute={true}><SellerHistory /></ProtectedRoute>} />
          <Route path="/user-details/:userId" element={<ProtectedRoute isAdminRoute={true}><UserDetails /></ProtectedRoute>} />
          <Route path="/pending-withdrawals" element={<ProtectedRoute isAdminRoute={true}><PendingWithdrawals /></ProtectedRoute>} />
          <Route path="/user-deposits" element={<ProtectedRoute isAdminRoute={true}><Deposits /></ProtectedRoute>} />
          <Route path="/reminders" element={<ProtectedRoute isAdminRoute={true}><Reminders /></ProtectedRoute>} />
          <Route path="/campaignCreate" element={<ProtectedRoute isAdminRoute={true}><CampaignCreate /></ProtectedRoute>} />
          <Route path="/campaigns" element={<ProtectedRoute isAdminRoute={true}><CampaignList /></ProtectedRoute>} />
          <Route path="/campaigns-edit/:id" element={<ProtectedRoute isAdminRoute={true}><CampaignEdit /></ProtectedRoute>} />
          <Route path="/all-donation-links" element={<ProtectedRoute isAdminRoute={true}><DonationLinks /></ProtectedRoute>} />
          <Route path="/single-donation-link-details/:linkId" element={<ProtectedRoute isAdminRoute={true}><DonationLinkDetail /></ProtectedRoute>} />
          <Route path="/edit-donation-links/:linkId" element={<ProtectedRoute isAdminRoute={true}><DonationLinkEdit /></ProtectedRoute>} />
          <Route path="/user-edit/:userId" element={<ProtectedRoute isAdminRoute={true}><UserEdit /></ProtectedRoute>} />
          <Route path="/kyc-edit/:userId" element={<ProtectedRoute isAdminRoute={true}><KYCEdit /></ProtectedRoute>} />
          <Route path="/ReviewList" element={<ProtectedRoute isAdminRoute={true}><ReviewList /></ProtectedRoute>} />
          <Route path="/edit-review/:reviewId" element={<ProtectedRoute isAdminRoute={true}><EditReview /></ProtectedRoute>} />
          <Route path="/post-review" element={<ProtectedRoute isAdminRoute={true}><PostReview /></ProtectedRoute>} />
          <Route path="/Adminhelp" element={<ProtectedRoute isAdminRoute={true}><AdminHelpPage /></ProtectedRoute>} />
          <Route path="/edit-faq/:faqId" element={<ProtectedRoute isAdminRoute={true}><EditFaq /></ProtectedRoute>} />
          <Route path="/post-faq" element={<ProtectedRoute isAdminRoute={true}><PostFaq /></ProtectedRoute>} />
          <Route path="/admin/grants" element={<ProtectedRoute isAdminRoute={true}><GrantsList /></ProtectedRoute>} />
          <Route path="/admin/grants/:grantId" element={<ProtectedRoute isAdminRoute={true}><GrantDetails /></ProtectedRoute>} />
        </Routes>
      </div>
      {!isExcludedRoute && <Footer />}
    </>
  );
};

export default AppLayout;
