// UserBadges.js

import React from 'react';
import { FaHandsHelping, FaStar } from 'react-icons/fa';

const badgeData = [
  {
    id: 1,
    name: 'Community Helper',
    description: 'Recognized for outstanding community support.',
    icon: <FaHandsHelping className="text-4xl" />,
    gradient: 'from-blue-500 to-teal-500',
  },
  {
    id: 2,
    name: 'Top Contributor',
    description: 'Awarded for exceptional contributions.',
    icon: <FaStar className="text-4xl" />,
    gradient: 'from-yellow-500 to-red-500',
  },
];

const UserBadges = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
      {badgeData.map((badge) => (
        <div
          key={badge.id}
          className="relative flex flex-col items-center bg-white p-4 rounded-lg shadow-lg overflow-hidden"
        >
          <div
            className={`absolute inset-0 bg-gradient-to-r ${badge.gradient} opacity-20`}
          ></div>
          <div className="relative z-10 p-4 rounded-full bg-white shadow-md mb-2">
            {badge.icon}
          </div>
          <h4 className="relative z-10 text-md font-semibold text-gray-800 text-center">{badge.name}</h4>
          <p className="relative z-10 text-sm text-gray-500 text-center">{badge.description}</p>
        </div>
      ))}
    </div>
  );
};

export default UserBadges;
