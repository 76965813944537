import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AssociatedAccounts = ({ userId }) => {
    const { user } = useUser();
    const [associatedAccounts, setAssociatedAccounts] = useState([]);
    const navigate = useNavigate();

    const fetchAssociatedAccounts = async () => {
        try {
            const response = await api.get(`/api/customer/${userId}/associated-accounts`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (response.status === 200) {
                setAssociatedAccounts(response.data);
            } else {
                toast.error('Failed to load associated accounts');
            }
        } catch (error) {
            console.error("Error fetching associated accounts: ", error);
            toast.error(`Failed to load associated accounts: ${error.message}`);
        }
    };

    useEffect(() => {
        fetchAssociatedAccounts();
    }, [userId, user.token]);

    const toggleBan = async (accountId, isBanned) => {
        try {
            await api.patch(`/api/admin/${accountId}/toggleBan`, {}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            toast.success(`User ${isBanned ? 'unbanned' : 'banned'} successfully`);
            fetchAssociatedAccounts(); // Refresh the list after toggling ban
        } catch (error) {
            const message = error.response?.data?.message || "Failed to toggle ban status";
            toast.error(message);
        }
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <h3 className="font-semibold text-lg mb-4">Associated Accounts</h3>
            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">Username</th>
                            <th className="px-4 py-2">Email</th>
                            <th className="px-4 py-2">Phone Number</th>
                            <th className="px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(associatedAccounts) && associatedAccounts.length > 0 ? (
                            associatedAccounts.map((account) => (
                                <tr key={account._id} className="text-xs border-b">
                                    <td className="px-4 py-2">{account.username}</td>
                                    <td className="px-4 py-2">{account.email}</td>
                                    <td className="px-4 py-2">{account.phoneNumber}</td>
                                    <td className="px-4 py-2">
    <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
        <button
            onClick={() => navigate(`/user-details/${account._id}`)}
            className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100 w-full md:w-auto"
        >
            View More
        </button>
        <button
            onClick={() => toggleBan(account._id, account.isBanned)}
            className={`px-4 py-2 rounded text-xs w-full md:w-auto ${
                account.isBanned
                    ? 'bg-red-500 text-white'
                    : 'bg-green-500 text-white'
            }`}
        >
            {account.isBanned ? 'Unban' : 'Ban'}
        </button>
    </div>
</td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center py-4">
                                    No associated accounts
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AssociatedAccounts;
