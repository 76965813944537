import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import WithdrawalHistory from './WithdrawalHistory';
import TradeHistory from './TradeHistory';
import TransferHistory from './TransferHistory';
import WithdrawalDetail from './WithdrawalDetail';
import TransferDetail from '../transactions/TransferDetail';
import DepositHistory from '../deposits/DepositHistory';
import DepositDetail from '../deposits/DepositDetail';
import ConvertHistory from '../conversion/ConvertHistory';
import ConversionDetail from '../conversion/ConversionDetail';

const HistoryContainer = () => {
    const [activeTab, setActiveTab] = useState('withdrawHistory');
    const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);
    const [selectedTransferId, setSelectedTransferId] = useState(null);
    const [selectedDepositId, setSelectedDepositId] = useState(null);
    const [selectedConversionId, setSelectedConversionId] = useState(null);

    const handleViewDetails = (id, type) => {
        if (type === 'withdraw') {
            setSelectedWithdrawalId(id);
        } else if (type === 'transfer') {
            setSelectedTransferId(id);
        } else if (type === 'deposit') {
            setSelectedDepositId(id);
        } else if (type === 'conversion') {
            setSelectedConversionId(id);
        }
        setActiveTab('detail');
    };

    const handleBackToHistory = (type) => {
        if (type === 'withdraw') {
            setSelectedWithdrawalId(null);
        } else if (type === 'transfer') {
            setSelectedTransferId(null);
        } else if (type === 'deposit') {
            setSelectedDepositId(null);
        } else if (type === 'conversion') {
            setSelectedConversionId(null);
        }
        setActiveTab(`${type}History`);
    };

    const tabOptions = [
        { label: 'Withdrawal History', value: 'withdrawHistory' },
        { label: 'Transfer History', value: 'transferHistory' },
        { label: 'Trade History', value: 'tradeHistory' },
        { label: 'Deposit History', value: 'depositHistory' },
        { label: 'Conversion History', value: 'conversionHistory' },
    ];

    return (
        <>
            {selectedWithdrawalId && (
                <WithdrawalDetail withdrawalId={selectedWithdrawalId} onBack={() => handleBackToHistory('withdraw')} />
            )}
            {selectedTransferId && (
                <TransferDetail transferId={selectedTransferId} onBack={() => handleBackToHistory('transfer')} />
            )}
            {selectedDepositId && (
                <DepositDetail depositId={selectedDepositId} onBack={() => handleBackToHistory('deposit')} />
            )}
            {selectedConversionId && (
                <ConversionDetail conversionId={selectedConversionId} onBack={() => handleBackToHistory('conversion')} />
            )}

            {!selectedWithdrawalId && !selectedTransferId && !selectedDepositId && !selectedConversionId && (
                <div className="container flex-grow mx-auto p-4 bg-white rounded-lg shadow-md">
                    <div className="flex flex-col sm:flex-row mb-4 border-b">
                        {/* Dropdown for small screens */}
                        <Menu as="div" className="relative sm:hidden w-full mb-4">
                            <Menu.Button className="flex justify-between w-full px-4 py-2 border rounded text-sm font-medium text-gray-700 transition-colors duration-300 hover:bg-emerald-50">
                                {tabOptions.find(tab => tab.value === activeTab)?.label || 'Select History'}
                                <ChevronDownIcon
                                    className="w-5 h-5 ml-2 text-emerald-500 animate-bounce"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Menu.Items className="absolute w-full mt-1 bg-white border rounded shadow-lg">
                                {tabOptions.map(tab => (
                                    <Menu.Item key={tab.value}>
                                        {({ active }) => (
                                            <button
                                                onClick={() => setActiveTab(tab.value)}
                                                className={`${
                                                    active ? 'bg-emerald-100' : ''
                                                } w-full text-left px-4 py-2 text-sm text-gray-700`}
                                            >
                                                {tab.label}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Menu>

                        {/* Tabs for larger screens */}
                        <div className="hidden sm:flex space-x-4">
                            {tabOptions.map(tab => (
                                <button
                                    key={tab.value}
                                    onClick={() => setActiveTab(tab.value)}
                                    className={`py-2 px-4 font-medium text-sm sm:text-base ${
                                        activeTab === tab.value
                                            ? 'border-b-2 border-emerald-500 text-emerald-600'
                                            : 'border-transparent hover:border-gray-300 hover:text-gray-600'
                                    }`}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                    </div>

                    {activeTab === 'withdrawHistory' && <WithdrawalHistory onViewDetails={(id) => handleViewDetails(id, 'withdraw')} />}
                    {activeTab === 'transferHistory' && <TransferHistory onViewDetails={(id) => handleViewDetails(id, 'transfer')} />}
                    {activeTab === 'depositHistory' && <DepositHistory onViewDetails={(id) => handleViewDetails(id, 'deposit')} />}
                    {activeTab === 'conversionHistory' && <ConvertHistory onViewDetails={(id) => handleViewDetails(id, 'conversion')} />}
                    {activeTab === 'tradeHistory' && <TradeHistory />}
                </div>
            )}
        </>
    );
};

export default HistoryContainer;
