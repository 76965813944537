import React, { useState, useEffect } from 'react';
import api from '../api';
import Lottie from "lottie-react";
import { useNavigate } from 'react-router-dom';
import successAnimation from "./lottie/success-animation.json";
import successConfetti from './lottie/success-confetti.json';
import { FaSpinner } from 'react-icons/fa';
import { useUser } from "./context";

// Configuration object for country-specific KYC fields
const KYC_CONFIG = {
    US: {
        fields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode', 'timezone'],
        fieldLabels: {
            ssn: 'Social Security Number',
            firstName: 'First Name',
            lastName: 'Last Name',
            dob: 'Date of Birth',
            phone: 'Phone Number',
            email: 'Email',
            streetAddress: 'Street Address',
            city: 'City',
            state: 'State',
            postalCode: 'ZIP Code',
            countryCode: 'Country Code',
            timezone: 'Timezone',
        },
        mandatoryFields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'streetAddress', 'city', 'state', 'postalCode'],
    },
    UK: {
        fields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'county', 'postalCode', 'countryCode', 'timezone'],
        fieldLabels: {
            nationalInsurance: 'National Insurance Number',
            firstName: 'First Name',
            lastName: 'Last Name',
            dob: 'Date of Birth',
            phone: 'Phone Number',
            email: 'Email',
            streetAddress: 'Street Address',
            city: 'City/Town',
            county: 'County',
            postalCode: 'Postal Code',
            countryCode: 'Country Code',
            timezone: 'Timezone',
        },
        mandatoryFields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'streetAddress', 'city', 'county', 'postalCode'],
    },
    KE: {
        fields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'town', 'region', 'countryCode', 'timezone'],
        fieldLabels: {
            idNumber: 'ID Number',
            firstName: 'First Name',
            lastName: 'Last Name',
            dob: 'Date of Birth',
            phone: 'Phone Number',
            email: 'Email',
            town: 'Town/City',
            region: 'County',
            countryCode: 'Country Code',
            timezone: 'Timezone',
        },
        mandatoryFields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'town', 'region'],
    },
    fallback: {
        fields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'city', 'region', 'countryCode', 'timezone'],
        fieldLabels: {
            identificationNumber: 'Identification Number',
            firstName: 'First Name',
            lastName: 'Last Name',
            dob: 'Date of Birth',
            phone: 'Phone Number',
            email: 'Email',
            city: 'City/Town',
            region: 'Region',
            countryCode: 'Country Code',
            timezone: 'Timezone',
        },
        mandatoryFields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'city', 'region'],
    }
};





// Function to get the locality (town or city) from coordinates using Google Maps API
const getLocationName = async (latitude, longitude) => {
    try {
        const apiKey = 'AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE'; // Replace with your Google Maps API key
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`);
        const data = await response.json();

        if (data.status === "OK") {
            const locality = data.results.find(result =>
                result.types.includes("locality") ||
                result.types.includes("administrative_area_level_2")
            );
            return locality ? locality.formatted_address : data.results[0]?.formatted_address || 'Unknown Location';
        }
        return 'Unknown Location';
    } catch (error) {
        console.error("Error getting location name:", error);
        return 'Unknown Location';
    }
};

const Kyc = () => {
    const navigate = useNavigate();
    const { user, login } = useUser();
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [locationPermissionStatus, setLocationPermissionStatus] = useState(null);
    const [kycDetails, setKycDetails] = useState({
        firstName: '',
        lastName: '',
        phone: user?.phoneNumber || '',
        email: user?.email || '',
        town: '',
        city: '',
        region: '',
        dob: '',
        idNumber: '',
        ssn: '',
        nationalInsurance: '',
        identificationNumber: '',
        countryCode: '',
        timezone: '',
        location: '' // Stores latitude, longitude
    });

    // Function to get the user's current location
    const getUserLocation = () => {
        if (!navigator.geolocation) {
            setError("Geolocation is not supported by your browser.");
        } else {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    // Ensure [longitude, latitude] order
                    setKycDetails((prevDetails) => ({
                        ...prevDetails,
                        location: { longitude, latitude }
                    }));

                    try {
                        // Optionally fetch location name from coordinates
                        const locationName = await getLocationName(latitude, longitude);
                        setKycDetails((prevDetails) => ({
                            ...prevDetails,
                            town: locationName
                        }));
                    } catch (error) {
                        setError("Failed to fetch location name, please reload the page.");
                    }
                    setShowModal(false);
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        handleDeniedLocation();
                    } else {
                        setError("Failed to get location, please reload the page.");
                    }
                    setShowModal(false);
                },
                { timeout: 10000 }
            );
        }
    };

    // Handle denied location permissions
    const handleDeniedLocation = () => {
        setError(
            <>
                <p>Location access was denied. To re-enable it, follow these steps:</p>
                <ol className="list-decimal ml-4">
                    <li>Click the three vertical dots on the top-right corner of the browser.</li>
                    <li>Select "Settings", navigate to "Privacy and security", and find "Location".</li>
                    <li>Under "Blocked sites", change the setting to "Allow".</li>
                    <li>Refresh the page and try again.</li>
                </ol>
            </>
        );
    };

    const requestLocationPermission = () => {
        getUserLocation(); // Reuse getUserLocation for requesting location permission
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKycDetails({ ...kycDetails, [name]: value });
    };

    // Check location permission and trigger the modal if needed
    useEffect(() => {
        const checkLocationPermission = async () => {
            try {
                if (navigator.permissions && navigator.permissions.query) {
                    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                    setLocationPermissionStatus(permissionStatus.state);
                    
                    if (permissionStatus.state === 'granted') {
                        // Permission granted, get location immediately
                        getUserLocation();
                    } else if (permissionStatus.state === 'prompt') {
                        // Show the modal to ask for permission
                        setShowModal(true);
                    } else if (permissionStatus.state === 'denied') {
                        handleDeniedLocation();
                    }
                } else {
                    // Fallback: Directly request geolocation for older browsers
                    getUserLocation();
                }
            } catch (err) {
                console.error('Error checking location permission:', err);
            }
        };

        checkLocationPermission();
    }, []);

    // Fetch IP info and location
    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        } else {
            // Fetch location info from ipinfo.io
            fetch("https://ipinfo.io/json?token=bff95b04d027fd")
                .then(response => response.json())
                .then(data => {
                    setKycDetails((prevDetails) => ({
                        ...prevDetails,
                        countryCode: data.country,
                        city: data.city,
                        town: data.city,
                        region: data.region,
                        timezone: data.timezone // Set timezone from ipinfo
                    }));
                })
                .catch(err => {
                    setError("Failed to fetch location name, please reload the page.");
                    console.error("Error fetching location:", err);
                });
        }
    }, [user]);

    const getConfigForCountry = () => {
        const { countryCode } = kycDetails;
        if (countryCode === 'UK' || countryCode === 'GB') {
            return KYC_CONFIG.UK;
        }
        return KYC_CONFIG[countryCode] || KYC_CONFIG.fallback;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);
        setIsSuccessful(false);
    
        // Check if location is set properly before submission
        if (!kycDetails.location || !kycDetails.location.latitude || !kycDetails.location.longitude) {
            setError('Location is required. Please enable location access.');
            setIsSubmitting(false);
            return;
        }
    
        const userToken = user?.token;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        };
    
        try {
            const response = await api.post('/api/auth/submit-kyc', kycDetails, config);
            if (response.status === 201 || response.status === 200) {
                // Update user context with new primary info after successful KYC submission
                login({ ...user, primaryInfo: response.data.primaryInfo });
    
                setIsSuccessful(true);
            } else {
                setError('Unexpected response from the server. Please try again.');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to submit KYC data. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
    

   // Dynamic Form Rendering
const renderFormFields = () => {
    const config = getConfigForCountry();
    return config.fields.map((field) => (
        <div key={field} className="mb-2">
            <label htmlFor={field} className="block text-sm font-medium text-gray-700 text-xs">
                {config.fieldLabels[field]}
            </label>
            <input
                type={field === 'dob' ? 'date' : 'text'}
                name={field}
                value={kycDetails[field]}
                onChange={handleChange}
                autoComplete={['city', 'region', 'town', 'countryCode'].includes(field) ? 'off' : 'new-password'}
                disabled={['phone', 'email', 'countryCode', 'timezone'].includes(field)} // Disable interaction with readonly fields
                className={`w-full p-2 border rounded focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 ${['phone', 'email', 'countryCode', 'timezone'].includes(field) ? 'bg-gray-100' : ''}`}
                placeholder={`Enter ${config.fieldLabels[field]}`}
                required={config.mandatoryFields.includes(field)}
            />
        </div>
    ));
};


    return (
        <div className="container mx-auto my-10 p-2 sm:p-4 bg-white rounded-lg shadow-lg text-xs border border-gray-300 sm:mx-2 lg:mx-auto">
            <h2 className="text-lg font-bold mb-4 text-center border-b-2 border-dotted border-gray-400 pb-2">
                KYC Verification
            </h2>

            {error && (
                <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700 text-xs">
                    <p>{error}</p>
                </div>
            )}

            {!isSuccessful ? (
                <>
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {renderFormFields()}
                        <div className="col-span-1 md:col-span-2">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`w-full border py-2 px-4 rounded transition duration-300 ${isSubmitting ? 'bg-emerald-500 text-white' : 'border-emerald-500 text-emerald-500 hover:bg-emerald-500 hover:text-white'
                                    }`}
                            >
                                {isSubmitting ? (
                                    <>
                                        <FaSpinner className="animate-spin inline mr-2" />
                                        Submitting...
                                    </>
                                ) : (
                                    'Submit KYC'
                                )}
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <div className="flex flex-col items-center justify-center w-full p-4">
                    {/* Success Animations */}
                    <div className="relative w-full h-64 md:h-96">
                        <Lottie animationData={successConfetti} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                        <Lottie animationData={successAnimation} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
                    </div>
                    <p className="text-lg font-semibold text-emerald-700 mt-4 text-center">
                        KYC Submitted Successfully!
                    </p>
                    <button
                        onClick={() => navigate('/wallet')}
                        className="mt-4 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm bg-emerald-500 bg-opacity-10"
                    >
                        Done
                    </button>
                </div>
            )}

            {/* Modal for Location Permission */}
            {showModal && (
                <div className="fixed z-50 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
                        <h2 className="text-lg font-bold mb-4">Location Access Needed</h2>

                        <p className="text-gray-600 mb-4">
                            Verdant Charity needs access to your location to ensure the security and authenticity of your account.
                        </p>

                        <div className="bg-gray-100 p-4 border border-gray-300 rounded-lg flex items-start text-left mb-4">
                            <span className="text-blue-500 mr-3 text-xl">ℹ️</span>
                            <p className="text-gray-600 text-xs">
                                In cases where you deny the permission, you will not be able to proceed. Location is part of what we record to ensure compliance and security.
                            </p>
                        </div>

                        <button
                            onClick={requestLocationPermission}
                            className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 transition duration-300"
                        >
                            Grant Location Access
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Kyc;
