// DonationsSummary.js
import React, { useState } from 'react';
import DonationChart from './DonationChart';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { MdAdd } from 'react-icons/md';
import CreateDonationLink from './CreateDonationLink';
import SummaryCards from './SummaryCards';
import { useUser } from "./context";
import Lottie from "lottie-react";
import emptyAnimation from "./lottie/Empty-box.json";
import RightSidebar from './RightSidebar2';
import { FaFilePdf, FaDownload } from 'react-icons/fa';

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);

const DonationsSummary = ({ setActiveComponent }) => {
    const { user, login } = useUser();
    const [showCreateLink, setShowCreateLink] = useState(false);


    // Dummy data for the chart
    const data = {
        labels: ['Individuals', 'Foundations', 'Corporations'],
        datasets: [
            {
                data: [29850, 8500, 2062], // Example data, replace with real data
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)', // for Individuals
                    'rgba(54, 162, 235, 0.2)', // for Foundations
                    'rgba(255, 206, 86, 0.2)', // for Corporations
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)', // for Individuals
                    'rgba(54, 162, 235, 1)', // for Foundations
                    'rgba(255, 206, 86, 1)', // for Corporations
                ],
                borderWidth: 1,
            },
        ],
    };

    const totalDonations = data.datasets[0].data.reduce((a, b) => a + b, 0);


    const handleCreateLinkClick = () => {
        if (user?.primaryInfo?.firstName && user?.primaryInfo?.lastName) {
            setShowCreateLink(true);
        } else {
            setActiveComponent('kyc');
        }
    };

    return (
        <>
        <div className="flex flex-col lg:flex-row">
            {/* Main Content */}
            <div className="w-full lg:w-3/4 pr-0 lg:pr-4">
                <SummaryCards />

<div className="mx-1 mt-3 rounded-lg flex-grow flex flex-col font-harmattan font-12">
    {/* PDF Download Section */}
    <div className="bg-gradient-to-r from-blue-200 via-purple-200 to-pink-200 bg-opacity-90 flex items-center p-2 rounded-lg shadow-md">
        <div className="bg-red-100 rounded-full p-3 mr-4 flex items-center justify-center">
            <FaFilePdf className="text-red-500 text-3xl" />
        </div>
        <div>
            <h2 className="text-md font-semibold text-gray-800">Environmental Sustainability Report</h2>
            <p className="text-xs text-gray-600 mb-2">
                Learn about our commitment to sustainability and download the full report.
            </p>
            <a
                href="/pdfs/Illustrated_Environmental_Sustainability.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-500 underline text-sm hover:text-red-600 flex items-center"
            >
                <FaDownload className="mr-2 text-xs" /> {/* Download icon next to the text */}
                Download PDF
            </a>
        </div>
    </div>
</div>





            <img
                src="/dashboard_images/volunteer_with_us.png"
                alt="Verdant Charity Ad"
                className="lg:hidden w-full h-auto my-6 rounded-lg shadow-lg border border-gray-200"
                style={{ objectFit: 'contain' }}
            />


            <div className="p-4 mt-2 bg-white rounded-lg shadow-md border-gray-200" >
                {/* Conditional rendering based on showCreateLink */}
                {!showCreateLink ? (
                    <>
                        <h1 className="text-lg sm:text-lg text-gray-700 font-bold mb-4">Donation Summary</h1>

                        {totalDonations > 0 ? (
                            <DonationChart data={data} />
                        ) : (
                            <div className="flex flex-col justify-center items-center h-64">
                                <Lottie animationData={emptyAnimation} style={{ width: 200, height: 200 }} />
                                <p className="text-gray-500 font-semibold mt-4">No data yet</p> {/* Centered message */}
                            </div>
                        )}

                        <div className="text-left mt-4">
                            <button
                                onClick={handleCreateLinkClick}
                                className="w-full text-sm sm:w-auto border border-emerald-500 text-emerald-500 px-4 py-1 rounded hover:bg-emerald-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition duration-300 inline-flex items-center justify-center"
                            >
                                <MdAdd className="mr-2 text-xs" size={24} />
                                Create Fund Me Link
                            </button>
                        </div>

                    </>
                ) : (
                    <CreateDonationLink setShowCreateLink={setShowCreateLink} />
                )}
            </div>


            <img
                src="/dashboard_images/1674207540.jpg"
                alt="Verdant Charity Ad"
                className="md:hidden w-full h-auto max-h-64 my-6 rounded-lg shadow-lg border border-gray-200"
                style={{ objectFit: 'contain' }}
            />

           
            </div>
              {/* Right Sidebar */}
<div className="hidden lg:block w-full lg:w-1/4 mt-6 lg:mt-0">
    <RightSidebar />
</div>

            </div>

        </>
    );
};

export default DonationsSummary;
