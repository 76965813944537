// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useUser } from './components/context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const { user } = useUser() || {};
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (user && user._id) {
            const socketURL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
            const newSocket = io(socketURL, { transports: ['websocket'] });

            console.log('Socket initialized for user:', user._id);
            setSocket(newSocket);

            newSocket.emit('user-connected', user._id);

            newSocket.on('error', (error) => {
                console.error('Socket error:', error);
                toast.error(error?.message || 'An unexpected socket error occurred.');
            });

            newSocket.on('connect', () => {
                console.log('Socket connected:', newSocket.id);
            });

            newSocket.on('disconnect', () => {
                console.log('Socket disconnected');
            });

            const heartbeatInterval = setInterval(() => {
                newSocket.emit('heartbeat');
            }, 30000);

            newSocket.heartbeatInterval = heartbeatInterval;

            return () => {
                clearInterval(newSocket.heartbeatInterval);
                newSocket.disconnect();
                setSocket(null);
            };
        } else {
            if (socket) {
                clearInterval(socket.heartbeatInterval);
                socket.disconnect();
                setSocket(null);
                console.log('Socket disconnected because user is not logged in.');
            }
        }
    }, [user]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};