// about_us_component.js

import React from 'react';

function AboutUs() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 bg-white relative">
      {/* SVG Line Graphic for Desktop */}
      <svg
        className="hidden md:block absolute top-0 left-0 w-full h-full opacity-20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#34D399"
          fillOpacity="1"
          d="M0,192L48,176C96,160,192,128,288,117.3C384,107,480,117,576,138.7C672,160,768,192,864,213.3C960,235,1056,245,1152,213.3C1248,181,1344,107,1392,69.3L1440,32V320H0V192Z"
        ></path>
      </svg>

      {/* SVG Line Graphic for Mobile */}
      <svg
        className="block md:hidden absolute top-0 left-0 w-full h-full opacity-20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 200"
      >
        <path
          fill="#34D399"
          fillOpacity="1"
          d="M0,64L48,58.7C96,53,192,43,288,37.3C384,32,480,32,576,48C672,64,768,96,864,106.7C960,117,1056,107,1152,85.3C1248,64,1344,32,1392,16L1440,0V160H0V64Z"
        ></path>
      </svg>

      {/* Main Content */}
      <div className="relative z-10">
        <h2 className="text-2xl md:text-4xl font-extrabold text-emerald-600 text-center mb-8">
          Empowering Change, One Campaign at a Time
        </h2>
        <p className="text-lg md:text-xl text-gray-700 mb-12 text-center">
          Verdant Charity is a global platform where compassion meets action. Our goal is simple yet powerful: empower people to make a lasting impact through community-driven campaigns, leaving a legacy of hope and change.
        </p>

        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="flex-1 mb-12 md:mb-0">
            <h3 className="text-2xl md:text-3xl text-center font-bold text-emerald-600 mb-6">Our Mission</h3>
            <p className="text-lg text-gray-700">
              At Verdant Charity, we connect the generosity of donors with communities in need. Transparency, trust, and life-changing results are the foundation of every campaign we support, making sure every contribution leads to measurable, lasting change.
            </p>
          </div>
          <div className="flex-1">
            <h3 className="text-2xl md:text-3xl font-bold text-center text-emerald-600 mb-6">Our Vision</h3>
            <p className="text-lg text-gray-700">
              We envision a world where every individual, regardless of circumstance, has the opportunity to thrive. Together, we’re building a future where empathy drives action, and every effort makes a difference.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

