import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import Lottie from 'lottie-react';
import successAnimation from '../lottie/success-animation.json';
import successConfetti from '../lottie/success-confetti.json';
import phoneVerifyAnimation from '../lottie/code_verification_lottie.json';

const PhoneVerify = () => {
  const locationState = useLocation();
  const initialCodeState = Array(6).fill('');
  const [code, setCode] = useState(initialCodeState);
  const [phoneNumber, setPhone] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  useEffect(() => {
    if (locationState.state?.phoneNumber) {
      setPhone(locationState.state.phoneNumber);
      setMessage(`A verification code has been sent to ${locationState.state.phoneNumber}. Please check your messages.`);
      setTimeLeft(60); // Start countdown
    } else {
      setError("No phone number found. Please try again.");
    }
  }, [locationState.state]);

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    const fullCode = code.join('');
    if (fullCode.length === 6 && code.every(digit => /^[0-9]$/.test(digit))) {
      handleVerify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleInputChange = (index) => (event) => {
    const newCode = [...code];
    const value = event.target.value;

    if (/^[0-9]$/.test(value) || value === '') {
      newCode[index] = value;
      setCode(newCode);

      if (value && index < code.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index) => (event) => {
    if (event.key === 'Backspace') {
      if (code[index]) {
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
        inputRefs.current[index].focus();
      } else if (index > 0 && !code[index]) {
        inputRefs.current[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  const handleVerify = async (e) => {
    // Check if `e` exists before calling `preventDefault`
    if (e) {
        e.preventDefault();
    }

    setLoading(true);
    setError('');
    setMessage('');

    const fullCode = code.join('');

    if (fullCode.length === 6) {
      try {
        const verifyResponse = await api.post('/api/auth/verify-phone', { phoneNumber, verificationCode: fullCode });

        if (verifyResponse.status === 200) {
          setSuccess(true);
          setMessage('Verification successful. You will be redirected shortly.');

          setTimeout(() => {
            navigate('/login');
          }, 5000);
        } else {
          setCode(initialCodeState);
          setError('Verification failed. Please try again or resend the code.');
        }
      } catch (error) {
        setCode(initialCodeState);
        if (error.response && error.response.data && error.response.data.message) {
          setError(` ${error.response.data.message}. Resend code.`);
        } else {
          setError('Verification failed. Please check your connection and try again.');
        }
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please enter all the digits of the verification code.');
      setLoading(false);
    }
};


  const handleResendCode = async () => {
    setLoading(true);
    setError('');
    setMessage('Sending a new code...');

    try {
      const response = await api.post('/api/auth/resend-verification-code', { phoneNumber });

      if (response.status === 200) {
        setMessage(`A new verification code has been sent to ${phoneNumber}. Please check your messages.`);
        setTimeLeft(60); // Reset countdown
      } else {
        setMessage('');
        setError('Failed to resend code. Please try again later.');
      }
    } catch (error) {
      setMessage('');
      const specificErrorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Failed to resend code. Please try again later.';

      setError(specificErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeNumber = () => {
    // Navigate to change phone number component
    navigate('/changeNumber', { state: { phoneNumber } });
  };

  if (success) {
    return (
      <div className="flex flex-col items-center justify-center w-full p-4 bg-gradient-to-b from-emerald-100 to-white min-h-screen">
        <Confetti />
        <Lottie
          animationData={successConfetti}
          className="w-48 h-48 sm:w-56 sm:h-56"
        />
        <Lottie
          animationData={successAnimation}
          className="w-40 h-40 sm:w-48 sm:h-48"
        />
        <p className="text-xl sm:text-2xl font-semibold text-emerald-700 mt-6 text-center">
          Verification Successful!
        </p>
        <button
          onClick={() => navigate('/login')}
          className="mt-8 px-6 py-3 rounded-full bg-emerald-500 text-white hover:bg-emerald-600 transition duration-300 shadow-lg transform hover:scale-105"
        >
          Go to Login
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-6 px-4 sm:px-6 bg-gradient-to-r from-green-200 to-blue-200">
      <div className="max-w-md w-full space-y-6 bg-white p-6 sm:p-8 md:p-10 rounded-2xl shadow-2xl">
        <div className="text-center">
          <Lottie
            animationData={phoneVerifyAnimation}
            className="w-32 h-32 sm:w-40 sm:h-40 mx-auto"
          />
          <h2 className="mt-4 text-2xl sm:text-3xl font-extrabold text-gray-900">
            Verify Your Phone Number
          </h2>
          <p className="mt-2 text-sm sm:text-base text-gray-600">
            We have sent a verification code to{' '}
            <span className="font-medium">{phoneNumber}</span>
          </p>
        </div>
        {message && (
          <div
            className="bg-green-100 border border-green-400 text-green-700 text-xs px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            {message}
          </div>
        )}
        {error && (
          <div
            className="bg-red-100 text-sm sm:text-base border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error! </strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}
        <form className="mt-6 space-y-6" onSubmit={(e) => e.preventDefault()}>
          <div className="flex justify-center gap-1 sm:gap-2">
            {code.map((digit, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                id={`code-${index}`}
                type="tel"
                maxLength="1"
                autoFocus={index === 0}
                value={digit}
                onChange={handleInputChange(index)}
                onKeyDown={handleKeyDown(index)}
                className="w-10 h-10 sm:w-12 sm:h-12 text-center text-lg font-medium border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 transition duration-200"
                required
              />
            ))}
          </div>
          <button
            onClick={handleVerify}
            disabled={loading}
            className={`relative w-full flex justify-center py-2 sm:py-3 px-4 sm:px-6 border border-transparent text-base sm:text-lg font-medium rounded-full text-white ${
              loading ? 'bg-emerald-300' : 'bg-emerald-600'
            } hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition duration-300 shadow-lg transform hover:scale-105`}
          >
            {loading ? 'Verifying...' : 'Verify Phone'}
          </button>
        </form>
        <div className="flex flex-col sm:flex-row justify-between items-center mt-4 sm:mt-6 space-y-2 sm:space-y-0">
          <button
            onClick={handleResendCode}
            disabled={loading || timeLeft > 0}
            className="text-sm sm:text-base underline text-emerald-600 hover:text-emerald-500 disabled:text-gray-400"
          >
            {timeLeft > 0 ? `Resend Code in (${timeLeft}s)` : 'Resend Code'}
          </button>
          <button
            onClick={handleChangeNumber}
            className="text-sm sm:text-base underline text-blue-600 hover:text-blue-500"
          >
            Change Number
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerify;
