// DonationChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DonationChart = ({ data }) => {
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 20,
                    boxWidth: 15,
                },
            },
        },
    };

    return (
        <div className="w-full h-64">
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default DonationChart;
