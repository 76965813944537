import React, { useState, useEffect } from 'react';
import Kyc from '../Kyc';
import { FaCheckCircle, FaMapMarkerAlt, FaCrown, FaBusinessTime, FaUser } from 'react-icons/fa';
import EditProfile from './EditProfile';
import { AiOutlineCheck, AiFillPhone, AiOutlineMail, AiFillCalendar, AiFillIdcard, AiFillHome, AiFillFlag, AiFillEdit } from 'react-icons/ai';
import api from '../../api';
import { useUser } from "../context";

const PersonalInfo = ({ onBack }) => {
  const { user } = useUser();
  const [editMode, setEditMode] = useState(false);
  const [mostRecentLocation, setMostRecentLocation] = useState('');
  const [loginHistory, setLoginHistory] = useState([]);
  const [coordinates, setCoordinates] = useState([36.8167, -1.2833]); // Default to Nairobi

  useEffect(() => {
    const fetchRecentLoginHistory = async () => {
      try {
        const response = await api.get('/api/auth/recent-login-history', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user?.token}`,
          },
        });
        const history = response.data;
        if (history.length > 0) {
          const { locationName, location } = history[0];
          setMostRecentLocation(locationName || 'Location unavailable');
          setCoordinates(location.coordinates);
          setLoginHistory(history);
        }
      } catch (error) {
        console.error('Failed to fetch login history:', error);
      }
    };

    if (user?.token) {
      fetchRecentLoginHistory();
    }
  }, [user]);

  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDOzxbdHOwd3q8Rl4YCB1XDSEshDwcnOxE&q=${coordinates[1]},${coordinates[0]}&zoom=14&maptype=roadmap`;

  const dynamicFields = () => {
    const countryCode = user?.primaryInfo?.countryCode || 'KE';
    switch (countryCode) {
      case 'US':
        return { label: 'SSN', value: user?.primaryInfo?.ssn, icon: <AiFillIdcard /> };
      case 'UK':
      case 'GB':
        return { label: 'National Insurance', value: user?.primaryInfo?.nationalInsurance, icon: <AiFillIdcard /> };
      case 'KE':
        return { label: 'ID Number', value: user?.primaryInfo?.idNumber, icon: <AiFillIdcard /> };
      default:
        return { label: 'Identification Number', value: user?.primaryInfo?.identificationNumber, icon: <AiFillIdcard /> };
    }
  };

  const information = [
    { label: 'First Name', value: user?.primaryInfo?.firstName, icon: <FaUser /> },
    { label: 'Last Name', value: user?.primaryInfo?.lastName, icon: <FaUser /> },
    { label: 'Phone', value: user?.primaryInfo?.phone, icon: <AiFillPhone /> },
    { label: 'Email', value: user?.primaryInfo?.email, icon: <AiOutlineMail /> },
    {
      label: 'Date of Birth',
      value: user?.primaryInfo?.dob ? new Date(user.primaryInfo.dob).toLocaleDateString() : '',
      icon: <AiFillCalendar />,
    },
    dynamicFields(),
    { label: 'Town', value: user?.primaryInfo?.town, icon: <AiFillHome /> },
    { label: 'City', value: user?.primaryInfo?.city || 'Nairobi', icon: <AiFillHome /> },
    { label: 'Region', value: user?.primaryInfo?.region || 'Nairobi County', icon: <AiFillFlag /> },
    { label: 'Country Code', value: user?.primaryInfo?.countryCode || 'KE', icon: <AiFillFlag /> },
    { label: 'Timezone', value: user?.primaryInfo?.timezone || 'Africa/Nairobi', icon: <AiFillCalendar /> },
  ];

  const handleEditClick = () => setEditMode(true);
  const handleSaveChanges = (information) => {
    console.log(information);
    setEditMode(false);
  };
  const handleCancelEdit = () => setEditMode(false);

  const isKycAvailable = user?.primaryInfo?.firstName && user?.primaryInfo?.lastName;

  if (!isKycAvailable) {
    return <Kyc />;
  } else if (editMode) {
    return (
      <EditProfile
        initialData={user?.primaryInfo}
        onSave={handleSaveChanges}
        onCancel={handleCancelEdit}
      />
    );
  } else {
 
    return (
      <div className="container mx-auto bg-white rounded-md shadow-sm">
       

        {/* Action Buttons */}
        <div className="flex px-4 mt-4">
          <button
            type="button"
            onClick={handleEditClick}
            className="w-full md:w-auto flex items-center justify-center px-4 py-2 border border-emerald-600 text-emerald-600 text-sm rounded-md hover:bg-emerald-50 focus:outline-none"
          >
            <AiFillEdit className="mr-1" /> Edit Profile
          </button>
        </div>

        {/* User Details */}
        <div className="mt-6 px-4">
          <div className="bg-white rounded-lg shadow-sm">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {information.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center p-4 border border-gray-200 rounded-lg ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                >
                  <div className="text-emerald-500 text-xl flex-shrink-0">{item.icon}</div>
                  <div className="ml-4 flex-1">
                    <p className="text-gray-600 text-sm">{item.label}</p>
                    <p className="text-gray-800 font-semibold break-words">{item.value || 'Not provided'}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Map */}
        <div className="mt-6 px-4">
          <div className="rounded-lg overflow-hidden shadow-sm">
            <iframe
              className="w-full h-56 md:h-72"
              frameBorder="0"
              style={{ border: 0 }}
              src={mapUrl}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="User Location"
            />
          </div>
        </div>
      </div>
    );
  }
};

export default PersonalInfo;
