import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function CallToAction() {
  const navigate = useNavigate(); // Initialize useNavigate

  // Dynamic social proof element
  const [supporterCount, setSupporterCount] = useState(0);

  // Simulate supporter count update
  useEffect(() => {
    const fetchSupporters = () => {
      const newSupporterCount = Math.floor(Math.random() * (5000 - 1000) + 1000); // Random number between 1000 and 5000
      setSupporterCount(newSupporterCount);
    };

    fetchSupporters();

    const interval = setInterval(fetchSupporters, 10000);
    return () => clearInterval(interval);
  }, []);

  // Handle button navigation
  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <div className="relative py-20 px-4 sm:px-6 lg:px-8 text-center">
      {/* Full-Width Background Image with Overlay */}
      <div className="absolute inset-0 w-full h-full">
        <img
          src="https://acterra.org/wp-content/uploads/2023/10/Acterra-Team-e1703248841739.webp"
          alt="Join the Movement"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>

      {/* Content with Rounded Dotted Border */}
      <div className="relative max-w-3xl mx-auto z-10 border-2 border-gray-500 rounded-lg p-8">
        <h2 className="text-4xl font-extrabold text-white mb-6">
          Join the Movement for Real Change
        </h2>
        <p className="text-lg text-gray-200 mb-10">
          Become one of {supporterCount.toLocaleString()} supporters driving change across the globe. Whether you want to lead a campaign, contribute to a cause, or amplify our message, your actions create a lasting impact.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <button 
            className="bg-transparent border border-emerald-600 text-white py-3 px-8 rounded-md font-semibold hover:bg-emerald-600 hover:text-white transition duration-300"
            onClick={handleSignUp}
          >
            Lead a Campaign
          </button>
          <button 
            className="bg-transparent border border-emerald-600 text-white py-3 px-8 rounded-md font-semibold hover:bg-emerald-600 hover:text-white transition duration-300"
            onClick={handleSignUp}
          >
            Contribute Now
          </button>
          <button 
            className="bg-transparent border border-white text-white py-3 px-8 rounded-md font-semibold hover:bg-white hover:text-emerald-600 transition duration-300"
            onClick={handleSignUp}
          >
            Learn How to Help
          </button>
        </div>
        <p className="text-sm text-gray-300 mt-8">
          Together, we can make the world a better place. Your contribution, no matter how small, adds up to monumental change.
        </p>
      </div>
    </div>
  );
}

export default CallToAction;
