import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from '../../context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

const Reminders = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [reminders, setReminders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [remindersPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalReminders, setTotalReminders] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [currentMonthTotal, setCurrentMonthTotal] = useState(0);
    const [previousMonthTotal, setPreviousMonthTotal] = useState(0);
    const [currentMonthDups, setCurrentMonthDups] = useState({ sms: 0, email: 0 });
    const [previousMonthDups, setPreviousMonthDups] = useState({ sms: 0, email: 0 });

    useEffect(() => {
        fetchReminders();
    }, [currentPage, searchTerm]);

    const fetchReminders = async () => {
        const queryParams = { page: currentPage, limit: remindersPerPage };
        if (searchTerm) {
            queryParams.email = searchTerm;
        }

        try {
            const response = await api.get('/api/admin/reminders', {
                params: queryParams,
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            if (response.status === 200) {
                setReminders(response.data.reminders);
                setTotalReminders(response.data.total);
                setTotalPending(response.data.totalPending);
                setTotalPages(Math.ceil(response.data.total / remindersPerPage));
                setCurrentMonthTotal(response.data.currentMonthTotal);
                setPreviousMonthTotal(response.data.previousMonthTotal);
                setCurrentMonthDups(response.data.currentMonthDups);
                setPreviousMonthDups(response.data.previousMonthDups);
            } else {
                toast.error('Failed to load reminders data');
            }
        } catch (error) {
            console.error("Error fetching reminders data: ", error);
            toast.error(`Failed to load reminders data: ${error.message}`);
        }
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        fetchReminders();
    };

    const getPagination = () => {
        const delta = 1;
        const range = [];
        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }
        if (currentPage - delta > 2) {
            range.unshift("...");
        }
        if (currentPage + delta < totalPages - 1) {
            range.push("...");
        }
        range.unshift(1);
        if (totalPages !== 1) range.push(totalPages);

        return range.map((page, index) => page === "..." ? <span key={index} className="px-2">...</span> : <button key={index} onClick={() => setCurrentPage(page)} className={`px-2 py-1 ${page === currentPage ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}>{page}</button>);
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
                <h3 className="font-semibold text-lg mb-4">
                Reminders (total: {totalReminders}) pending ({totalPending})
            </h3>
            <p className="text-sm mb-4">
                Current Month Total: {currentMonthTotal} | Previous Month Total: {previousMonthTotal}
            </p>
            <p className="text-sm mb-4">
                Duplicates - Current Month: (SMS: {currentMonthDups.sms}, Email: {currentMonthDups.email}) |
                Previous Month: (SMS: {previousMonthDups.sms}, Email: {previousMonthDups.email})
            </p>
            <form onSubmit={handleSearch} className="mb-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by email"
                    className="border p-2 rounded text-xs flex-grow"
                />
                <button type="submit" className="border p-2 rounded bg-emerald-500 text-white text-xs">
                    Search
                </button>
            </form>
            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">User Email</th>
                            <th className="px-4 py-2">Phone Number</th>
                            <th className="px-4 py-2">First Name</th>
                            <th className="px-4 py-2">Last Name</th>
                            <th className="px-4 py-2">Type</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Sent At</th>
                            <th className="px-4 py-2">View More</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reminders.map((reminder) => (
                            <tr key={reminder._id}>
                                <td className="px-4 py-2">{reminder.email}</td>
                                <td className="px-4 py-2">{reminder.phoneNumber}</td>
                                <td className="px-4 py-2">{reminder.firstName}</td>
                                <td className="px-4 py-2">{reminder.lastName}</td>
                                <td className="px-4 py-2">{reminder.type}</td>
                                <td className="px-4 py-2">
                                    <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${reminder.status === 'sent' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {reminder.status}
                                    </span>
                                </td>
                                <td className="px-4 py-2">
                                    {reminder.sentAt ? formatDistanceToNow(new Date(reminder.sentAt), { addSuffix: true }) : 'N/A'}
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => navigate(`/user-details/${reminder.user}`)}
                                        className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                    >
                                        View More
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center space-x-2 mt-4">
                {getPagination()}
            </div>
        </div>
    );
};

export default Reminders;
