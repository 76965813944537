// src/components/SummaryCards.js

import React, { useEffect, useState, useRef } from 'react';
import { FaUsers, FaBroadcastTower, FaBan, FaDollarSign, FaUserCheck, FaPiggyBank, FaLink, FaFileAlt } from 'react-icons/fa';
import api from '../../api';
import { useUser } from "../context";
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../SocketContext';

const SummaryCards = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const socket = useSocket();
    const [stats, setStats] = useState({
        totalUsers: 0,
        unverifiedUsers: 0,
        bannedUsers: 0,
        activeUsers: 0,
        totalPendingWithdrawals: 0,
        totalSuccessfulDeposits: 0,
        totalDonationLinks: 0,
        totalGrants: 0,
    });

    const onlineUsers = useRef(new Set()); // Use useRef to persist the Set across renders

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await api.get('api/admin/user-stats', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setStats(response.data);
            } catch (error) {
                console.error("Error fetching stats: ", error);
            }
        };

        if (user && user.token) {
            fetchStats();
        }
    }, [user]);

    useEffect(() => {
        if (socket) {
            socket.on('user-status-update', (update) => {
                // Update the Set based on the user's online status
                if (update.isOnline) {
                    onlineUsers.current.add(update.userId);
                } else {
                    onlineUsers.current.delete(update.userId);
                }

                // Update the activeUsers count based on the size of the Set
                setStats((prevStats) => ({
                    ...prevStats,
                    activeUsers: onlineUsers.current.size,
                }));
            });

            // Cleanup the event listener
            return () => {
                socket.off('user-status-update');
            };
        }
    }, [socket]);


    return (
        <div className="bg-white p-4 space-y-4 md:grid md:grid-cols-6 md:gap-4 md:space-y-0">
            {/* Total Users Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Total Users</h5>
                    <p className="text-xs mt-2">{stats.totalUsers}</p>
                    {stats.activeUsers > 0 && (
                        <p className="text-xs text-emerald-500 flex items-center mt-1">
                            <FaBroadcastTower className="text-xs mr-1" />
                            : {stats.activeUsers}
                        </p>
                    )}
                </div>
                <div className="rounded-full bg-emerald-500 bg-opacity-20 p-2">
                    <FaUsers className="text-emerald-500 text-xl" />
                </div>
            </div>

            {/* Unverified Users Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Unverified</h5>
                    <p className="text-xs mt-2">{stats.unverifiedUsers}</p>
                </div>
                <div className="rounded-full bg-yellow-500 bg-opacity-20 p-2">
                    <FaUserCheck className="text-yellow-500 text-xl" />
                </div>
            </div>
            {/* Banned Users Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Banned</h5>
                    <p className="text-xs mt-2">{stats.bannedUsers}</p>
                </div>
                <div className="rounded-full bg-emerald-500 bg-opacity-20 p-2">
                    <FaBan className="text-emerald-500 text-xl" />
                </div>
            </div>

            {/* Withdrawals Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Withdrawals</h5>
                    <p className="text-xs mt-2">{stats.totalPendingWithdrawals}</p>
                </div>
                <button
                    className="border border-gray-300 rounded text-xs hover:bg-gray-100 py-1 px-3"
                    onClick={() => navigate('/pending-withdrawals')}
                >
                    View
                </button>
                <div className="rounded-full bg-emerald-500 bg-opacity-20 p-2">
                    <FaDollarSign className="text-emerald-500 text-xl" />
                </div>
            </div>

            {/* Deposits Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Deposits</h5>
                    <p className="text-xs mt-2">{stats.totalSuccessfulDeposits}</p>
                </div>
                <button
                    className="border border-gray-300 rounded text-xs hover:bg-gray-100 py-1 px-3"
                    onClick={() => navigate('/user-deposits')}
                >
                    View
                </button>
                <div className="rounded-full bg-blue-500 bg-opacity-20 p-2">
                    <FaPiggyBank className="text-blue-500 text-xl" />
                </div>
            </div>

            {/* Donation Links Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Donation Links</h5>
                    <p className="text-xs mt-2">{stats.totalDonationLinks}</p>
                </div>
                <button
                    className="border border-gray-300 rounded text-xs hover:bg-gray-100 py-1 px-3"
                    onClick={() => navigate('/all-donation-links')}
                >
                    View
                </button>
                <div className="rounded-full bg-purple-500 bg-opacity-20 p-2">
                    <FaLink className="text-purple-500 text-xl" />
                </div>
            </div>

            {/* Grants Card */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center justify-between">
                <div>
                    <h5 className="text-xs">Grants</h5>
                    <p className="text-xs mt-2">{stats.totalGrants}</p>
                </div>
                <button
                    className="border border-gray-300 rounded text-xs hover:bg-gray-100 py-1 px-3"
                    onClick={() => navigate('/admin/grants')}
                >
                    View
                </button>
                <div className="rounded-full bg-indigo-500 bg-opacity-20 p-2">
                    <FaFileAlt className="text-indigo-500 text-xl" />
                </div>
            </div>
        </div>
    );
};

export default SummaryCards;
