import React from 'react';
import { BiDollarCircle } from 'react-icons/bi';
import { AiFillAlipayCircle, AiOutlineGift } from 'react-icons/ai';
import { MdOutlineVolunteerActivism } from 'react-icons/md';
import { useUser } from "./context";

const formatCurrency = (amount, currencyCode, currencySymbol) => {
  const roundedAmount = Math.round(amount);
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const parts = formatter.formatToParts(roundedAmount);
  const formattedParts = parts.map(part => (part.type === 'currency' ? (currencySymbol || part.value) + ' ' : part.value));
  return formattedParts.join('');
};

const SummaryCards = () => {
  const { user } = useUser();

  // Find the USD account and balance
  const usdAccount = user?.accounts?.find(account => account.currency === "USD");
  const usdBalance = usdAccount ? usdAccount.balance : 0;
  const usdSymbol = usdAccount?.currencySymbol || "$";

  // Find the primary account, and if it's USD, fallback to GBP
  const primaryAccount = user?.accounts?.find(account => account.isPrimary && account.currency !== "USD") ||
    user?.accounts?.find(account => account.currency === "GBP");
  const primaryCurrency = primaryAccount ? primaryAccount.currency : 'N/A';
  const primaryBalance = primaryAccount ? primaryAccount.balance : 0;
  const primarySymbol = primaryAccount?.currencySymbol || "$";
  const primaryCurrencyCode = primaryAccount?.currency || 'USD';

  return (
    <div
      className="bg-emerald-50 bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 space-y-3 rounded-lg shadow-md border border-emerald-200 md:grid md:grid-cols-4 md:gap-3 md:space-y-0"
      style={{
        backgroundImage: "url('/dashboard_images/emerald_pattern.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {/* Card 1: USD Balance */}
      <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-md p-3 rounded-lg border border-emerald-300 flex items-center justify-between">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-emerald-900">USD Balance</h5>
          <p className="text-sm md:text-md font-bold text-emerald-700">{formatCurrency(usdBalance, 'USD', usdSymbol)}</p>
        </div>
        <div className="rounded-full bg-emerald-200 p-2">
          <BiDollarCircle className="text-emerald-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 2: Primary Currency (or GBP if USD is primary) */}
      <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-md p-3 rounded-lg border border-emerald-300 flex items-center justify-between">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-emerald-900">{primaryCurrency} Balance</h5>
          <p className="text-sm md:text-md font-bold text-emerald-700">{formatCurrency(primaryBalance, primaryCurrencyCode, primarySymbol)}</p>
        </div>
        <div className="rounded-full bg-emerald-200 p-2">
          <AiFillAlipayCircle className="text-emerald-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 3: Active Fundraisers */}
      <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-md p-3 rounded-lg border border-emerald-300 flex items-center justify-between">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-emerald-900">Active Fundraisers</h5>
          <p className="text-sm md:text-md font-bold text-emerald-700">0</p>
        </div>
        <div className="rounded-full bg-emerald-200 p-2">
          <MdOutlineVolunteerActivism className="text-emerald-600 text-lg md:text-2xl" />
        </div>
      </div>

      {/* Card 4: Impact Points */}
      <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg shadow-md p-3 rounded-lg border border-emerald-300 flex items-center justify-between">
        <div>
          <h5 className="text-xs md:text-sm font-semibold text-emerald-900">Impact Points</h5>
          <p className="text-sm md:text-md font-bold text-emerald-700">{user?.points}</p>
        </div>
        <div className="rounded-full bg-emerald-200 p-2">
          <AiOutlineGift className="text-emerald-600 text-lg md:text-2xl" />
        </div>
      </div>
    </div>
  );
};

export default SummaryCards;
