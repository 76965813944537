import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";
import successAnimation from "../lottie/success-animation.json";
import successConfetti from '../lottie/success-confetti.json';
import { FaSpinner } from 'react-icons/fa';
import { useUser } from "../context";
import api from '../../api';

const KYC_CONFIG = {
  US: {
    fields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      ssn: 'Social Security Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      postalCode: 'ZIP Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    }
  },
  UK: {
    fields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'county', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      nationalInsurance: 'National Insurance Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City/Town',
      county: 'County',
      postalCode: 'Postal Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    }
  },
  KE: {
    fields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'town', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      idNumber: 'ID Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      town: 'Town/City',
      region: 'County',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    }
  },
  fallback: {
    fields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'city', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      identificationNumber: 'Identification Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      city: 'City/Town',
      region: 'Region',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    }
  }
};

const EditProfile = ({ initialData, onCancel }) => {
  const isAlwaysDisabled = true;
  const { user, login } = useUser();
  const [formData, setFormData] = useState(initialData || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    setIsDataChanged(Object.keys(initialData).some(key => formData[key] !== initialData[key]));
  }, [formData, initialData]);

  const getConfigForCountry = () => {
    const { countryCode } = formData;
    if (countryCode === 'UK' || countryCode === 'GB') return KYC_CONFIG.UK;
    return KYC_CONFIG[countryCode] || KYC_CONFIG.fallback;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError("");

    const payload = Object.keys(formData).reduce((acc, key) => {
      if (initialData[key] !== formData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    try {
      const response = await api.patch('/api/auth/edit-kyc', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user?.token}`
        }
      });

      if (response.status === 200) {
        setShowSuccess(true);
        login({ ...user, primaryInfo: response.data.primaryInfo });
      } else {
        throw new Error(response.data.message || "Error updating KYC data.");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "An unknown error occurred";
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDone = () => {
    setShowSuccess(false);
    onCancel();
  };

  const config = getConfigForCountry();

  return (
    <div className="container mx-auto p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md">
      {error && (
        <div className="mb-4 p-4 text-xs sm:text-xs bg-red-100 border-l-4 border-red-500 text-red-700">
          <p>{error}</p>
        </div>
      )}
      {showSuccess ? (
        <div className="flex flex-col items-center justify-center w-full p-4">
          <div className="relative w-full h-64 md:h-64">
            <Lottie animationData={successConfetti} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
            <Lottie animationData={successAnimation} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
          </div>
          <p className="text-lg font-semibold text-emerald-700 mt-4 text-center">KYC Updated Successfully!</p>
          <button onClick={handleDone} className="mt-4 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 py-2 px-4 rounded text-sm">
            Done
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {config.fields.map(field => (
            <div key={field}>
              <label htmlFor={field} className="block text-sm mb-2 font-medium text-gray-700">{config.fieldLabels[field]}</label>
              <input
                type={field === 'dob' ? 'date' : 'text'}
                name={field}
                id={field}
                value={formData[field] || ''}
                onChange={handleChange}
                className={`text-xs sm:text-sm block w-full pr-3 py-2 pl-3 border border-gray-300 rounded-md leading-5 ${['city', 'region', 'phone', 'email', 'countryCode', 'timezone'].includes(field) ? 'bg-gray-100' : 'bg-white'} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500`}
                readOnly={['city', 'region', 'phone', 'email', 'countryCode', 'timezone'].includes(field)}
              />
            </div>
          ))}
          <div className="md:col-span-2 flex flex-col md:flex-row justify-end mt-4 space-y-2 md:space-y-0 md:space-x-2">
          <button
  type="submit"
  disabled={isAlwaysDisabled || !isDataChanged || isSubmitting} // Add forced disable logic
  className={`text-xs sm:text-sm px-4 py-2 border border-transparent rounded-md shadow-sm w-full md:w-auto
    ${!isDataChanged || isSubmitting || isAlwaysDisabled 
      ? "bg-gray-400 text-gray-300 cursor-not-allowed" 
      : "text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
    }`}
>
  {isSubmitting ? (
    <>
      <FaSpinner className="animate-spin inline mr-2" />
      Saving...
    </>
  ) : (
    "Save Changes"
  )}
</button>

            <button
              type="button"
              onClick={onCancel}
              className="text-xs sm:text-sm px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 w-full md:w-auto"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditProfile;
