import React, { useState, useEffect } from 'react';
import { useUser } from "./context";
import { useNavigate } from 'react-router-dom';
import api from '../api';
import Sidebar from './Sidebar';
import ChangePassword from './ChangePassword';
import DonationsSummary from './DonationsSummary';
import CampaignsInsideApp from './CampaignsInsideApp';
import MyDonations from './MyDonations';
import VolunteerActivities from './VolunteerActivities';
import Profile from './Profile/Profile';
import Membership from './Membership';
import Wallet from './wallet/Wallet';
import Kyc from './Kyc';
import AccessHistory from './wallet/history/AccessHistory';
import AccessDetail from './wallet/history/AccessDetail';
import ForexStrip from './TradingViewWidget';
import GrantsContainer from './grants/GrantsContainer'; // Import the Grants component

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, login } = useUser();
  
  // State to manage which component is displayed
  const [activeComponent, setActiveComponent] = useState('donationsSummary');
  const [selectedLoginId, setSelectedLoginId] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      const fetchUserData = async () => {
        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
            }
          };
          const response = await api.get('/api/auth/info', config);
          if (response.status === 200) {
            login({ ...user, ...response.data });
          } else {
            console.error('Failed to fetch user data, status code:', response.status);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
    }
  }, []);
  
  useEffect(() => {
    if (!user?.primaryInfo?.countryCode || !user?.primaryInfo?.firstName) {
      setActiveComponent('kyc');
      navigate('/kyc');
    }
  }, [user, navigate]);
  
  const handleViewDetails = (loginId) => {
    setSelectedLoginId(loginId);
    setActiveComponent('accessDetail');
  };

  const handleBackToHistory = () => {
    setSelectedLoginId(null);
    setActiveComponent('accessHistory');
  };

  // Updated componentMap with the new Grants component
  const componentMap = {
    donationsSummary: <DonationsSummary setActiveComponent={setActiveComponent} />,
    myDonations: <MyDonations />,
    campaigns: <CampaignsInsideApp />,
    volunteerActivities: <VolunteerActivities />,
    changePassword: <ChangePassword />,
    profile: <Profile />,
    membership: <Membership />,
    wallet: <Wallet />,
    kyc: <Kyc />,
    accessHistory: <AccessHistory onViewDetails={handleViewDetails} />,
    accessDetail: <AccessDetail loginId={selectedLoginId} onBack={handleBackToHistory} setActiveComponent={setActiveComponent} />,
    grants: <GrantsContainer />, // Added Grants component here
  };

  return (
    <div className="bg-emerald-50 min-h-screen pb-20 font-harmattan font-12">
      <ForexStrip />

      {/* "Great News" div */}
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center text-xs" role="alert">
      <strong className="font-bold">🎄 Merry Christmas! 🎄</strong>
        <span className="block sm:inline">
          You can now call us for assistance. Reach out to our support team for prompt help at <a href="tel:+13852339891" className="underline">+1 385 233 9891</a>.
        </span>
      </div>
   {/* Christmas wishes image ad, only visible on small devices */}
   <div className="flex justify-center mt-4 md:hidden">
        <div className=" p-2 rounded-lg ">
          <img
            src="https://verdantcharity.s3.us-east-1.amazonaws.com/Christmas_Gifts_no_bg.png"
            alt="Season's Greetings and Best Wishes"
            className="w-full max-w-xs"
          />
        </div>
      </div>
     

      <div className="lg:flex lg:flex-row p-4 rounded-lg border border-gray-200">
        <Sidebar changeComponent={setActiveComponent} />

      

        {/* Main content */}
        <div className="mx-4 mt-8 rounded-lg flex-grow flex flex-col font-harmattan font-12">
          {/* Render the active component */}
          {componentMap[activeComponent]}
        </div>
      </div>
      
    </div>
  );
};

export default Dashboard;
