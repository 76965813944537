import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-white rounded-lg max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      <h2 className="text-3xl font-semibold text-gray-900 mb-6 text-center">
        Terms of Service
      </h2>

      <div className="p-6 border border-gray-300 rounded-lg overflow-y-auto max-h-[600px] space-y-8">
        <p className="text-gray-700 text-base">
          Thank you for choosing our platform. These Terms of Service constitute a binding agreement governing your use of our services. By accessing our platform, you agree to comply with these terms.
        </p>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">1. Acceptance of Terms</h3>
          <p className="text-gray-700 text-sm">
            Accessing or using our services implies your full acceptance of these terms. If you disagree with any provision, please refrain from using our platform.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">2. User Eligibility</h3>
          <p className="text-gray-700 text-sm">
            Our services are intended solely for individuals aged 18 and above. By using our platform, you confirm that you meet this age requirement. If you are under 18, your access may require parental or guardian consent, subject to applicable laws.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">3. Account Security</h3>
          <p className="text-gray-700 text-sm">
            You are responsible for maintaining the confidentiality of your account credentials. Unauthorized access, sharing, or misuse of account information is strictly prohibited. Promptly notify us of any suspected security breaches.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">4. User Obligations and Conduct</h3>
          <p className="text-gray-700 text-sm">
            Users are expected to interact ethically and responsibly. Prohibited activities include unauthorized access to our platform, harassment, fraudulent actions, and data misuse. Violations may result in account suspension or legal consequences.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">5. Fees and Payments</h3>
          <p className="text-gray-700 text-sm">
            Some of our services may require payment. By choosing these services, you agree to provide accurate payment information and authorize charges as applicable. Failure to meet payment obligations may result in service restrictions.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">6. Refund Policy</h3>
          <p className="text-gray-700 text-sm">
            Refunds may be issued in accordance with our refund policy, which is subject to specific terms and conditions for each service. Please refer to our Refund Policy page or contact support for detailed information.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">7. Data Protection and Privacy</h3>
          <p className="text-gray-700 text-sm">
            We are committed to safeguarding your data as detailed in our Privacy Policy. You consent to our data practices, including collection, processing, and storage. For any inquiries about your data rights, reach out to our support team.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">8. Intellectual Property Rights</h3>
          <p className="text-gray-700 text-sm">
            All content, trademarks, and logos on this platform are proprietary. Unauthorized reproduction or distribution is strictly prohibited and may lead to legal actions.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">9. Limitation of Liability</h3>
          <p className="text-gray-700 text-sm">
            Our platform is provided "as is," without warranties of any kind. We are not liable for any direct, indirect, or incidental damages resulting from your use of the platform, to the extent permitted by law.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">10. Third-Party Links and Content</h3>
          <p className="text-gray-700 text-sm">
            Our platform may contain links to third-party websites. We do not endorse or assume responsibility for any third-party content or practices. Access to third-party websites is at your own risk.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">11. Modification of Terms</h3>
          <p className="text-gray-700 text-sm">
            We reserve the right to update these terms at any time. Changes are effective immediately upon posting. Continued use of our platform signifies acceptance of updated terms.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">12. Termination of Access</h3>
          <p className="text-gray-700 text-sm">
            We may terminate or restrict your access to our platform at our discretion if these terms are violated. You agree to cease all use of our services upon termination.
          </p>
        </section>

        <p className="text-gray-700 text-sm">
          For any inquiries or further information, please contact our support team. We are dedicated to ensuring a secure, fair, and enjoyable experience for all users.
        </p>
      </div>

      <p className="text-gray-500 mt-6 text-xs text-center">
        Last Updated: January 1, 2024
      </p>
    </div>
  );
};

export default TermsOfService;
