// src/components/grants/IntroductionPage.js

import React from 'react';
import { FaClipboardCheck, FaDollarSign, FaHandshake, FaRegCheckCircle, FaArrowRight } from 'react-icons/fa';

const steps = [
  {
    id: '1',
    label: 'Application Submission',
    icon: <FaClipboardCheck />,
    color: 'bg-blue-100 text-blue-600',
  },
  {
    id: '2',
    label: 'Application Review',
    icon: <FaRegCheckCircle />,
    color: 'bg-yellow-100 text-yellow-600',
  },
  {
    id: '3',
    label: 'Funding Allocation',
    icon: <FaDollarSign />,
    color: 'bg-green-100 text-green-600',
  },
  {
    id: '4',
    label: 'Grant Utilization',
    icon: <FaHandshake />,
    color: 'bg-purple-100 text-purple-600',
  },
];

const IntroductionPage = ({ onStart }) => {
  return (
    <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-emerald-700 mb-6 text-center">
        Welcome to Our Grant Program
      </h2>
      <p className="text-gray-700 mb-6">
        We are excited to offer grants to support your business initiatives. Our program is designed to help you achieve your goals by providing the necessary funding and resources.
      </p>

      {/* Steps Section */}
      <div className="mb-8">
        <h3 className="text-lg font-bold text-gray-800 mb-4">Application Process:</h3>
        <div className="flex flex-col items-center sm:flex-row justify-between">
          {steps.map((step, index) => (
            <div key={step.id} className="flex flex-col items-center relative w-full">
              {/* Step Icon */}
              <div className={`flex h-10 w-10 rounded-full justify-center items-center ${step.color}`}>
                {step.icon}
              </div>
              {/* Step Label */}
              <span className={`mt-2 px-2 py-1 text-xs  ${step.color} bg-opacity-20 rounded-full`}>
                {step.label}
              </span>
              {/* Connector Line */}
              {index < steps.length - 1 && (
                <div
                  className={`hidden sm:block absolute top-1/2 transform -translate-y-1/2 -right-6 w-12 h-0.5 ${
                    index < steps.length - 1 ? 'bg-gray-300' : ''
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={onStart}
        className="w-full sm:w-auto px-6 py-2 bg-emerald-600 text-white font-semibold rounded hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 transition duration-300 flex items-center justify-center"
      >
        Start Now <FaArrowRight className="ml-2" />
      </button>
    </div>
  );
};

export default IntroductionPage;
