import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../lottie/loading.json'; // Adjust the path to your Lottie file
import api from '../../api'; 

const HelpPage = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openFaqIndex, setOpenFaqIndex] = useState(null);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                setLoading(true);
                const response = await api.get('/api/faqs/faq');
                setFaqs(response.data);
            } catch (error) {
                console.error('Error fetching FAQs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []);

    const toggleFaq = (index) => {
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };

    // FAQ Item Component
    const FaqItem = ({ question, answer, isOpen, toggle }) => {
        return (
            <div className="bg-white p-6 rounded-md shadow mb-6 transition-all duration-300 hover:shadow-lg" onClick={toggle}>
                <div className="flex justify-between items-center cursor-pointer">
                    <h3 className="text-lg font-semibold text-gray-800">{question}</h3>
                    <span className="text-green-600">{isOpen ? '▲' : '▼'}</span>
                </div>
                {isOpen && <div className="text-md text-gray-600 mt-4" dangerouslySetInnerHTML={{ __html: answer }} />}
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-green-100 to-green-50 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full">
                <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 320">
                    <path fill="#bbf7d0" fillOpacity="0.5" d="M0,192L60,186.7C120,181,240,171,360,160C480,149,600,139,720,154.7C840,171,960,213,1080,224C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
                <svg className="w-full h-full absolute top-20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 320">
                    <path fill="#86efac" fillOpacity="0.3" d="M0,128L60,144C120,160,240,192,360,186.7C480,181,600,139,720,128C840,117,960,139,1080,149.3C1200,160,1320,160,1380,154.7L1440,149.3L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
            </div>
            <div className="container mx-auto p-6 relative z-10">
                <header className="text-center mb-10">
                    <h1 className="text-4xl font-bold text-green-700 mb-4">Verdant Charity Help Center</h1>
                    <p className="text-lg text-gray-600">Find answers to your questions or get in touch</p>
                </header>
                
                <div className="flex justify-center mb-12">
                    <div className="w-full md:w-2/3 lg:w-1/2 relative">
                        <div className="flex items-center bg-white rounded-md shadow-md transition-all duration-300 hover:shadow-lg">
                            <input type="text" className="w-full py-4 px-6 rounded-l-md focus:outline-none focus:ring-2 focus:ring-green-300" placeholder="Search for help articles" />
                            <button className="bg-green-600 hover:bg-green-700 text-white py-4 px-6 rounded-r-md transition-all duration-300">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                
                <section>
                    <h2 className="text-2xl font-semibold text-gray-700 mb-6">Frequently Asked Questions</h2>
                    {loading ? (
                        <div className="flex justify-center items-center py-10">
                            <Lottie animationData={loadingAnimation} style={{ width: 120, height: 120 }} />
                        </div>
                    ) : faqs.length > 0 ? (
                        <div>
                            {faqs.map((faq, index) => (
                                <FaqItem 
                                    key={index}
                                    question={faq.question}
                                    answer={faq.answer}
                                    isOpen={openFaqIndex === index}
                                    toggle={() => toggleFaq(index)}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-gray-600 py-10">
                            <p>No FAQs found at the moment.</p>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default HelpPage;
