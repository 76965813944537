//AccessHistory.js 

import React, { useEffect, useState } from 'react'; 
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { FaTrashAlt } from 'react-icons/fa'; // Import trash icon

const AccessHistory = ({ userId }) => {
    const { user } = useUser();
    const [accessHistory, setAccessHistory] = useState([]);
    const [selectedHistory, setSelectedHistory] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false); // State to mute button during deletion
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages

    useEffect(() => {
        const fetchAccessHistory = async () => {
            try {
                const response = await api.get(`/api/admin/loginhistory/${userId}/access-history?page=${page}`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setAccessHistory(response.data.accessHistory);
                    setTotalPages(response.data.totalPages);
                } else {
                    toast.error('Failed to load access history');
                }
            } catch (error) {
                console.error("Error fetching access history:", error);
                toast.error(`Failed to load access history: ${error.message}`);
            }
        };

        fetchAccessHistory();
    }, [userId, user.token, page]);

    // Handle checkbox selection
    const handleCheckboxChange = (historyId) => {
        if (selectedHistory.includes(historyId)) {
            setSelectedHistory(selectedHistory.filter(id => id !== historyId));
        } else {
            setSelectedHistory([...selectedHistory, historyId]);
        }
    };

    // Handle delete click
    const handleDeleteClick = () => {
        if (selectedHistory.length > 0) {
            setShowDeleteModal(true);
        } else {
            toast.error("Please select at least one record to delete.");
        }
    };

    // Confirm deletion
    const handleDelete = async () => {
        setIsDeleting(true); // Mute the button
        try {
            const response = await api.delete(`/api/admin/loginhistory/${userId}/access-history`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
                data: { historyIds: selectedHistory },
            });
            if (response.status === 200) {
                toast.success(`${selectedHistory.length} record(s) deleted successfully`);
                setAccessHistory(accessHistory.filter(history => !selectedHistory.includes(history._id)));
                setSelectedHistory([]);
                setShowDeleteModal(false);
            } else {
                toast.error('Failed to delete records');
            }
        } catch (error) {
            console.error("Error deleting access history: ", error);
            toast.error(`Failed to delete access history: ${error.message}`);
        }
        setIsDeleting(false); // Unmute the button
    };

    // Helper function to format the login time
    const formatLoginTime = (loginTime) => {
        const dateOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

        const formattedDate = new Date(loginTime).toLocaleDateString(undefined, dateOptions);
        const formattedTime = new Date(loginTime).toLocaleTimeString(undefined, timeOptions);

        return `${formattedDate} ${formattedTime}`;
    };

    // Helper function to format the user coordinates
    const formatCoordinates = (coordinates) => {
        if (!coordinates || coordinates.length !== 2) return 'N/A';
        return `{${coordinates[1].toFixed(4)}, ${coordinates[0].toFixed(4)}}`; // {latitude, longitude}
    };

    // Handle page navigations eg 1,2...5
    const goToNextPage = () => setPage(prevPage => Math.min(prevPage + 1, totalPages));
    const goToPreviousPage = () => setPage(prevPage => Math.max(prevPage - 1, 1));

    return (
        <div className="bg-white rounded-lg p-4 mt-5 border border-gray">
            <h3 className="font-semibold text-lg mb-4">Access History</h3>
            <div className="flex items-center justify-between mb-4">
                {selectedHistory.length > 0 && (
                    <button
                        onClick={handleDeleteClick}
                        disabled={isDeleting}
                        className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4 flex items-center ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <FaTrashAlt className="mr-2" />
                        {selectedHistory.length}
                    </button>
                )}
            </div>

            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedHistory(accessHistory.map(history => history._id));
                                        } else {
                                            setSelectedHistory([]);
                                        }
                                    }}
                                />
                            </th>
                            <th className="px-4 py-2">Login Time</th>
                            <th className="px-4 py-2">Email</th>
                            <th className="px-4 py-2">Platform</th>
                            <th className="px-4 py-2">Device</th>
                            <th className="px-4 py-2">Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accessHistory.map((history) => (
                            <tr key={history._id} className="border-b">
                                <td className="px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={selectedHistory.includes(history._id)}
                                        onChange={() => handleCheckboxChange(history._id)}
                                    />
                                </td>
                                <td className="px-4 py-2">
                                    {formatLoginTime(history.loginTime)}
                                </td>
                                <td className="px-4 py-2">{history.email}</td>
                                <td className="px-4 py-2">{history.platform}</td>
                                <td className="px-4 py-2">{history.device}</td>
                                <td className="px-4 py-2">{history.locationName} {formatCoordinates(history.location.coordinates)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
                <button
                    onClick={goToPreviousPage}
                    className={`px-4 py-2 bg-gray-200 rounded ${page === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={page === 1}
                >
                    Previous
                </button>
                <span className="px-4 py-2">Page {page} of {totalPages}</span>
                <button
                    onClick={goToNextPage}
                    className={`px-4 py-2 bg-gray-200 rounded ${page === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={page === totalPages}
                >
                    Next
                </button>
            </div>

            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                        <h2 className="text-lg font-semibold">Confirm Delete</h2>
                        <p className="my-4">
                            Are you sure you want to delete {selectedHistory.length} entries of this user?
                        </p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setShowDeleteModal(false)}
                                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isDeleting}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccessHistory;
