// src/components/grants/GrantsUnavailable.js

import React from 'react';
import { FaInfoCircle, FaPhoneAlt, FaEnvelope, FaArrowLeft } from 'react-icons/fa';

const GrantsUnavailable = ({ onBack }) => {
  return (
    <div className="max-w-md sm:max-w-2xl mx-auto bg-white p-6 sm:p-8 rounded-lg shadow-md border border-gray-200 text-center">
      <div className="flex flex-col items-center">
        <FaInfoCircle className="text-yellow-500 text-4xl sm:text-5xl mb-3 sm:mb-4" />
        <h2 className="text-lg sm:text-2xl font-bold text-yellow-600 mb-2 sm:mb-4">Grants Unavailable in Your Region</h2>
        <p className="text-gray-700 text-xs sm:text-sm mb-3 sm:mb-4">
          We apologize, but our grant program is not currently available in your region. We hope to expand soon and welcome your participation.
        </p>
        
        {/* Suggested Next Steps */}
        <div className="bg-gray-50 border border-gray-200 p-3 sm:p-4 rounded-lg mb-3 sm:mb-4 w-full">
          <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">What You Can Do:</h3>
          <ul className="text-gray-700 text-xs sm:text-sm text-left list-disc list-inside space-y-1 sm:space-y-2">
            <li>Check back with us soon as we may expand to new regions.</li>
            <li>Contact our support team with any questions.</li>
            <li>Explore other resources we offer to support your initiatives.</li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="text-center mb-3 sm:mb-4 w-full">
          <h3 className="text-sm sm:text-base font-semibold text-gray-800 mb-1 sm:mb-2">Need Help?</h3>
          <p className="text-gray-700 text-xs mb-1 sm:mb-2">Our support team is here for you.</p>
          <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4 text-gray-600 text-xs sm:text-sm">
            <div className="flex items-center justify-center">
              <FaPhoneAlt className="mr-1" /> <span>+1 385 233 9891</span>
            </div>
            <div className="flex items-center justify-center">
              <FaEnvelope className="mr-1" /> <span>support@verdantcharity.org</span>
            </div>
          </div>
        </div>

        {/* Back Button */}
        <button
          onClick={onBack}
          className="mt-3 sm:mt-4 px-3 py-2 sm:px-4 bg-emerald-600 text-white font-semibold rounded hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 transition duration-200 flex items-center justify-center text-xs sm:text-sm"
        >
          <FaArrowLeft className="mr-1" /> Go Back
        </button>
      </div>
    </div>
  );
};

export default GrantsUnavailable;
