import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../context';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Configuration object for country-specific KYC fields
const KYC_CONFIG = {
  US: {
    fields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      ssn: 'Social Security Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      postalCode: 'ZIP Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  UK: {
    fields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'county', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      nationalInsurance: 'National Insurance Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City/Town',
      county: 'County',
      postalCode: 'Postal Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  KE: {
    fields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'town', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      idNumber: 'ID Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      town: 'Town/City',
      region: 'County',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  fallback: {
    fields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'city', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      identificationNumber: 'Identification Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      city: 'City/Town',
      region: 'Region',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
};

const KYCEdit = () => {
  const { userId } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const [kycData, setKycData] = useState({});
  const [originalKycData, setOriginalKycData] = useState({});
  const [countryConfig, setCountryConfig] = useState(KYC_CONFIG.fallback);

  useEffect(() => {
    const fetchKycData = async () => {
      try {
        const response = await api.get(`/api/customer/kyc/${userId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (response.status === 200) {
          setKycData(response.data);
          setOriginalKycData(response.data);

          // Determine country configuration based on countryCode
          const countryCode = response.data.countryCode;
          if (countryCode === 'US') {
            setCountryConfig(KYC_CONFIG.US);
          } else if (countryCode === 'UK' || countryCode === 'GB') {
            setCountryConfig(KYC_CONFIG.UK);
          } else if (countryCode === 'KE') {
            setCountryConfig(KYC_CONFIG.KE);
          } else {
            setCountryConfig(KYC_CONFIG.fallback);
          }
        } else {
          toast.error('Failed to load KYC data');
        }
      } catch (error) {
        toast.error(`Error fetching KYC data: ${error.message}`);
      }
    };

    fetchKycData();
  }, [userId, user.token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setKycData({ ...kycData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Determine the changes by comparing the current state with the original state
    const changes = Object.keys(kycData).reduce((acc, key) => {
      if (kycData[key] !== originalKycData[key]) {
        acc[key] = kycData[key];
      }
      return acc;
    }, {});

    try {
      const response = await api.patch(`/api/customer/kyc/${userId}`, changes, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (response.status === 200) {
        toast.success('KYC updated successfully');
        navigate(`/user-details/${userId}`);
      } else {
        toast.error('Failed to update KYC');
      }
    } catch (error) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'An unknown error occurred';
      toast.error(`Error updating KYC: ${errorMessage}`);
    }
  };

  // Dynamic Form Rendering
  const renderFormFields = () => {
    return countryConfig.fields.map((field) => (
      <div key={field}>
        <label htmlFor={field} className="block text-sm font-medium text-gray-700">
          {countryConfig.fieldLabels[field]}
        </label>
        <input
          id={field}
          type={field === 'dob' ? 'date' : 'text'}
          name={field}
          value={
            field === 'dob'
              ? kycData[field]
                ? kycData[field].split('T')[0]
                : ''
              : kycData[field] || ''
          }
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm"
        />
      </div>
    ));
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow pb-20">
      <ToastContainer />
      <h2 className="text-xl font-semibold mb-6">Edit KYC</h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {renderFormFields()}

        <div className="col-span-full mt-4 flex justify-center sm:justify-end">
  <button
    type="submit"
    className="w-full sm:w-auto px-4 py-2 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
  >
    Update KYC
  </button>
</div>

      </form>
    </div>
  );
};

export default KYCEdit;
