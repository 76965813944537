// src/components/grants/GrantStatus.js

import React, { useEffect, useState } from 'react';
import { FaSpinner, FaCheckCircle, FaTimesCircle, FaDollarSign } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../api';
import { useUser } from "../context";

const GrantStatus = () => {
  const { user } = useUser();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await api.get('/api/grant-applications/user/applications', {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        if (response.data.count > 0) {
          setApplication(response.data.data[0]); // Assuming the latest application is first
        } else {
          toast.info('You have no active grant applications.');
        }
      } catch (err) {
        console.error('Error fetching application status:', err);
        setError('Failed to fetch application status. Please try again later.');
        toast.error('Failed to fetch application status. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [user.token]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-emerald-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
        <p className="text-gray-700">{error}</p>
      </div>
    );
  }

  if (!application) {
    return (
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-bold text-gray-700">No Application Found</h2>
        <p className="text-gray-700 mt-4">
          You have not submitted a grant application yet. Click the button below to start your application.
        </p>
        <button
          onClick={() => window.location.reload()} // Or navigate to the application page
          className="mt-6 px-6 py-2 bg-emerald-600 text-white font-semibold rounded hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 transition duration-300"
        >
          Start Application
        </button>
      </div>
    );
  }

  const statuses = [
    { id: 'Pending', label: 'Review', icon: <FaSpinner />, color: 'bg-yellow-400 text-yellow-600' },
    application.status === 'Rejected'
      ? { id: 'Rejected', label: 'Rejected', icon: <FaTimesCircle />, color: 'bg-red-400 text-red-600' }
      : { id: 'Approved', label: 'Approval', icon: <FaCheckCircle />, color: 'bg-green-400 text-green-600' },
    { id: 'Allocated', label: 'Allocation', icon: <FaDollarSign />, color: 'bg-blue-400 text-blue-600' },
  ];

  const currentStatusIndex = statuses.findIndex((status) => status.id === application.status);

  return (
    <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-emerald-700 mb-6 text-center">
        Your Grant Application Status
      </h2>

{/* Status Progress Indicator */}
<div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8 w-full">
  {statuses.map((status, index) => (
    <div key={status.id} className="flex flex-col items-center relative w-full">
      {/* Step Icon with Pulsing Effect */}
      <div className="relative flex items-center justify-center">
        {index <= currentStatusIndex && (
          <span
            className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${status.color}`}
          ></span>
        )}
        <span
          className={`relative inline-flex h-8 w-8 rounded-full flex justify-center items-center ${
            index <= currentStatusIndex ? `${status.color}` : 'bg-gray-200 text-gray-400'
          }`}
        >
          {status.icon}
        </span>
      </div>
      
      {/* Status Label */}
      <span
        className={`mt-2 px-2 py-1 text-xs rounded-full ${
          index <= currentStatusIndex ? status.color : 'bg-gray-200 text-gray-400'
        } bg-opacity-20`}
      >
        {status.label}
      </span>
      
      {/* Line connector, visible only on medium and larger screens */}
      {index < statuses.length - 1 && (
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 left-full h-0.5 w-full sm:w-16 sm:block hidden ${
            index < currentStatusIndex ? 'bg-emerald-500' : 'bg-gray-300'
          }`}
        />
      )}
    </div>
  ))}
</div>


  {/* Application Details */}
<div className="border-t border-gray-200 py-4 space-y-4 flex flex-col">
  <div className="flex flex-col sm:flex-row items-start sm:items-center">
    <span className="font-bold text-gray-500 text-xs sm:text-sm">Application ID:</span>
    <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application._id || 'N/A'}</span>
  </div>
  
  <div className="flex flex-col sm:flex-row items-start sm:items-center">
    <span className="font-bold text-gray-500 text-xs sm:text-sm">Status:</span>
    <span className="ml-0 sm:ml-2 flex items-center text-xs sm:text-sm">
      <span className={`px-2 py-1 rounded-full ${statuses[currentStatusIndex].color} bg-opacity-20`}>
        {statuses[currentStatusIndex].label || 'N/A'}
      </span>
    </span>
  </div>

  <div className="flex flex-col sm:flex-row items-start sm:items-center">
    <span className="font-bold text-gray-500 text-xs sm:text-sm">Submission Date:</span>
    <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application.submissionDate || 'N/A'}</span>
  </div>

  {/* Conditionally Render Approval Date or Rejection Date */}
  {application.status === 'Approved' && (
    <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <span className="font-bold text-gray-500 text-xs sm:text-sm">Approval Date:</span>
      <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application.approvalDate || 'N/A'}</span>
    </div>
  )}

  {application.status === 'Rejected' && (
    <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <span className="font-bold text-gray-500 text-xs sm:text-sm">Rejection Date:</span>
      <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application.rejectionDate || 'N/A'}</span>
    </div>
  )}

  {application.status === 'Approved' && (
    <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <span className="font-bold text-gray-500 text-xs sm:text-sm">Allocated Amount:</span>
      <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">${application.allocatedAmount || 'N/A'}</span>
    </div>
  )}
  
  <div className="flex flex-col sm:flex-row items-start sm:items-center">
    <span className="font-bold text-gray-500 text-xs sm:text-sm">Allocation Date:</span>
    <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application.allocationDate || 'N/A'}</span>
  </div>

  {application.adminNotes && (
    <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <span className="font-bold text-gray-500 text-xs sm:text-sm">Admin Notes:</span>
      <span className="ml-0 sm:ml-2 text-gray-900 text-xs sm:text-sm">{application.adminNotes}</span>
    </div>
  )}
</div>


      {/* Actions Based on Status */}
      <div className="mt-6 text-center">
        {application.status === 'Pending' && <p className="text-gray-700">Your application is currently under review.</p>}
        {application.status === 'Approved' && <p className="text-gray-700">Congratulations! Your grant has been approved.</p>}
        {application.status === 'Rejected' && <p className="text-gray-700">We're sorry, your grant application was not approved.</p>}
        {application.status === 'Allocated' && <p className="text-gray-700">Funds have been allocated to your account.</p>}
      </div>
    </div>
  );
};

export default GrantStatus;
