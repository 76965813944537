import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import {
  FaEnvelope,
  FaBullhorn,
  FaSms,
  FaRegCalendarAlt,
  FaCog,
  FaUserCheck,
} from 'react-icons/fa';

const settingsOptions = [
    {
        key: 'systemUpdates',
        label: 'System Updates',
        description: 'Be informed about system updates and maintenance.',
        icon: <FaCog className="text-emerald-600 w-6 h-6" />,
      },
      {
        key: 'personalizedRecommendations',
        label: 'Personalized Recommendations',
        description: 'Get recommendations tailored to your preferences.',
        icon: <FaUserCheck className="text-emerald-600 w-6 h-6" />,
      },

  {
    key: 'smsAlerts',
    label: 'SMS Alerts',
    description: 'Get SMS notifications for important alerts.',
    icon: <FaSms className="text-emerald-600 w-6 h-6" />,
  },
  
  {
    key: 'campaignUpdates',
    label: 'Campaign Updates',
    description: 'Stay updated with the latest campaign news.',
    icon: <FaBullhorn className="text-emerald-600 w-6 h-6" />,
  },
  {
    key: 'weeklySummary',
    label: 'Weekly Summary',
    description: 'Receive a summary of your weekly activity.',
    icon: <FaRegCalendarAlt className="text-emerald-600 w-6 h-6" />,
  },

  {
    key: 'promotionalEmails',
    label: 'Promotional Emails',
    description: 'Receive emails about new promotions and offers.',
    icon: <FaEnvelope className="text-emerald-600 w-6 h-6" />,
  },
];

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    systemUpdates: false,
    personalizedRecommendations: true,
    campaignUpdates: false,
    smsAlerts: true,
    weeklySummary: true,
    
    promotionalEmails: true,
  });

  const handleToggle = (key) => {
    setSettings((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="bg-white rounded-lg  max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Notification Settings
      </h2>
      <div className="space-y-4">
        {settingsOptions.map((option) => (
          <div
            key={option.key}
            className="flex items-center p-4 bg-gray-50 rounded-lg hover:shadow transition-shadow duration-200"
          >
            {/* Icon */}
            <div className="mr-4 flex-shrink-0">{option.icon}</div>

            {/* Text Content */}
            <div className="flex-1">
              <h3 className="text-lg font-medium text-gray-700">
                {option.label}
              </h3>
              <p className="text-sm text-gray-500">
                {option.description}
              </p>
            </div>

            {/* Toggle Switch */}
            <div className="ml-4">
              <Switch
                checked={settings[option.key]}
                onChange={() => handleToggle(option.key)}
                className={`${
                  settings[option.key] ? 'bg-emerald-600' : 'bg-gray-300'
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
              >
                <span
                  className={`${
                    settings[option.key] ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
            </div>
          </div>
        ))}
      </div>
      {/* Optional: Add a footer or additional information */}
      <p className="text-gray-500 mt-6 text-sm text-center px-2 sm:px-0">
        Customize your notification preferences to stay informed about what matters most to you.
      </p>
    </div>
  );
};

export default NotificationSettings;
