import React from 'react';
import {
  FaMapMarkerAlt,
  FaRegClock,
  FaDollarSign,
  FaRegPaperPlane,
  FaRegCheckCircle,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaDownload,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Ensure react-router-dom is installed

function HowToApply() {
  const navigate = useNavigate();

  const steps = [
    {
      id: 'step1',
      title: 'Who is eligible for Verdant Charity\'s business grants?',
      description:
        'At Verdant Charity, we are committed to empowering visionaries and entrepreneurs who strive to make a positive impact. Our grants are available to both established small businesses and innovative startups at the idea stage. Whether you are launching a new venture or seeking to expand an existing business, we welcome your application. If you have a compelling vision and the drive to make a difference, you are exactly who we are looking to support.',
      image: 'https://images.unsplash.com/photo-1518609878373-06d740f60d8b',
      icon: (
        <div className="bg-emerald-100 bg-opacity-40 p-4 rounded-full">
          <FaRegCheckCircle className="text-emerald-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step2',
      title: 'How much funding is available through these grants?',
      description:
        'We offer grants ranging from $5,000 to $50,000, tailored to meet the specific needs of your business and the potential impact of your project. This funding can be a crucial stepping stone in bringing your ideas to fruition, allowing you to invest in key areas such as product development, marketing, or scaling operations. Our aim is to provide you with the financial support necessary to accelerate your growth and success.',
      image: 'https://bridgingthegap.org/wp-content/uploads/2023/10/HTA_Timber-Valley_Alpha-Kappa-Alpha_April-15-2023.jpg',
      icon: (
        <div className="bg-blue-100 bg-opacity-40 p-4 rounded-full">
          <FaDollarSign className="text-blue-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step3',
      title: 'How long does the application process take?',
      description:
        'We value your time and have designed a streamlined application process. Creating an account on our platform takes approximately 5 minutes. The grant application itself is straightforward and can be completed in about 15 minutes. We focus on the essential information needed to evaluate your proposal, making the process efficient so you can quickly move forward.',
      image: 'https://images.unsplash.com/photo-1504384308090-c894fdcc538d',
      icon: (
        <div className="bg-purple-100 bg-opacity-40 p-4 rounded-full">
          <FaRegClock className="text-purple-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step4',
      title: 'How soon will I receive a response after submitting my application?',
      description:
        'We understand the importance of timely feedback in advancing your business plans. After submitting your application, our dedicated review team will assess it promptly. You can expect to receive a response within 1-3 business days. Our commitment is to keep you informed every step of the way. Should you have any questions, our support team is readily available at support@verdantcharity.org.',
      image: 'https://www.brec.org/assets/volunteer/GroupBeautificationProject.jpg',
      icon: (
        <div className="bg-yellow-100 bg-opacity-40 p-4 rounded-full">
          <FaRegPaperPlane className="text-yellow-600 text-3xl" />
        </div>
      ),
    },
    {
      id: 'step5',
      title: 'How do I get started with my application?',
      description:
        'Embarking on your journey with Verdant Charity is simple. Visit our website at www.verdantcharity.org to create your free account. Once registered, you will have access to our user-friendly grant application portal. Here, you can provide us with insights into your business or innovative idea. Our platform is designed to guide you seamlessly through each step. Don’t miss this opportunity to turn your vision into reality—start your application today.',
      image: 'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2',
      icon: (
        <div className="bg-teal-100 bg-opacity-40 p-4 rounded-full">
          <FaRegCheckCircle className="text-teal-600 text-3xl" />
        </div>
      ),
    },
  ];

  // Handler for navigating to the signup page
  const handleApplyNow = () => {
    navigate('/signup');
  };

  // Handler for downloading the PDF
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://verdantcharity.s3.us-east-1.amazonaws.com/grant_application.pdf';
    link.download = 'grant_application.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
<div className="relative">
  <img
    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f"
    alt="Verdant Charity Business Grants"
    className="w-full h-96 object-cover"
  />
  <div className="absolute inset-0 bg-emerald-700 bg-opacity-50 flex flex-col items-center justify-center px-4">
    <h1 className="text-4xl md:text-6xl text-white font-bold text-center">
      Verdant Charity Business Grants
    </h1>
    <div className="mt-6 flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 w-full max-w-md">
      {/* Apply Now Button */}
      <button
        onClick={handleApplyNow}
        className="w-full sm:w-auto px-6 py-3 bg-white text-emerald-700 rounded-lg font-semibold uppercase tracking-widest
                   hover:bg-emerald-600 hover:text-white transition duration-300"
        style={{
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // Sharp edges
        }}
      >
        Apply Online
      </button>
      {/* Download Application Button */}
      <button
        onClick={handleDownload}
        className="w-full sm:w-auto px-6 py-3 bg-white text-emerald-700 rounded-lg font-semibold uppercase tracking-widest
                   hover:bg-emerald-600 hover:text-white transition duration-300 flex items-center justify-center"
        style={{
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        }}
      >
        <FaDownload className="mr-2" />
        Download Application
      </button>
    </div>
  </div>
</div>


      {/* Content Section */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {steps.map((step, index) => (
          <div
            key={index}
            id={step.id}
            className={`flex flex-col md:flex-row items-center mb-16 ${
              index % 2 === 0 ? '' : 'md:flex-row-reverse'
            }`}
          >
            {/* Image Section */}
            <div className="md:w-1/2">
              <img
                src={step.image}
                alt={step.title}
                className="w-full h-80 object-cover rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300"
              />
            </div>
            {/* Text Section */}
            <div
              className="md:w-1/2 md:pl-12 md:pr-12 mt-8 md:mt-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex items-center">
                {step.icon}
                <h2 className="text-2xl md:text-3xl font-bold text-emerald-700 ml-3">
                  {step.title}
                </h2>
              </div>
              <p className="mt-4 text-gray-700 whitespace-pre-line">
                {step.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Call to Action with Gradient Background */}
      <div className="bg-gradient-to-r from-emerald-600 via-teal-500 to-green-500 py-12">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white">
            Ready to Transform Your Vision into Reality?
          </h2>
          <p className="mt-4 text-white text-lg">
            Join the Verdant Charity community today. Receive the support and funding you need to elevate your business to new heights. Your journey towards success starts with a single step.
          </p>
          <button
            onClick={handleApplyNow}
            className="mt-6 inline-block bg-white text-emerald-700 font-semibold py-3 px-6 rounded-full hover:bg-gray-100 transition-colors duration-300"
          >
            Get Started Now
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 py-8">
        <div className="max-w-7xl mx-auto text-center text-gray-400">
          <div className="flex justify-center mb-4">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="mx-2 text-gray-400 hover:text-white">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2 text-gray-400 hover:text-white">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2 text-gray-400 hover:text-white">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="mx-2 text-gray-400 hover:text-white">
              <FaLinkedinIn />
            </a>
          </div>
          <p>&copy; {new Date().getFullYear()} Verdant Charity. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default HowToApply;
