import React, { useState, useEffect } from 'react';
import api from '../../../api';
import { useUser } from '../../context';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const AdminGrantList = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [grants, setGrants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [grantToDelete, setGrantToDelete] = useState(null);

  useEffect(() => {
    fetchGrants();
  }, [searchTerm, currentPage]);

  const fetchGrants = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/grant-applications/allgrants', {
        headers: { Authorization: `Bearer ${user.token}` },
        params: { searchTerm, page: currentPage, limit: 10 },
      });
      setGrants(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error('Error fetching grant applications.');
    }
    setLoading(false);
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleDeleteClick = (grant) => {
    setGrantToDelete(grant); // Store the whole grant object to access applicant name
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/api/grant-applications/${grantToDelete._id}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      toast.success('Grant application deleted.');
      setShowDeleteModal(false);
      fetchGrants();
    } catch (error) {
      toast.error('Failed to delete grant application.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Grant Applications</h1>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by Grant ID..."
        className="border p-2 mb-4 rounded text-sm w-full sm:w-1/3"
      />

      {loading ? (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faSpinner} spin className="text-2xl" />
        </div>
      ) : (
        <table className="min-w-full border border-gray-200">
          <thead>
            <tr className="bg-gray-100 text-sm text-gray-700">
              <th className="p-2 border">Grant ID</th>
              <th className="p-2 border">Applicant Email</th>
              <th className="p-2 border">Status</th>
              <th className="p-2 border">Amount</th>
              <th className="p-2 border">Submitted</th>
              <th className="p-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {grants.length > 0 ? (
              grants.map((grant) => (
                <tr key={grant._id} className="text-sm">
                  <td className="p-2 border">{grant.grantId}</td>
                  <td className="p-2 border">{grant.applicant.email || 'N/A'}</td>
                  <td className={`p-2 border ${grant.status === 'Approved' ? 'text-green-600' : grant.status === 'Rejected' ? 'text-red-600' : 'text-yellow-600'}`}>
                    {grant.status}
                  </td>
                  <td className="p-2 border">${grant.allocatedAmount || 'N/A'}</td>
                  <td className="p-2 border">{moment(grant.createdAt).format('LL')}</td>
                  <td className="p-2 border flex space-x-2">
                    <button
                      onClick={() => navigate(`/admin/grants/${grant._id}`)}
                      className="px-2 py-1 border rounded hover:bg-gray-200 text-xs"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(grant)}
                      className="px-2 py-1 border rounded text-red-600 hover:bg-red-50 text-xs"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="p-4 text-center text-gray-500">No grant applications found.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="flex justify-center space-x-2 mt-4">
        {[...Array(totalPages).keys()].map((page) => (
          <button
            key={page + 1}
            onClick={() => setCurrentPage(page + 1)}
            className={`px-2 py-1 ${currentPage === page + 1 ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}
          >
            {page + 1}
          </button>
        ))}
      </div>

      {showDeleteModal && grantToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-lg font-semibold">Confirm Delete</h2>
            <p className="my-4">
              Are you sure you want to delete the grant application of <strong>{grantToDelete.applicant.email || 'this applicant'}</strong>?
            </p>
            <div className="flex justify-end space-x-2">
              <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
              <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded">Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminGrantList;
