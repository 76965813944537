import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import {
  FaShieldAlt,
  FaBell,
  FaKey,
  FaDesktop,
} from 'react-icons/fa';

const Authentication = () => {
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [loginAlerts, setLoginAlerts] = useState(false);
  const [showBackupCodes, setShowBackupCodes] = useState(false);
  const [showActiveSessions, setShowActiveSessions] = useState(false);
  const [endingSession, setEndingSession] = useState(false);

  const toggleTwoFactorAuth = () => setTwoFactorAuth(!twoFactorAuth);
  const toggleLoginAlerts = () => setLoginAlerts(!loginAlerts);

  const handleEndSession = () => {
    setEndingSession(true);
    setTimeout(() => {
      setEndingSession(false);
      setShowActiveSessions(false);
    }, 1500);
  };

  const authenticationOptions = [
    {
      key: 'twoFactorAuth',
      label: 'Two-Factor Authentication',
      description: 'Add an extra layer of security to your account.',
      icon: <FaShieldAlt className="text-emerald-600" />,
      value: twoFactorAuth,
      toggle: toggleTwoFactorAuth,
    },
    {
      key: 'loginAlerts',
      label: 'Login Alerts',
      description: 'Get notified when your account is accessed from a new device.',
      icon: <FaBell className="text-emerald-600" />,
      value: loginAlerts,
      toggle: toggleLoginAlerts,
    },
  ];

  return (
    <div className="bg-white rounded-lg max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
        Authentication Settings
      </h2>
      <div className="space-y-6">
        {/* Authentication Options */}
        {authenticationOptions.map((option) => (
          <div
            key={option.key}
            className="flex items-center p-4 bg-gray-50 rounded-lg hover:shadow transition-shadow duration-200"
          >
            <div className="mr-4">{option.icon}</div>
            <div className="flex-1">
              <h3 className="text-lg font-medium text-gray-700">
                {option.label}
              </h3>
              <p className="text-sm text-gray-500">
                {option.description}
              </p>
            </div>
            <div className="ml-4">
              <Switch
                checked={option.value}
                onChange={option.toggle}
                className={`${
                  option.value ? 'bg-emerald-600' : 'bg-gray-300'
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 focus:outline-none`}
              >
                <span
                  className={`${
                    option.value ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
            </div>
          </div>
        ))}

        {/* Backup Codes */}
        <div className="p-4 bg-gray-50 rounded-lg hover:shadow transition-shadow duration-200">
          <div className="flex items-center">
            <div className="mr-4">
              <FaKey className="text-emerald-600" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-medium text-gray-700">Backup Codes</h3>
              <p className="text-sm text-gray-500">
                Generate backup codes in case you lose access to your authenticator app.
              </p>
            </div>
          </div>
          <button
            onClick={() => setShowBackupCodes(!showBackupCodes)}
            className="px-4 py-2 mt-4 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 transition w-full sm:w-auto"
          >
            {showBackupCodes ? 'Hide Backup Codes' : 'Generate Backup Codes'}
          </button>
          {showBackupCodes && (
            <div className="mt-4 text-gray-700 text-sm bg-white p-4 rounded-lg border border-gray-200">
              {/* Simulated backup codes for demonstration */}
              <ul className="list-disc pl-5 space-y-1">
                <li>1234-5678-9101</li>
                <li>2345-6789-1012</li>
                <li>3456-7890-1234</li>
              </ul>
              <p className="text-xs text-gray-500 mt-2">
                Keep these codes safe. Each can only be used once.
              </p>
            </div>
          )}
        </div>

        {/* Active Sessions */}
        <div className="p-4 bg-gray-50 rounded-lg hover:shadow transition-shadow duration-200">
          <div className="flex items-center">
            <div className="mr-4">
              <FaDesktop className="text-emerald-600" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-medium text-gray-700">Active Sessions</h3>
              <p className="text-sm text-gray-500">
                Manage devices currently logged into your account.
              </p>
            </div>
          </div>
          <button
            onClick={() => setShowActiveSessions(!showActiveSessions)}
            className="px-4 py-2 mt-4 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 transition w-full sm:w-auto"
          >
            {showActiveSessions ? 'Hide Active Sessions' : 'View Active Sessions'}
          </button>
          {showActiveSessions && (
            <div className="mt-4 text-gray-700 text-sm bg-white p-4 rounded-lg border border-gray-200">
              {/* Example active session */}
              <p>
                <strong>Device:</strong> MacBook Pro - Safari
              </p>
              <p>
                <strong>Location:</strong> New York, NY
              </p>
              <p>
                <strong>Last Active:</strong> 10/24/2024, 10:00 AM
              </p>
              <p className="text-xs text-gray-500 mt-2">
                Click "End Session" to log out from this device.
              </p>
              <button
                onClick={handleEndSession}
                className="px-4 py-2 mt-4 text-red-500 border border-red-500 rounded-md hover:bg-red-50 transition w-full sm:w-auto"
                disabled={endingSession}
              >
                {endingSession ? 'Ending...' : 'End Session'}
              </button>
            </div>
          )}
        </div>
      </div>
      <p className="text-gray-500 mt-6 text-sm text-center">
        Enabling two-factor authentication (2FA) adds an additional layer of security to your account.
      </p>
    </div>
  );
};

export default Authentication;
