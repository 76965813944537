import React from 'react';
import { FaMapMarkerAlt, FaDollarSign, FaShieldAlt } from 'react-icons/fa'; // Replaced FaLock with FaShieldAlt

function CampaignsOverview() {
  const campaigns = [
    {
      title: 'Clean Water for Flint, Michigan',
      description:
        'Providing clean water to families in Flint, Michigan, where access to safe drinking water has been a major crisis. This project aims to provide long-term clean water solutions.',
      image: 'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/newscms/2018_15/2392306/180409-flint-water-bottles-2-0913.jpg',
      location: 'Flint, Michigan, USA',
      amountRaised: 78000,
      targetAmount: 150000,
      organizer: {
        name: 'Michael Carter',
        role: 'Project Director, WaterWorks Initiative',
        image: 'https://cdn.prod.website-files.com/6152478a23beca84a004ddef/6627f83e4048db273ffdb17e_IMG_0224-p-500.jpeg',
      },
      testimonial: '"Thanks to this campaign, our family finally has access to clean water." - Flint Resident',
    },
    {
      title: 'London Homeless Support',
      description:
        'Supporting homeless individuals in London by providing shelter, food, and job training programs. This initiative has helped over 500 people get back on their feet.',
      image: 'https://static1.squarespace.com/static/5b8db66fb40b9dd2716cf4f1/t/5fb2913de0c1e336af184a8c/1605538124939/Andrew+%26+Sophie+-+Downtown+London.jpeg?format=1500w',
      location: 'London, UK',
      amountRaised: 65000,
      targetAmount: 100000,
      organizer: {
        name: 'Sarah Williams',
        role: 'Campaign Lead, ShelterCare UK',
        image: 'https://thechurchco-production.s3.amazonaws.com/uploads/sites/7690/2023/12/kp-uneditedArtboard-1-800x800.jpg',
      },
      testimonial: '"This program gave me hope and the tools to rebuild my life." - Homeless Support Recipient',
    },
    {
      title: 'Youth Mental Health in Manchester',
      description:
        'Improving mental health services for young people in Manchester, UK, with a focus on providing accessible therapy and support for teens struggling with anxiety and depression.',
      image: 'https://www.manchestermind.org/wp-content/uploads/2023/07/Cerebal-blog-photo-2-web-e1690802843864.jpg',
      location: 'Manchester, UK',
      amountRaised: 42000,
      targetAmount: 75000,
      organizer: {
        name: 'Mark Johnson',
        role: 'Mental Health Advocate, MindsMatter',
        image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/shannon-bill.jpg',
      },
      testimonial: '"The mental health support has been life-changing for our community." - Teen Support Recipient',
    },
    {
      title: 'Hurricane Relief for Louisiana',
      description:
        'Helping communities in Louisiana recover from the devastation of hurricanes. This project focuses on rebuilding homes and providing emergency supplies to affected families.',
      image: 'https://www.okdisasterhelp.org/wp-content/uploads/2020/08/IMG_3058-1068x675.jpeg',
      location: 'Louisiana, USA',
      amountRaised: 98000,
      targetAmount: 200000,
      organizer: {
        name: 'George Crawford',
        role: 'Disaster Relief Coordinator, HelpNow Foundation',
        image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/crawford-george.jpg',
      },
      testimonial: '"Rebuilding our homes and lives wouldn’t have been possible without this aid." - Louisiana Resident',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl md:text-4xl font-extrabold text-emerald-600 text-center mb-8">
        Ongoing Campaigns
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {campaigns.map((campaign, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md border border-gray-300 flex flex-col justify-between"
          >
            <div>
              <img
                className="w-full h-48 object-cover rounded-t-lg mb-4"
                src={campaign.image}
                alt={campaign.title}
              />
              <h3 className="text-xl font-bold text-gray-900 mb-2">{campaign.title}</h3>
              <p className="text-gray-600 mb-4">{campaign.description}</p>
              
              {/* Location */}
              <div className="flex items-center text-gray-500 mb-2">
                <FaMapMarkerAlt className="mr-2" /> {campaign.location}
              </div>

              {/* Amount Raised */}
              <div className="flex items-center text-gray-500 mb-4">
                <FaDollarSign className="mr-2" /> Amount Raised: ${campaign.amountRaised.toLocaleString()}
              </div>
              
              {/* Target Amount */}
              <p className="text-gray-500">Target Amount: ${campaign.targetAmount.toLocaleString()}</p>
              
              {/* Progress Bar with Milestones */}
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
                <div
                  className="bg-green-500 h-2.5 rounded-full"
                  style={{ width: `${(campaign.amountRaised / campaign.targetAmount) * 100}%` }}
                />
              </div>
              <div className="flex justify-between text-xs text-gray-500 mb-4">
                <span>25%: Milestone 1</span>
                <span>50%: Milestone 2</span>
                <span>75%: Milestone 3</span>
              </div>
              
              {/* Percentage Funded */}
              <p className="text-sm text-gray-500 mb-4">
                {(campaign.amountRaised / campaign.targetAmount * 100).toFixed(2)}% funded
              </p>
              
              {/* Testimonial */}
              <blockquote className="italic text-gray-700 mb-4">{campaign.testimonial}</blockquote>
            </div>

            {/* Organizer Details and Secure Payment Notice */}
            <div className="mt-6">
              <div className="flex items-center mb-2">
                <img src={campaign.organizer.image} alt={campaign.organizer.name} className="w-8 h-8 rounded-full mr-2" />
                <div>
                  <p className="text-gray-700 font-medium">{campaign.organizer.name}</p>
                  <p className="text-gray-500 text-sm">{campaign.organizer.role}</p>
                </div>
              </div>

              <div className="flex items-center text-gray-500">
                <FaShieldAlt className="mr-2" /> <span className="italic">Stripe Secured Payment</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CampaignsOverview;
