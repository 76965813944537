// RightSidebar.js
import React from 'react';
import {
  FaUserCircle,
  FaChartPie,
  FaBookOpen,
  FaCog,
  FaQuoteLeft,
  FaLightbulb ,
  FaCreditCard,
} from 'react-icons/fa';
import { useUser } from "./context";

const RightSidebar = () => {
  const { user } = useUser();

  // Helper function to format currency
  const formatCurrency = (amount, currencySymbol) => {
    return `${currencySymbol}${amount.toLocaleString()}`;
  };

  // Calculate total balance
  const totalBalance = user?.accounts?.reduce((acc, account) => acc + account.balance, 0) || 0;

  // Find primary account
  const primaryAccount = user?.accounts?.find(account => account.isPrimary) || {};

  return (
    <div
      className="bg-emerald-50 bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 rounded-xl shadow-lg border border-emerald-200 space-y-4"
      style={{
        backgroundImage: "url('/dashboard_images/emerald_pattern.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
   {/* User Profile Section */}
<div className="flex items-center space-x-3">
    <div className="rounded-full bg-blue-100 bg-opacity-30 p-2 backdrop-filter backdrop-blur-lg">
        <FaUserCircle className="text-blue-500 text-2xl" />
    </div>
    <div className="overflow-hidden">
        <h2 className="text-md font-semibold text-gray-800 truncate max-w-[150px] sm:max-w-[200px] lg:max-w-[250px]">
            {user?.primaryInfo?.firstName} {user?.primaryInfo?.lastName}
        </h2>
        <p className="text-xs text-gray-600">Member since {new Date(user?.createdAt).getFullYear()}</p>
    </div>
</div>


      {/* Financial Overview Section */}
      <div className="space-y-2">
        <h2 className="text-md font-semibold text-blue-800">Financial Overview</h2>
        <div className="flex items-center space-x-2">
          <div className="rounded-full bg-green-100  p-2 backdrop-filter backdrop-blur-lg">
            <FaLightbulb  className="text-green-500 text-xl" />
          </div>
          <div>
            <p className="text-sm text-gray-700">Total Balance</p>
            <p className="text-xs text-gray-500 font-semibold">
              {formatCurrency(totalBalance, '$')}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="rounded-full bg-purple-100 bg-opacity-90 p-2 backdrop-filter backdrop-blur-lg">
            <FaCreditCard className="text-purple-500 text-xl" />
          </div>
          <div>
            <p className="text-sm text-gray-700">Primary Account</p>
            <p className="text-xs text-gray-500 font-semibold">
              {primaryAccount.currency || 'N/A'} - {formatCurrency(primaryAccount.balance || 0, primaryAccount.currencySymbol || '$')}
            </p>
          </div>
        </div>
      </div>

      {/* Quote of the Day */}
      <div className="space-y-2">
        <h2 className="text-md font-semibold text-blue-800">Quote of the Day</h2>
        <div className="flex items-start">
          <FaQuoteLeft className="text-gray-400 text-sm mr-2 mt-1" />
          <p className="text-sm text-gray-700 italic">
            "Invest in yourself. Your career is the engine of your wealth."
          </p>
        </div>
      </div>

      {/* Featured Resources Section */}
      <div className="space-y-2">
        <h2 className="text-md font-semibold text-blue-800">Featured Resources</h2>
        <ul className="space-y-2">
          <li className="flex items-start">
            <div className="rounded-full bg-green-100 bg-opacity-30 p-2 backdrop-filter backdrop-blur-lg">
              <FaBookOpen className="text-green-500 text-lg" />
            </div>
            <div className="ml-2">
              <p className="text-sm font-semibold text-gray-800">Financial Planning Guide</p>
              <p className="text-xs text-gray-600">Learn how to manage your finances effectively.</p>
            </div>
          </li>
          <li className="flex items-start">
            <div className="rounded-full bg-purple-100 bg-opacity-30 p-2 backdrop-filter backdrop-blur-lg">
              <FaChartPie className="text-purple-500 text-lg" />
            </div>
            <div className="ml-2">
              <p className="text-sm font-semibold text-gray-800">Investment Strategies</p>
              <p className="text-xs text-gray-600">Maximize your returns with smart investments.</p>
            </div>
          </li>
        </ul>
      </div>

      {/* Settings Button */}
      <div>
        <button className="w-full bg-gray-100 bg-opacity-30 backdrop-filter backdrop-blur-lg py-2 rounded-lg border border-emerald-300 shadow-md hover:bg-opacity-40 transition flex items-center justify-center">
          <FaCog className="text-gray-700 text-lg mr-2" />
          <span className="text-sm font-semibold text-gray-700">Settings</span>
        </button>
      </div>
    </div>
  );
};

export default RightSidebar;
