// CampaignsInsideApp.js

import React from 'react';
import {
  FaMapMarkerAlt,
  FaDollarSign,
  FaShieldAlt,
  FaUserTie,
  FaQuoteLeft,
  FaHandsHelping,
  FaRegClock,
} from 'react-icons/fa';
import { AiOutlineHeart, AiFillHeart, AiOutlineShareAlt } from 'react-icons/ai';
import RightSidebar from './RightSidebar';



function CampaignsInsideApp() {
    const campaigns = [
        {
          title: 'Clean Water for Flint, Michigan',
          description:
            'Providing clean water to families in Flint, Michigan, where access to safe drinking water has been a major crisis. This project aims to provide long-term clean water solutions.',
          image: 'https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/newscms/2018_15/2392306/180409-flint-water-bottles-2-0913.jpg',
          location: 'Flint, Michigan, USA',
          amountRaised: 78000,
          targetAmount: 150000,
          organizer: {
            name: 'Michael Carter',
            role: 'Project Director, WaterWorks Initiative',
            image: 'https://cdn.prod.website-files.com/6152478a23beca84a004ddef/6627f83e4048db273ffdb17e_IMG_0224-p-500.jpeg',
          },
          testimonial: '"Thanks to this campaign, our family finally has access to clean water." - Flint Resident',
        },
        {
          title: 'London Homeless Support',
          description:
            'Supporting homeless individuals in London by providing shelter, food, and job training programs. This initiative has helped over 500 people get back on their feet.',
          image: 'https://static1.squarespace.com/static/5b8db66fb40b9dd2716cf4f1/t/5fb2913de0c1e336af184a8c/1605538124939/Andrew+%26+Sophie+-+Downtown+London.jpeg?format=1500w',
          location: 'London, UK',
          amountRaised: 65000,
          targetAmount: 100000,
          organizer: {
            name: 'Sarah Williams',
            role: 'Campaign Lead, ShelterCare UK',
            image: 'https://thechurchco-production.s3.amazonaws.com/uploads/sites/7690/2023/12/kp-uneditedArtboard-1-800x800.jpg',
          },
          testimonial: '"This program gave me hope and the tools to rebuild my life." - Homeless Support Recipient',
        },
        {
          title: 'Youth Mental Health in Manchester',
          description:
            'Improving mental health services for young people in Manchester, UK, with a focus on providing accessible therapy and support for teens struggling with anxiety and depression.',
          image: 'https://www.manchestermind.org/wp-content/uploads/2023/07/Cerebal-blog-photo-2-web-e1690802843864.jpg',
          location: 'Manchester, UK',
          amountRaised: 42000,
          targetAmount: 75000,
          organizer: {
            name: 'Mark Johnson',
            role: 'Mental Health Advocate, MindsMatter',
            image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/shannon-bill.jpg',
          },
          testimonial: '"The mental health support has been life-changing for our community." - Teen Support Recipient',
        },
        {
          title: 'Hurricane Relief for Louisiana',
          description:
            'Helping communities in Louisiana recover from the devastation of hurricanes. This project focuses on rebuilding homes and providing emergency supplies to affected families.',
          image: 'https://www.okdisasterhelp.org/wp-content/uploads/2020/08/IMG_3058-1068x675.jpeg',
          location: 'Louisiana, USA',
          amountRaised: 98000,
          targetAmount: 200000,
          organizer: {
            name: 'George Crawford',
            role: 'Disaster Relief Coordinator, HelpNow Foundation',
            image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/crawford-george.jpg',
          },
          testimonial: '"Rebuilding our homes and lives wouldn’t have been possible without this aid." - Louisiana Resident',
        },
      ];
 // Sample state to track likes (in a real app, this would come from your backend or context)
 const [likedCampaigns, setLikedCampaigns] = React.useState([]);

 const toggleLike = (index) => {
   setLikedCampaigns((prevLiked) =>
     prevLiked.includes(index)
       ? prevLiked.filter((i) => i !== index)
       : [...prevLiked, index]
   );
 };

 return (
   <div className="flex">
     {/* Main Content Area */}
     <div className="flex-grow flex flex-col items-center p-4">
       <div className="w-full max-w-2xl space-y-6">
         {campaigns.map((campaign, index) => (
           <div
             key={index}
             className="bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden"
           >
             {/* Campaign Header */}
             <div className="p-4">
               <div className="flex justify-between items-start">
                 {/* Left Side */}
                 <div>
                   <div className="flex items-center">
                     <img
                       src={campaign.organizer.image}
                       alt={campaign.organizer.name}
                       className="w-10 h-10 rounded-full mr-3"
                     />
                     <div>
                       <p className="text-gray-700 font-bold">
                         {campaign.organizer.name}
                       </p>
                       <p className="text-xs text-gray-500">
                         {campaign.organizer.role}
                       </p>
                     </div>
                   </div>
                   {/* Time Remaining 
                   <div className="flex items-center text-gray-500 text-sm mt-2">
                     <FaRegClock className="mr-1" />
                     <span>28 days left</span> 
                   </div>
                   */}
                 </div>
                 {/* Like Button */}
                 <button
                   onClick={() => toggleLike(index)}
                   className="text-gray-500 hover:text-red-500 focus:outline-none"
                 >
                   {likedCampaigns.includes(index) ? (
                     <AiFillHeart className="text-red-500 text-xl" />
                   ) : (
                     <AiOutlineHeart className="text-xl" />
                   )}
                 </button>
               </div>
             </div>

             {/* Campaign Image */}
             <img
               className="w-full h-64 object-cover"
               src={campaign.image}
               alt={campaign.title}
             />

             {/* Campaign Content */}
             <div className="p-4">
               {/* Title */}
               <h3 className="text-xl font-bold text-gray-900 mb-2">
                 {campaign.title}
               </h3>

               {/* Description */}
               <p className="text-gray-600 mb-4">{campaign.description}</p>

               {/* Location and Category */}
               <div className="flex flex-wrap items-center text-gray-500 mb-4">
                 <div className="flex items-center mr-4 mb-2">
                   <FaMapMarkerAlt className="mr-1" />
                   {campaign.location}
                 </div>
                 <div className="flex items-center mb-2">
                   <FaHandsHelping className="mr-1" />
                   Community Support
                 </div>
               </div>

               {/* Progress Section */}
               <div className="mb-4">
                 {/* Amount Raised */}
                 <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-2">
                   <p className="text-gray-700 font-semibold">
                     ${campaign.amountRaised.toLocaleString()}
                     <span className="text-sm text-gray-500 font-normal">
                       {' '}
                       raised of ${campaign.targetAmount.toLocaleString()}
                     </span>
                   </p>
                   <p className="text-sm text-gray-500 mt-1 sm:mt-0">
                     {(
                       (campaign.amountRaised / campaign.targetAmount) *
                       100
                     ).toFixed(2)}
                     % funded
                   </p>
                 </div>

                 {/* Progress Bar */}
                 <div className="w-full bg-gray-200 rounded-full h-3">
                   <div
                     className="bg-emerald-500 h-3 rounded-full"
                     style={{
                       width: `${
                         (campaign.amountRaised / campaign.targetAmount) * 100
                       }%`,
                     }}
                   />
                 </div>
               </div>

               {/* Testimonial */}
               <div className="bg-gray-50 p-3 rounded-lg mb-4">
                 <p className="text-sm text-gray-700 italic flex items-start">
                   <FaQuoteLeft className="mr-2 text-emerald-600" />
                   {campaign.testimonial}
                 </p>
               </div>

               {/* Action Buttons */}
               <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                 <button className="bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition duration-200 mb-2 sm:mb-0">
                   Donate Now
                 </button>
                 <button className="flex items-center text-gray-500 hover:text-emerald-600">
                   <AiOutlineShareAlt className="mr-1" /> Share
                 </button>
               </div>
             </div>

             {/* Footer */}
             <div className="bg-gray-100 p-4">
               <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                 {/* Secure Payment Notice */}
                 <div className="flex items-center text-gray-500 mb-2 sm:mb-0">
                   <FaShieldAlt className="mr-2" />
                   <span className="text-sm italic">
                     Stripe Secured Payment
                   </span>
                 </div>
                 {/* Tags */}
                 <div className="flex flex-wrap space-x-2">
                   <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-1 rounded mb-1">
                     #Charity
                   </span>
                   <span className="bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 rounded mb-1">
                     #Community
                   </span>
                   <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2 py-1 rounded mb-1">
                     #Support
                   </span>
                 </div>
               </div>
             </div>
           </div>
         ))}
       </div>
     </div>

     {/* Right Sidebar */}
     <RightSidebar />
   </div>
 );
}

export default CampaignsInsideApp;