import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

// Configuration object for country-specific KYC fields
const KYC_CONFIG = {
  US: {
    fields: ['ssn', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'state', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      ssn: 'Social Security Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      postalCode: 'ZIP Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  UK: {
    fields: ['nationalInsurance', 'firstName', 'lastName', 'dob', 'phone', 'email', 'streetAddress', 'city', 'county', 'postalCode', 'countryCode', 'timezone'],
    fieldLabels: {
      nationalInsurance: 'National Insurance Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      streetAddress: 'Street Address',
      city: 'City/Town',
      county: 'County',
      postalCode: 'Postal Code',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  KE: {
    fields: ['idNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'town', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      idNumber: 'ID Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      town: 'Town/City',
      region: 'County',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
  fallback: {
    fields: ['identificationNumber', 'firstName', 'lastName', 'dob', 'phone', 'email', 'city', 'region', 'countryCode', 'timezone'],
    fieldLabels: {
      identificationNumber: 'Identification Number',
      firstName: 'First Name',
      lastName: 'Last Name',
      dob: 'Date of Birth',
      phone: 'Phone Number',
      email: 'Email',
      city: 'City/Town',
      region: 'Region',
      countryCode: 'Country Code',
      timezone: 'Timezone',
    },
  },
};

const UserInfo = ({ userId }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [userKyc, setUserKyc] = useState(null);
  const [kycConfig, setKycConfig] = useState(KYC_CONFIG.fallback);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userResponse = await api.get(`/api/customer/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (userResponse.status === 200 && userResponse.data) {
          setUserInfo(userResponse.data);
          toast.success('User data loaded successfully');
        } else {
          toast.error('Failed to load user data');
        }

        const kycResponse = await api.get(`/api/customer/kyc/${userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (kycResponse.status === 200 && kycResponse.data) {
          setUserKyc(kycResponse.data);
          // Determine the country code and set the appropriate KYC config
          const countryCode = kycResponse.data.countryCode;
          if (countryCode === 'US') {
            setKycConfig(KYC_CONFIG.US);
          } else if (countryCode === 'UK' || countryCode === 'GB') {
            setKycConfig(KYC_CONFIG.UK);
          } else if (countryCode === 'KE') {
            setKycConfig(KYC_CONFIG.KE);
          } else {
            setKycConfig(KYC_CONFIG.fallback);
          }
          toast.success('KYC data loaded successfully');
        } else {
          toast.error('Failed to load KYC data');
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error(`Failed to load data: ${error.response ? error.response.data.message : error.message}`);
      }
    };

    fetchUserInfo();
  }, [userId, user.token]);

  function formatDate(dateString) {
    if (!dateString) return 'No date set';
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: 'long', // "Monday"
      year: 'numeric', // "2021"
      month: 'long', // "July"
      day: 'numeric' // "19"
    });
  }

  // Function to render KYC fields dynamically
  const renderKycFields = () => {
    return kycConfig.fields.map((field) => (
      <div key={field} className="flex justify-between">
        <p>{kycConfig.fieldLabels[field]}:</p>
        <p>
          {field === 'dob' && userKyc[field] ? new Date(userKyc[field]).toLocaleDateString() : userKyc[field]}
        </p>
      </div>
    ));
  };

  return (
    <div className="p-4">
      <ToastContainer />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Account Details */}
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex justify-between items-center mb-1">
            <h3 className="font-semibold text-md mb-2">Account Details</h3>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => navigate(`/user-edit/${userId}`)}
              className="cursor-pointer text-blue-500 hover:text-blue-700"
            />
          </div>

          {userInfo ? (
            <div className="text-xs space-y-2">
              <div className="flex justify-between">
                <p>Username:</p>
                <p>{userInfo.username}</p>
              </div>
              <div className="flex justify-between">
                <p>Email:</p>
                <p>{userInfo.email}</p>
              </div>
              <div className="flex justify-between">
                <p>Phone Number:</p>
                <p>{userInfo.phoneNumber}</p>
              </div>
              <div className="flex justify-between">
                <p>Role:</p>
                <p>{userInfo.role}</p>
              </div>
              <div className="flex justify-between">
                <p>Account Type:</p>
                <p>{userInfo.accountType}</p>
              </div>
              <div className="flex justify-between">
                <p>Pay ID:</p>
                <p>{userInfo.payId}</p>
              </div>
              <div className="flex justify-between items-center">
                <p>Account Status:</p>
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${userInfo.isBanned ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                  {userInfo.isBanned ? 'Banned' : 'Active'}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <p>Release date:</p>
                <p>{formatDate(userInfo.banReleaseDate)}</p>
              </div>
              <div className="flex justify-between">
                <p>Verified:</p>
                <p>{userInfo.isVerified ? 'Yes' : 'No'}</p>
              </div>
              <div className="flex justify-between">
                <p>Phone Verified:</p>
                <p>{userInfo.isPhoneVerified ? 'Yes' : 'No'}</p>
              </div>
              <div className="flex justify-between">
                <p>Premium Account:</p>
                <p>{userInfo.isPremium ? 'Yes' : 'No'}</p>
              </div>
              <div className="flex justify-between">
                <p>Upgrade Request:</p>
                <p>{userInfo.upgradeRequest ? 'Yes' : 'No'}</p>
              </div>
              <div className="flex justify-between">
                <p>Points:</p>
                <p>{userInfo.points}</p>
              </div>
              <div className="flex justify-between">
                <p>Balance:</p>
                <p>{userInfo.balance}</p>
              </div>
              <div className="flex justify-between">
                <p>OTP:</p>
                <p>{userInfo.otp}</p>
              </div>
              <div className="flex justify-between">
                <p>Last Login:</p>
                <p>{userInfo.lastLogin ? new Date(userInfo.lastLogin).toLocaleString() : 'Never'}</p>
              </div>
              <div className="flex justify-between">
                <p>Last Reminder:</p>
                <p>{userInfo.lastReminder ? new Date(userInfo.lastReminder).toLocaleString() : 'Never'}</p>
              </div>
              <div className="flex justify-between">
                <p>Referral Code:</p>
                <p>{userInfo.referralCode}</p>
              </div>
              <div className="flex justify-between">
                <p>Created At:</p>
                <p>{userInfo.createdAt ? formatDate(userInfo.createdAt) : 'N/A'}</p>
              </div>
              {userInfo.trackingInfo && userInfo.trackingInfo.length > 0 && (
                <div className="mt-4">
                  <h4 className="font-semibold">Tracking Info</h4>
                  {userInfo.trackingInfo.map((info, index) => (
                    <div key={index} className="space-y-1">
                      <div className="flex justify-between">
                        <p>Fingerprint ID:</p>
                        <p>{info.fingerprintId}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>User IP:</p>
                        <p>{info.userIp}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Browser:</p>
                        <p>{info.browser}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>OS:</p>
                        <p>{info.os}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Platform:</p>
                        <p>{info.platform}</p>
                      </div>
                      <div className="flex justify-between">
                        <p>Device:</p>
                        <p>{info.device}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <p>Loading user account information...</p>
          )}
        </div>

        {/* KYC Details */}
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex justify-between items-center mb-1">
            <h3 className="font-semibold text-md mb-2">KYC Details</h3>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => navigate(`/kyc-edit/${userId}`)}
              className="cursor-pointer text-blue-500 hover:text-blue-700"
            />
          </div>
          {userKyc ? (
            <div className="text-xs space-y-2">
              {renderKycFields()}
            </div>
          ) : (
            <p>Loading KYC information...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
