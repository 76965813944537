// RightSidebar.js

import React from 'react';
import {
  FaCalendarAlt,
  FaUserFriends,
  FaMedal,
  FaQuoteLeft,
  FaBullhorn,
} from 'react-icons/fa';
import { AiFillGift, AiOutlineArrowRight } from 'react-icons/ai';

function RightSidebar() {
  return (
    <div className="hidden lg:block w-64 p-4 space-y-6">
      {/* Announcements */}
      <div className="bg-yellow-100 p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-2 flex items-center text-yellow-800">
          <FaBullhorn className="mr-2" /> Announcements
        </h3>
        <p className="text-sm text-yellow-700">
          Our annual fundraiser is coming up! Stay tuned for more details.
        </p>
      </div>

      {/* Upcoming Events */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaCalendarAlt className="text-emerald-600 mr-2" /> Upcoming Events
        </h3>
        <ul className="text-sm text-gray-600 space-y-3">
          <li className="flex items-center">
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Nov 20
            </span>
            Charity Run
          </li>
          <li className="flex items-center">
            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Dec 5
            </span>
            Food Drive
          </li>
          <li className="flex items-center">
            <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Dec 18
            </span>
            Holiday Gala
          </li>
        </ul>
        <a
          href="#"
          className="text-emerald-600 text-sm mt-4 flex items-center hover:underline"
        >
          View All Events <AiOutlineArrowRight className="ml-1" />
        </a>
      </div>

      {/* Top Donors */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaMedal className="text-yellow-500 mr-2" /> Top Donors
        </h3>
        <ul className="text-sm text-gray-600 space-y-3">
          <li className="flex items-center">
            <img
              src="https://randomuser.me/api/portraits/men/32.jpg"
              alt="Karen Botsford"
              className="w-8 h-8 rounded-full mr-2"
            />
            <div>
              <p className="font-medium text-gray-700">Karen Botsford</p>
              <p className="text-xs text-gray-500">$5,000</p>
            </div>
          </li>
          <li className="flex items-center">
            <img
              src="https://randomuser.me/api/portraits/women/44.jpg"
              alt="Angeline Gorczany"
              className="w-8 h-8 rounded-full mr-2"
            />
            <div>
              <p className="font-medium text-gray-700">Angeline Gorczany</p>
              <p className="text-xs text-gray-500">$4,500</p>
            </div>
          </li>
          <li className="flex items-center">
            <img
              src="https://randomuser.me/api/portraits/women/65.jpg"
              alt="Emilia Baumbach"
              className="w-8 h-8 rounded-full mr-2"
            />
            <div>
              <p className="font-medium text-gray-700">Emilia Baumbach</p>
              <p className="text-xs text-gray-500">$4,000</p>
            </div>
          </li>
        </ul>
        <a
          href="#"
          className="text-emerald-600 text-sm mt-4 flex items-center hover:underline"
        >
          View Leaderboard <AiOutlineArrowRight className="ml-1" />
        </a>
      </div>

      {/* Friends Who Donated */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaUserFriends className="text-emerald-600 mr-2" /> Friends Who Donated
        </h3>
        <div className="flex -space-x-2 overflow-hidden mb-4">
          <img
            className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
            src="https://randomuser.me/api/portraits/women/68.jpg"
            alt="Emily Davis"
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
            src="https://randomuser.me/api/portraits/men/76.jpg"
            alt="David Wilson"
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
            src="https://randomuser.me/api/portraits/women/29.jpg"
            alt="Sophia Brown"
          />
          <span className="inline-block h-10 w-10 rounded-full ring-2 ring-white bg-gray-200 text-gray-600 flex items-center justify-center">
            +5
          </span>
        </div>
        <a
          href="#"
          className="text-emerald-600 text-sm flex items-center hover:underline"
        >
          See All Friends <AiOutlineArrowRight className="ml-1" />
        </a>
      </div>

      {/* Motivational Quote */}
      <div className="bg-emerald-100 p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-2 flex items-center">
          <FaQuoteLeft className="text-emerald-600 mr-2" /> Inspiration
        </h3>
        <p className="text-sm text-gray-700 italic">
          "No one has ever become poor by giving." - Anne Frank
        </p>
      </div>
    </div>
  );
}

export default RightSidebar;
