import React from 'react';
import { FaStar, FaCheckCircle } from 'react-icons/fa'; // For trust badges and stars

function Testimonials({ testimonials }) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
        What Our Supporters Say
      </h2>
      <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        {testimonials.map((testimonial, index) => (
          <div 
            key={index} 
            className="bg-white p-6 rounded-lg shadow-md text-center border border-gray-200"
          >
            {/* Trust Badge */}
            <div className="flex justify-center mb-2">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-gray-500 text-sm">Verified Supporter</span>
            </div>

            {/* Profile Image */}
            <img
              className="w-24 h-24 rounded-full mx-auto mb-4"
              src={testimonial.image}
              alt={testimonial.name}
            />

            {/* Name and Role */}
            <h3 className="text-lg font-bold text-gray-900 mb-1">{testimonial.name}</h3>
            <p className="text-gray-500 mb-2">{testimonial.role || 'Beneficiary'}</p>

            {/* Star Rating */}
            <div className="flex justify-center mb-4">
              {[...Array(5)].map((_, starIndex) => (
                <FaStar key={starIndex} className="text-yellow-500" />
              ))}
            </div>

            {/* Testimonial Message with Emotional Appeal */}
            <p className="italic text-gray-600 mb-4">"{testimonial.message}"</p>

            {/* Date or Timestamp */}
            <p className="text-sm text-gray-500">Given in {testimonial.date || '2024'}</p>
          </div>
        ))} 
      </div>
    </div>
  );
}

Testimonials.defaultProps = {
  testimonials: [
    {
      name: 'Dr. Olivia White',
      role: 'Medical Assistance Recipient',
      message: 'Verdant Charity helped raise the funds I needed for a critical surgery. Their support saved my life, and I will always be grateful to them.',
      image: 'https://thechurchco-production.s3.amazonaws.com/uploads/sites/7690/2023/12/PL-uneditedArtboard-1-copy-2-800x800.jpg',
      date: 'September 2024',
    },
    {
      name: 'Marcus Taylor',
      role: 'Non-Profit Organizer, WaterAid Initiative',
      message: 'With Verdant Charity’s platform, we were able to surpass our fundraising goal and provide clean water to over 1,000 families. This platform made it possible to bring our vision to life.',
      image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/hargrove-carl.jpg',
      date: 'August 2024',
    },
    {
      name: 'Jon Rehurek',
      role: 'Community Donor',
      message: 'I’ve been a regular donor through Verdant Charity, and every campaign I’ve supported has been transparent and impactful. It’s reassuring to see where my contributions go.',
      image: 'https://s3.amazonaws.com/static.gracechurch.org/images/leaders/rehurek-jon.jpg',
      date: 'July 2024',
    }
  ]
};

export default Testimonials;
