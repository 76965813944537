import React, { useState, useEffect, useMemo } from 'react';
import api from '../../api';
import { useUser } from "../context";
import {
  FaArrowRight,
  FaArrowLeft,
  FaCheckCircle,
  FaRegSmileWink,
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GrantApplication() {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    fullName: '',
    contactPhone: '',
    contactEmail: '',
    businessType: '',
    businessName: '',
    industry: '',
    profitStatus: '',
    description: '',
    fundingAmount: '',
    fundingUse: '',
    otherFundingUse: '',
    revenue: '',
    annualRevenue: '',
    communityImpact: '',
    progressReports: '',
  });

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const steps = useMemo(
    () => [
      {
        id: 1,
        title: 'Full Name',
        component: (
          <div>
  <label className="block mb-6">
    <span className="text-gray-700">What is your full name?</span>
    <input
      type="text"
      name="fullName"
      value={formData.fullName}
      onChange={handleChange}
      className={`mt-2 block w-full border ${
        errors.fullName ? 'border-red-500' : 'border-emerald-300'
      } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 
      py-2 px-3`} // Adjust padding for height
      placeholder="Enter your full name"
      style={{ placeholderColor: 'gray-400' }} // Optionally, add placeholder color if needed
    />
  </label>
  {errors.fullName && (
    <p className="text-red-500 text-sm">{errors.fullName}</p>
  )}
</div>
        ),
        isActive: () => true,
      },
    {
  id: 2,
  title: 'Contact Phone Number',
  component: (
    <div>
      <label className="block mb-6">
        <span className="text-gray-700">What is your contact phone number?</span>
        <input
          type="tel"
          name="contactPhone"
          value={formData.contactPhone}
          onChange={handleChange}
          className={`mt-2 block w-full border ${
            errors.contactPhone ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 py-2 px-3`} // Padding adjustments
          placeholder="Enter your contact phone number"
          style={{ placeholderColor: 'gray-400' }} // Optional placeholder color
        />
      </label>
      {errors.contactPhone && (
        <p className="text-red-500 text-sm">{errors.contactPhone}</p>
      )}
    </div>
  ),
  isActive: () => true,
},
{
  id: 3,
  title: 'Contact Email Address',
  component: (
    <div>
      <label className="block mb-6">
        <span className="text-gray-700">What is your contact email address?</span>
        <input
          type="email"
          name="contactEmail"
          value={formData.contactEmail}
          onChange={handleChange}
          className={`mt-2 block w-full border ${
            errors.contactEmail ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 py-2 px-3`} // Padding adjustments
          placeholder="Enter your contact email address"
          style={{ placeholderColor: 'gray-400' }} // Optional placeholder color
        />
      </label>
      {errors.contactEmail && (
        <p className="text-red-500 text-sm">{errors.contactEmail}</p>
      )}
    </div>
  ),
  isActive: () => true,
},

{
  id: 4,
  title: 'Business Type',
  component: (
    <div >
      <label className="block mb-6">
        <span className="text-gray-700">What type of business are you applying for?</span>
        <div className="relative mt-2">
          <select
            name="businessType"
            value={formData.businessType}
            onChange={handleChange}
            className={`block w-full px-4 py-2 border ${
              errors.businessType ? 'border-red-500' : 'border-emerald-300'
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
            style={{
              WebkitAppearance: 'none', // Removes default styling for Safari
              MozAppearance: 'none', // Removes default styling for Firefox
              appearance: 'none', // Removes default styling for Chrome
            }}
          >
            <option value="" disabled>Select an option</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Startup">Startup</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Existing Business">Existing Business</option>
          </select>
          {/* Chevron Icon */}
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </label>
      {errors.businessType && <p className="text-red-500 text-sm">{errors.businessType}</p>}
    </div>
  ),
  isActive: () => true,
},

     // Step 5: Business Name
{
  id: 5,
  title: 'Business Name',
  component: (
    <div >
      <label className="block mb-6">
        <span className="text-gray-700">What is your business name?</span>
        <input
          type="text"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
          className={`mt-2 block w-full px-4 py-2 border ${
            errors.businessName ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300`}
          placeholder="Enter your business name"
        />
      </label>
      {errors.businessName && (
        <p className="text-red-500 text-sm">{errors.businessName}</p>
      )}
    </div>
  ),
  isActive: () => formData.businessType === 'Existing Business',
},
      // Step 6: Industry & Profit Status
      {
        id: 6,
        title: 'Industry & Profit Status',
        component: (
          <div>
            {/* Industry Dropdown */}
            <label className="block mb-6">
              <span className="text-gray-700">What industry does your business belong to?</span>
              <div className="relative mt-2">
                <select
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  className={`block w-full px-4 py-2 border ${
                    errors.industry ? 'border-red-500' : 'border-emerald-300'
                  } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
                  style={{
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    appearance: 'none',
                  }}
                >
                  <option value="" disabled>Select an industry</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Technology">Technology</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Healthcare">Healthcare</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Education">Education</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Agriculture">Agriculture</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Finance">Finance</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Retail">Retail</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Manufacturing">Manufacturing</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Energy">Energy</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Transportation">Transportation</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Construction">Construction</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Food & Beverage">Food & Beverage</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Entertainment">Entertainment</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Real Estate">Real Estate</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Hospitality">Hospitality</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Other">Other</option>
                </select>
                <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-emerald-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </label>
            {errors.industry && <p className="text-red-500 text-sm">{errors.industry}</p>}
            
            {/* Profit Status Dropdown */}
            <label className="block mb-6">
              <span className="text-gray-700">Is your business for-profit or non-profit?</span>
              <div className="relative mt-2">
                <select
                  name="profitStatus"
                  value={formData.profitStatus}
                  onChange={handleChange}
                  className={`block w-full px-4 py-2 border ${
                    errors.profitStatus ? 'border-red-500' : 'border-emerald-300'
                  } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
                  style={{
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    appearance: 'none',
                  }}
                >
                  <option value="" disabled>Select an option</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="For-Profit">For-Profit</option>
                  <option className="bg-emerald-50 hover:bg-emerald-100" value="Non-Profit">Non-Profit</option>
                </select>
                <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-emerald-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </label>
            {errors.profitStatus && <p className="text-red-500 text-sm">{errors.profitStatus}</p>}
          </div>
        ),
        isActive: () => true,
      },
      
     // Step 7: Business Description
{
  id: 7,
  title: 'Business Description',
  component: (
    <div>
      <label className="block mb-6">
        <span className="text-gray-700">Describe your business or idea in detail.</span>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          rows="5"
          className={`mt-2 block w-full px-4 py-2 border ${
            errors.description ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300`}
          placeholder="Provide a detailed description..."
        ></textarea>
      </label>
      {errors.description && (
        <p className="text-red-500 text-sm">{errors.description}</p>
      )}
    </div>
  ),
  isActive: () => true,
},

// Step 8: Funding Details
{
  id: 8,
  title: 'Funding Details',
  component: (
    <div >
      {/* Funding Amount Dropdown */}
      <label className="block mb-6">
        <span className="text-gray-700">How much funding are you requesting?</span>
        <div className="relative mt-2">
          <select
            name="fundingAmount"
            value={formData.fundingAmount}
            onChange={handleChange}
            className={`block w-full px-4 py-2 border ${
              errors.fundingAmount ? 'border-red-500' : 'border-emerald-300'
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
            style={{
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="" disabled>Select an amount</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="$5,000 - $10,000">$5,000 - $10,000</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="$10,001 - $20,000">$10,001 - $20,000</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="$20,001 - $50,000">$20,001 - $50,000</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="$50,001 - $100,000">$50,001 - $100,000</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Above $100,000">Above $100,000</option>
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </label>
      {errors.fundingAmount && <p className="text-red-500 text-sm">{errors.fundingAmount}</p>}
      
      {/* Funding Use Dropdown */}
      <label className="block mb-6">
        <span className="text-gray-700">What will the funding be used for?</span>
        <div className="relative mt-2">
          <select
            name="fundingUse"
            value={formData.fundingUse}
            onChange={handleChange}
            className={`block w-full px-4 py-2 border ${
              errors.fundingUse ? 'border-red-500' : 'border-emerald-300'
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
            style={{
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="" disabled>Select an option</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Product Development">Product Development</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Marketing">Marketing</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Operations">Operations</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Research and Development">Research and Development</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Hiring Staff">Hiring Staff</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Equipment Purchase">Equipment Purchase</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Other">Other</option>
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </label>
      {errors.fundingUse && <p className="text-red-500 text-sm">{errors.fundingUse}</p>}
    </div>
  ),
  isActive: () => true,
},

    // Step 9: Other Funding Use
{
  id: 9,
  title: 'Other Funding Use',
  component: (
    <div >
      <label className="block mb-6">
        <span className="text-gray-700">Please specify how you will use the funding:</span>
        <input
          type="text"
          name="otherFundingUse"
          value={formData.otherFundingUse}
          onChange={handleChange}
          className={`mt-2 block w-full px-4 py-2 border ${
            errors.otherFundingUse ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300`}
          placeholder="Specify funding use"
        />
      </label>
      {errors.otherFundingUse && (
        <p className="text-red-500 text-sm">{errors.otherFundingUse}</p>
      )}
    </div>
  ),
  isActive: () => formData.fundingUse === 'Other',
},

// Step 10: Revenue Information
{
  id: 10,
  title: 'Revenue Information',
  component: (
    <div >
      {/* Revenue Dropdown */}
      <label className="block mb-6">
        <span className="text-gray-700">Do you have any revenue?</span>
        <div className="relative mt-2">
          <select
            name="revenue"
            value={formData.revenue}
            onChange={handleChange}
            className={`block w-full px-4 py-2 border ${
              errors.revenue ? 'border-red-500' : 'border-emerald-300'
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
            style={{
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="">Select an option</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Yes">Yes</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="No">No</option>
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </label>
      {errors.revenue && (
        <p className="text-red-500 text-sm">{errors.revenue}</p>
      )}

      {/* Annual Revenue Field (Visible if Revenue is Yes) */}
      {formData.revenue === 'Yes' && (
        <label className="block mb-6">
          <span className="text-gray-700">What is your annual revenue?</span>
          <input
            type="text"
            name="annualRevenue"
            value={formData.annualRevenue}
            onChange={handleChange}
            className={`mt-2 block w-full px-4 py-2 border ${
              errors.annualRevenue ? 'border-red-500' : 'border-emerald-300'
            } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300`}
            placeholder="Enter your annual revenue"
          />
          {errors.annualRevenue && (
            <p className="text-red-500 text-sm">{errors.annualRevenue}</p>
          )}
        </label>
      )}
    </div>
  ),
  isActive: () => formData.businessType === 'Existing Business',
},

     // Step 11: Community Impact
{
  id: 11,
  title: 'Community Impact',
  component: (
    <div >
      <label className="block mb-6">
        <span className="text-gray-700">How will your business impact the community?</span>
        <textarea
          name="communityImpact"
          value={formData.communityImpact}
          onChange={handleChange}
          rows="5"
          className={`mt-2 block w-full px-4 py-2 border ${
            errors.communityImpact ? 'border-red-500' : 'border-emerald-300'
          } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300`}
          placeholder="Describe the potential community impact..."
        ></textarea>
      </label>
      {errors.communityImpact && (
        <p className="text-red-500 text-sm">{errors.communityImpact}</p>
      )}
    </div>
  ),
  isActive: () => true,
},

// Step 12: Progress Reports
{
  id: 12,
  title: 'Progress Reports',
  component: (
    <div >
      {/* Progress Reports Dropdown */}
      <label className="block mb-6">
        <span className="text-gray-700">Are you willing to provide progress reports?</span>
        <div className="relative mt-2">
          <select
            name="progressReports"
            value={formData.progressReports}
            onChange={handleChange}
            className={`block w-full px-4 py-2 border ${
              errors.progressReports ? 'border-red-500' : 'border-emerald-300'
            } bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 sm:text-sm transition duration-300 appearance-none`}
            style={{
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
            }}
          >
            <option value="">Select an option</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="Yes">Yes</option>
            <option className="bg-emerald-50 hover:bg-emerald-100" value="No">No</option>
          </select>
          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.92l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </label>
      {errors.progressReports && (
        <p className="text-red-500 text-sm">{errors.progressReports}</p>
      )}
    </div>
  ),
  isActive: () => true,
},

   
      {
        id: 13,
        title: 'Confirmation Question',
        component: (
          <div className="text-center">
            <FaCheckCircle className="text-emerald-600 text-6xl mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-gray-700">
              Are you satisfied with the information provided and ready to submit your application?
            </h3>
          </div>
        ),
        isActive: () => true,
      },
      {
        id: 14,
        title: 'Final Confirmation',
        component: (
          <div className="text-center">
            <FaRegSmileWink className="text-emerald-600 text-6xl mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-emerald-700">
              Thank you for completing the application!
            </h3>
            <p className="mt-4 text-gray-700">
              We have received your application and will review it shortly.
            </p>
          </div>
        ),
        isActive: () => true,
      },
    ],
    [formData, errors]
  );

  const activeSteps = useMemo(() => steps.filter((step) => step.isActive()), [steps]);
  const safeCurrentStepIndex = Math.min(currentStepIndex, activeSteps.length - 1);

  const getStepErrors = () => {
    const step = activeSteps[safeCurrentStepIndex];
    if (!step) return {};
  
    let newErrors = {};
  
    switch (step.id) {
      case 1:
        if (!formData.fullName.trim()) {
          newErrors.fullName = 'Please enter your full name.';
        } else if (formData.fullName.trim().length < 2) {
          newErrors.fullName = 'Full name must be at least 2 characters.';
        }
        break;
      case 2:
        if (!formData.contactPhone.trim()) {
          newErrors.contactPhone = 'Please enter your contact phone number.';
        } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.contactPhone.trim())) {
          newErrors.contactPhone = 'Please provide a valid phone number.';
        }
        break;
      case 3:
        if (!formData.contactEmail.trim()) {
          newErrors.contactEmail = 'Please enter your contact email address.';
        } else if (!/\S+@\S+\.\S+/.test(formData.contactEmail.trim())) {
          newErrors.contactEmail = 'Please provide a valid email address.';
        }
        break;
      case 4:
        if (!formData.businessType) {
          newErrors.businessType = 'Please select a business type.';
        }
        break;
      case 5:
        if (formData.businessType === 'Existing Business') {
          if (!formData.businessName.trim()) {
            newErrors.businessName = 'Please enter your business name.';
          }
        }
        break;
      case 6:
        if (!formData.industry) {
          newErrors.industry = 'Please select an industry.';
        }
        if (!formData.profitStatus) {
          newErrors.profitStatus = 'Please select profit status.';
        }
        break;
      case 7:
        if (!formData.description.trim()) {
          newErrors.description = 'Please provide a description.';
        } else if (formData.description.trim().length < 20) {
          newErrors.description = 'Description must be at least 20 characters.';
        }
        break;
      case 8:
        if (!formData.fundingAmount) {
          newErrors.fundingAmount = 'Please select a funding amount.';
        }
        if (!formData.fundingUse) {
          newErrors.fundingUse = 'Please select how the funding will be used.';
        }
        break;
      case 9:
        if (formData.fundingUse === 'Other') {
          if (!formData.otherFundingUse.trim()) {
            newErrors.otherFundingUse = 'Please specify how the funding will be used.';
          } else if (formData.otherFundingUse.trim().length < 5) {
            newErrors.otherFundingUse = 'Please provide a more detailed response.';
          }
        }
        break;
      case 10:
        if (formData.businessType === 'Existing Business') {
          if (!formData.revenue) {
            newErrors.revenue = 'Please select an option.';
          } else if (formData.revenue === 'Yes') {
            if (!formData.annualRevenue.trim()) {
              newErrors.annualRevenue = 'Please enter your annual revenue.';
            } else if (formData.annualRevenue.trim().length < 3) {
              newErrors.annualRevenue = 'Please provide a valid revenue amount.';
            }
          }
        }
        break;
      case 11:
        if (!formData.communityImpact.trim()) {
          newErrors.communityImpact = 'Please describe the community impact.';
        } else if (formData.communityImpact.trim().length < 20) {
          newErrors.communityImpact = 'Community impact description must be at least 20 characters.';
        }
        break;
      case 12:
        if (!formData.progressReports) {
          newErrors.progressReports = 'Please select an option.';
        }
        break;
      case 13:
        // No validation needed for confirmation question
        break;
      case 14:
        // No validation needed for final confirmation
        break;
      default:
        break;
    }
  
    return newErrors;
  };
  

  useEffect(() => {
    const stepErrors = getStepErrors();
    setErrors(stepErrors);
  }, [formData, safeCurrentStepIndex]);

  const isStepValid = useMemo(() => Object.keys(errors).length === 0, [errors]);

  const handleNext = (e) => {
    e.preventDefault();
    if (isStepValid) {
      if (safeCurrentStepIndex < activeSteps.length - 1) {
        setCurrentStepIndex(safeCurrentStepIndex + 1);
      }
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (safeCurrentStepIndex > 0) {
      setCurrentStepIndex(safeCurrentStepIndex - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStepValid) return;
    setIsSubmitting(true);
    try {
      const response = await api.post('/api/grant-applications', formData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      });
      toast.success(response.data.message || 'Grant application submitted successfully.');
      setCurrentStepIndex(safeCurrentStepIndex + 1);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again later.';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateProgress = () => Math.round(((safeCurrentStepIndex + 1) / activeSteps.length) * 100);

  return (
    <div className="min-h-screen py-12 px-4 bg-gradient-to-r from-green-100 to-green-200">
      <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-emerald-700 mb-6 text-center">Grant Application</h2>
  
        {/* Progress Bar */}
        <div className="w-full bg-gray-300 rounded-full h-4 mb-8 overflow-hidden">
          <div
            className="bg-emerald-600 h-4 rounded-full transition-all duration-300 text-xs text-white text-center leading-4"
            style={{ width: `${calculateProgress()}%` }}
          >
            {calculateProgress()}%
          </div>
        </div>
  
        {/* Step Content */}
        <form
          onSubmit={
            activeSteps[safeCurrentStepIndex].id === 13
              ? handleSubmit // Trigger the submission function on confirmation step
              : (e) => e.preventDefault()
          }
        >
          {activeSteps[safeCurrentStepIndex].component}
        </form>
  
        {/* Navigation Buttons */}
        <div className="flex flex-col sm:flex-row justify-between mt-8 space-y-4 sm:space-y-0 sm:space-x-4">
          {safeCurrentStepIndex > 0 && (
            <button
              onClick={handleBack}
              disabled={isSubmitting}
              className="w-full sm:w-auto px-6 py-2 bg-gray-200 text-gray-700 font-semibold rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-300"
            >
              <FaArrowLeft className="inline mr-2" />
              Back
            </button>
          )}
  
          {/* Show Next or Submit button based on the step */}
          {safeCurrentStepIndex < activeSteps.length - 1 && (
            <button
              onClick={safeCurrentStepIndex === activeSteps.length - 2 ? handleSubmit : handleNext}
              disabled={!isStepValid || isSubmitting}
              className={`w-full sm:w-auto px-6 py-2 font-semibold rounded focus:outline-none focus:ring-2 transition duration-300 ${
                isStepValid
                  ? 'bg-emerald-600 text-white hover:bg-emerald-700 focus:ring-emerald-500'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {isSubmitting ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white inline"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : safeCurrentStepIndex === activeSteps.length - 2 ? (
                <>
                  Confirm and Submit
                  <FaCheckCircle className="inline ml-2" />
                </>
              ) : (
                <>
                  Next
                  <FaArrowRight className="inline ml-2" />
                </>
              )}
            </button>
          )}
        </div>
  
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default GrantApplication;