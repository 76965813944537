import React, { useEffect, useState } from 'react';
import api from '../../../api';
import { useUser } from "../../context";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

const Deposits = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [deposits, setDeposits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [depositsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchDeposits = async () => {
            try {
                const response = await api.get(`/api/admin/deposits`, {
                    params: { page: currentPage, limit: depositsPerPage },
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (response.status === 200) {
                    setDeposits(response.data.deposits);
                    const totalItems = response.data.total;
                    setTotalPages(Math.ceil(totalItems / depositsPerPage));
                } else {
                    toast.error('Failed to load deposit data');
                }
            } catch (error) {
                console.error("Error fetching deposit data: ", error);
                toast.error(`Failed to load deposit data: ${error.message}`);
            }
        };

        fetchDeposits();
    }, [currentPage, user.token, depositsPerPage]);

    const getPagination = () => {
        const delta = 1;
        const range = [];
        for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
            range.push(i);
        }
        if (currentPage - delta > 2) {
            range.unshift("...");
        }
        if (currentPage + delta < totalPages - 1) {
            range.push("...");
        }
        range.unshift(1);
        if (totalPages !== 1) range.push(totalPages);

        return range.map((page, index) => page === "..." ? <span key={index} className="px-2">...</span> : <button key={index} onClick={() => setCurrentPage(page)} className={`px-2 py-1 ${page === currentPage ? 'bg-emerald-500 text-white' : 'bg-gray-200'}`}>{page}</button>);
    };

    return (
        <div className="bg-white shadow rounded-lg p-4 mt-5">
            <h3 className="font-semibold text-lg mb-4">Deposits</h3>
            <div className="overflow-x-auto">
                <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th className="px-4 py-2">Deposit ID</th>
                            <th className="px-4 py-2">Phone Number</th>
                            <th className="px-4 py-2">Amount</th>
                            <th className="px-4 py-2">Mpesa</th>
                            <th className="px-4 py-2">Transaction Date</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">View More</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits.map((deposit) => (
                            <tr key={deposit._id}>
                                <td className="px-4 py-2">{deposit.userEmail}</td>
                                <td className="px-4 py-2">{deposit.initiatorPhoneNumber}</td>
                                <td className="px-4 py-2">{deposit.amount}</td>
                                <td className="px-4 py-2">{deposit.mpesaReceiptNumber}</td>
                                <td className="px-4 py-2">
                                    {formatDistanceToNow(new Date(deposit.transactionDate), { addSuffix: true })}
                                </td>
                                <td className="px-4 py-2">
                                    <span className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${deposit.isSuccess ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                        {deposit.isSuccess ? 'Completed' : 'Failed'}
                                    </span>
                                </td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => navigate(`/user-details/${deposit.user._id}`)}
                                        className="px-4 py-2 border border-gray-300 rounded text-xs hover:bg-gray-100"
                                    >
                                        View More
                                    </button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            <div className="flex justify-center space-x-2 mt-4">
                {getPagination()}
            </div>
        </div>
    );
};

export default Deposits;
