import React from 'react';

const CommunityStandards = () => {
  return (
    <div className="bg-white rounded-lg max-w-full sm:max-w-2xl mx-auto p-4 sm:p-6">
      <h2 className="text-3xl font-semibold text-gray-900 mb-6 text-center">
        Community Standards
      </h2>

      <div className="p-6 border border-gray-200 rounded-lg overflow-y-auto max-h-[600px] space-y-8">
        <p className="text-gray-700 text-base">
          At Verdant Charity, our community is built on trust, mutual respect, and a commitment to a positive experience for all. By engaging in this community, you agree to uphold the following standards.
        </p>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">1. Respectful Communication</h3>
          <p className="text-gray-700 text-sm">
            Respect each other’s opinions and perspectives. Discrimination, hate speech, or harassment of any kind is prohibited. Let's ensure our community remains welcoming to everyone.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">2. Privacy and Confidentiality</h3>
          <p className="text-gray-700 text-sm">
            Protect the privacy of others. Refrain from sharing personal or sensitive information without explicit permission. Privacy violations may lead to immediate action.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">3. No Spam or Unsolicited Promotion</h3>
          <p className="text-gray-700 text-sm">
            Keep discussions meaningful and focused. Spam, unsolicited promotions, and irrelevant posts disrupt the community experience and will be removed.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">4. Reporting Violations</h3>
          <p className="text-gray-700 text-sm">
            If you observe any behavior that conflicts with our standards, report it to our team. Reports are treated confidentially, and we will handle each case promptly.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">5. Constructive Feedback</h3>
          <p className="text-gray-700 text-sm">
            When sharing feedback, please ensure it is constructive and respectful. Constructive criticism fosters improvement and helps maintain a positive environment.
          </p>
        </section>

        <section>
          <h3 className="text-lg font-semibold text-gray-900">6. Safe Content Sharing</h3>
          <p className="text-gray-700 text-sm">
            Be mindful when sharing content. Avoid harmful, misleading, or inappropriate content that could jeopardize the well-being of others or the community's integrity.
          </p>
        </section>

        <p className="text-gray-700 text-sm">
          By following these standards, we can create a safe, inclusive, and uplifting space for all our members. Thank you for your dedication to upholding our community's values!
        </p>
      </div>

      <p className="text-gray-500 mt-6 text-xs text-center">
        Last Updated: January 1, 2024
      </p>
    </div>
  );
};

export default CommunityStandards;
