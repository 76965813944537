import React, { useState, useEffect } from 'react';
import { FaTimes, FaBell, FaCheckCircle, FaRegClock } from 'react-icons/fa'; // Importing necessary icons
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import Lottie from 'lottie-react';
import api from '../api';
import loadingAnimation from './lottie/loading.json';
import { useUser } from "./context";

const NotificationsPanel = ({ setShowNotifications }) => {
    const { user } = useUser();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch notifications from the server
        const fetchNotifications = async () => {
            try {
                setLoading(true);
                const userToken = user?.token;
                const response = await api.get('/api/notifications/unread', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    }
                });
                setNotifications(response.data || []);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                setLoading(false);
            }
        };
        fetchNotifications();
    }, [user?.token]);

    const markAsRead = async (notificationId) => {
        try {
            const userToken = user?.token;
            setNotifications(prev => prev.filter(n => n._id !== notificationId));

            await api.post(`/api/notifications/mark-read/${notificationId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${userToken}`
                }
            });
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    return (
        <div className="notifications-panel fixed inset-x-0 bottom-0 mb-16 mx-4 p-4 bg-emerald-500 rounded-t-md transform transition-transform duration-300 text-white-600 font-harmattan">
            <div className="flex justify-between items-center border-b border-emerald-300 pb-2">
                <h2 className="text-lg text-emerald-900 font-bold px-2">
                    <FaBell className="inline-block mr-2 text-red-900 text-lg" /> Notifications
                </h2>
                <button onClick={() => setShowNotifications(false)} className="text-white font-bold">
                    <FaTimes />
                </button>
            </div>
            <ul className="overflow-y-auto" style={{ maxHeight: '60vh' }}>
                {loading ? (
                    <div className="flex justify-center items-center py-4">
                        <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                    </div>
                ) : notifications.length > 0 ? (
                    notifications.map(notification => (
                        <li key={notification._id} className="border-b border-emerald-900 py-2">
                            <div className="flex flex-col justify-between">
                                <div className="flex items-center">
                                    <FaRegClock className="mr-2 text-xs text-emerald-900" />
                                    <span className="text-emerald-900 text-xs hover:text-white mr-2" style={{ fontStyle: 'italic' }}>
                                        {new Date(notification.createdAt).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })}
                                    </span>
                                    <span className="text-emerald-900 text-xs hover:text-white" style={{ fontStyle: 'italic' }}>
                                        {new Date(notification.createdAt).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </span>
                                </div>

                                <span className="text-white">{notification.text}</span>
                                {!notification.isRead && (
                                    <div className="flex justify-end items-center gap-1 mt-1">
                                        <IoMdCheckmarkCircleOutline size={12} />
                                        <button className="text-emerald-900 text-xs hover:text-white" onClick={() => markAsRead(notification._id)}>
                                            Mark as Read
                                        </button>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))
                ) : (
                    <li className="text-white text-center py-4">No notifications</li>
                )}
            </ul>
        </div>
    );
};

export default NotificationsPanel;
