// Profile.js

import React, { useState, useEffect } from 'react';
import {
  FaMapMarkerAlt,
  FaCrown,
  FaBusinessTime,
  FaUser,
  FaShieldAlt,
  FaInfoCircle,
  FaHandsHelping,
  FaMedal,
  FaCalendarAlt, FaUserFriends, FaTrophy, FaBell, FaDonate 
} from 'react-icons/fa';
import { AiOutlineArrowLeft, AiOutlineUser, AiOutlineArrowRight } from 'react-icons/ai';
import PersonalInfo from './PersonalInfo';
import NotificationSettings from './NotificationSettings';
import Authentication from './Authentication';
import TermsOfService from './TermsOfService';
import CommunityStandards from './CommunityStandards';
import UserBadges from './UserBadges'; // Import UserBadges component
import { useUser } from "../context";
import api from '../../api';

const Profile = () => {
  const { user } = useUser();
  const [activeSection, setActiveSection] = useState(null);
  const [mostRecentLocation, setMostRecentLocation] = useState('Location unavailable');

  const profilePhotoUrl =
    user?.profilePhotoUrl ||
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLgeB7GPHeXY4XZB6p_Gb1rQOjdofnQ4y3FAU3X7LKhWK12meoTCsZPva7eVicCJ9x-6M&usqp=CAU';
  const coverPhotoUrl =
    user?.coverPhotoUrl || 'https://www.lesecologycenter.org/wp-content/uploads/page/Vol-IMG_4255-2048x1536.jpg';

  useEffect(() => {
    const fetchRecentLoginHistory = async () => {
      try {
        const response = await api.get('/api/auth/recent-login-history', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user?.token}`,
          },
        });
        const history = response.data;
        if (history.length > 0) {
          const { locationName } = history[0];
          setMostRecentLocation(locationName || 'Location unavailable');
        }
      } catch (error) {
        console.error('Failed to fetch login history:', error);
      }
    };

    if (user?.token) {
      fetchRecentLoginHistory();
    }
  }, [user]);

  const handleBack = () => setActiveSection(null);

  const renderActiveSection = () => {
    const sectionComponents = {
      PersonalInfo: <PersonalInfo />,
      Notifications: <NotificationSettings />,
      Authentication: <Authentication />,
      TermsOfService: <TermsOfService />,
      CommunityStandards: <CommunityStandards />,
      Badges: <UserBadges />, // Added Badges section
    };

    return (
      <div className="w-full">
        <button
          onClick={handleBack}
          className="flex items-center p-2 text-emerald-600 hover:text-emerald-800 transition duration-150 ease-in-out mb-4"
        >
          <span className="inline-flex items-center justify-center p-2 rounded-full border border-emerald-600 bg-emerald-100 hover:bg-emerald-200">
            <AiOutlineArrowLeft />
          </span>
          <span className="ml-2">Back</span>
        </button>
        <div className="w-full">
          {sectionComponents[activeSection]}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Main Content */}
      <div className="bg-white rounded-lg shadow-md w-full lg:w-2/3 mx-auto p-4 sm:p-6">
        <div className="relative">
          <img src={coverPhotoUrl} alt="Cover" className="w-full h-40 object-cover sm:h-60 rounded-t-lg" />
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
            <img
              src={profilePhotoUrl}
              alt="Profile"
              className="h-24 w-24 rounded-full border-4 border-white sm:h-32 sm:w-32"
            />
          </div>
        </div>

        <div className="mt-16 sm:mt-20 text-center px-4 sm:px-6">
          <h1 className="text-xl font-bold text-gray-800 sm:text-2xl flex items-center justify-center">
            {user?.primaryInfo?.firstName} {user?.primaryInfo?.lastName}
            {user?.isPremium && <FaCrown className="ml-2 text-yellow-500 inline-block" />}
          </h1>
          <p className="text-gray-600 mt-1 flex justify-center items-center">
            {user?.accountType === 'business' ? (
              <>
                <FaBusinessTime className="inline-block mr-1" /> Business Account
              </>
            ) : (
              <>
                <FaUser className="inline-block mr-1" /> Individual Account
              </>
            )}
          </p>
          <p className="text-gray-500 text-sm mt-1">
            Joined on {new Date(user?.primaryInfo?.createdAt).toLocaleDateString()}
          </p>
          <div className="flex justify-center items-center mt-2">
            <FaMapMarkerAlt className="text-red-500 mr-1" />
            <span className="text-gray-500 text-sm">{mostRecentLocation}</span>
          </div>
        </div>

        <div className="border-t border-gray-300 my-6"></div>

        {activeSection ? (
          renderActiveSection()
        ) : (
          <div className="mt-8 space-y-2">
            {[
              { label: 'Personal Info', section: 'PersonalInfo', icon: <AiOutlineUser /> },
              { label: 'Badges', section: 'Badges', icon: <FaMedal /> }, // Added Badges to the list
              { label: 'Notifications', section: 'Notifications', icon: <FaBell /> },
              { label: 'Authentication', section: 'Authentication', icon: <FaShieldAlt /> },
              { label: 'Terms of Service', section: 'TermsOfService', icon: <FaInfoCircle /> },
              { label: 'Community Standards', section: 'CommunityStandards', icon: <FaHandsHelping /> },
            ].map((item) => (
              <button
                key={item.section}
                onClick={() => setActiveSection(item.section)}
                className="w-full text-left py-3 px-4 font-semibold text-gray-700 hover:bg-emerald-50 focus:outline-none border-b border-gray-200 transition ease-in-out duration-150 flex items-center"
              >
                <span className="mr-3 text-emerald-600">{item.icon}</span>
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Right Sidebar */}
      <div className="hidden lg:block w-72 p-4">
      {/* User Summary */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <div className="flex items-center mb-4">
          <img
            src={profilePhotoUrl}
            alt="User Avatar"
            className="w-12 h-12 rounded-full mr-3"
          />
        <div>
  <p className="text-gray-800 font-bold">Welcome Back!</p>
  <p className="text-gray-500 text-sm italic">
    @{`${user?.primaryInfo?.firstName}${user?.primaryInfo?.lastName}`.toLowerCase()}
  </p>
</div>

        </div>
        <div className="flex justify-between text-center">
          <div>
            <p className="text-gray-800 font-bold">0</p>
            <p className="text-gray-500 text-sm">Donations</p>
          </div>
          <div>
            <p className="text-gray-800 font-bold">0</p>
            <p className="text-gray-500 text-sm">Campaigns</p>
          </div>
          <div>
            <p className="text-gray-800 font-bold">2</p>
            <p className="text-gray-500 text-sm">Badges</p>
          </div>
        </div>
      </div>

      {/* Notifications */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaBell className="mr-2 text-yellow-500" /> Notifications
        </h3>
        <ul className="text-sm text-gray-600 space-y-3">
          <li className="flex items-start">
            <FaUserFriends className="text-blue-500 mr-2 mt-1" />
            <span><strong>Verdant charity</strong> started following you.</span>
          </li>
          <li className="flex items-start">
            <FaDonate className="text-green-500 mr-2 mt-1" />
            <span>Your donation to <strong>Clean Water Initiative</strong> was successful.</span>
          </li>
          <li className="flex items-start">
            <FaTrophy className="text-orange-500 mr-2 mt-1" />
            <span>You earned the <strong>Donor</strong> badge.</span>
          </li>
        </ul>
      </div>

         {/* Upcoming Events */}
         <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaCalendarAlt className="text-emerald-600 mr-2" /> Upcoming Events
        </h3>
        <ul className="text-sm text-gray-600 space-y-3">
          <li className="flex items-center">
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Nov 20
            </span>
            Charity Run
          </li>
          <li className="flex items-center">
            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Dec 5
            </span>
            Food Drive
          </li>
          <li className="flex items-center">
            <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
              Dec 18
            </span>
            Holiday Gala
          </li>
        </ul>
        <a
          href="#"
          className="text-emerald-600 text-sm mt-4 flex items-center hover:underline"
        >
          View All Events <AiOutlineArrowRight className="ml-1" />
        </a>
      </div>

      {/* Trending Campaigns */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold mb-4 flex items-center">
          <FaTrophy className="mr-2 text-purple-500" /> Trending Campaigns
        </h3>
        <ul className="text-sm text-gray-600 space-y-3">
          <li>
            <p className="font-bold">Education for All</p>
            <p className="text-gray-500 text-xs">Providing education to underprivileged children.</p>
          </li>
          <li>
            <p className="font-bold">Disaster Relief Fund</p>
            <p className="text-gray-500 text-xs">Supporting communities affected by natural disasters.</p>
          </li>
          <li>
            <p className="font-bold">Wildlife Conservation</p>
            <p className="text-gray-500 text-xs">Protecting endangered species and habitats.</p>
          </li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default Profile;
