// src/components/grants/GrantsContainer.js

import React, { useState, useEffect } from 'react';
import { useUser } from "../context";
import IntroductionPage from './IntroductionPage';
import GrantApplication from './GrantApplication';
import GrantStatus from './GrantStatus';
import GrantsUnavailable from './GrantsUnavailable';
import api from '../../api';
import { toast } from 'react-toastify';

const GrantsContainer = () => {
  const { user } = useUser();
  const disallowedRegions = ['KE','TZ', 'ZM']; // Define disallowed regions here

  // State to manage the current view
  const [currentView, setCurrentView] = useState('loading');

  useEffect(() => {
    const fetchApplicationCount = async () => {
      try {
        // Make the API call to check if the user has existing applications
        const response = await api.get('/api/grant-applications/user/count', {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        // If user has an existing application, set the view to 'status'
        if (response.data.count > 0) {
          setCurrentView('status');
        } else {
          // Otherwise, default to the introduction page
          setCurrentView('introduction');
        }
      } catch (err) {
        console.error('Error fetching application count:', err);
        toast.error('Failed to fetch application count. Please try again later.');
        setCurrentView('introduction'); // Default to introduction if there’s an error
      }
    };

    fetchApplicationCount();
  }, [user.token]);

  // Handle Start Application based on region eligibility
  const handleStartApplication = () => {
    if (disallowedRegions.includes(user.country)) {
      setCurrentView('unavailable');
    } else {
      setCurrentView('application');
    }
  };

  // Render components based on the current view
  return (
    <div className="min-h-screen py-12 px-4 bg-gradient-to-r from-green-100 to-green-200">
      {currentView === 'introduction' && <IntroductionPage onStart={handleStartApplication} />}
      {currentView === 'application' && <GrantApplication />}
      {currentView === 'unavailable' && <GrantsUnavailable onBack={() => setCurrentView('introduction')} />}
      {currentView === 'status' && <GrantStatus />}
      {currentView === 'loading' && (
        <div className="flex justify-center items-center h-screen">
          <svg
            className="animate-spin h-10 w-10 text-emerald-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};

export default GrantsContainer;
