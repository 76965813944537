import React, { useState, useEffect } from 'react';
import Hero from './Hero';
import Testimonials from './testimonials_component';
import CallToAction from './call_to_action_component';
import CampaignsOverview from './campaigns_overview_component';
import AboutUs from './about_us_component';

function Home() {
  

  return (
    <div className="flex flex-col min-h-screen pb-20 bg-gradient-to-r from-blue-50 to-blue-100">
      {/* Hero Section with bottom margin */}
      <div className="">
        <Hero />
      </div>
    
      {/* Rotating Logos Section */}
      <div className="overflow-hidden  bg-white shadow-md">
        <div className="animate-scroll flex items-center space-x-10 p-4">
          {/* Repeat the company logos multiple times to ensure seamless scroll */}
          <img
            src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
            alt="Company Logos"
            className="h-16 w-auto"
          />
          <img
            src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-2.1ea7d28d.svg"
            alt="Company Logos"
            className="h-16 w-auto"
          />
          <img
            src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-3.4922153e.svg"
            alt="Company Logos"
            className="h-16 w-auto"
          />
          <img
            src="https://website-cdn.ipinfo.io/_next/static/media/customers-group-1.61d55751.svg"
            alt="Company Logos"
            className="h-16 w-auto"
          />
        </div>
      </div>

      {/* About Us Section */}
      <div className="bg-white py-4 px-4 sm:px-6 lg:px-8">
        <AboutUs />
      </div>


      {/* Campaigns Overview Section */}
      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
        <CampaignsOverview />
      </div>

      {/* Testimonials Section */}
      <div className="bg-gradient-to-r from-green-50 to-green-100 py-16 px-4 sm:px-6 lg:px-8">
        <Testimonials />
      </div>

     {/* Call to Action Section */}
<div className="w-full py-4 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
  <CallToAction />
</div>
    </div>
  );
}

export default Home;
