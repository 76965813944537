// components/auth/signup.js
import { useState, useRef, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import api from '../../api';
import axios from 'axios';
import { AiOutlineMail, AiOutlineEye, AiOutlinePhone, AiOutlineEyeInvisible, AiOutlineLock } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import countryList from 'react-select-country-list'; // Import the country list

const Signup = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({ value: 'us', label: 'United States' });
    const [signupData, setSignupData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        countryCode: 'us', // Default to 'US'
    });

    const countries = countryList().getData();

    useEffect(() => {
        axios.get('https://ipinfo.io/json?token=bff95b04d027fd')
            .then(response => {
                const country = response.data.country.toLowerCase(); // Convert to lowercase
                const detectedCountry = countries.find(c => c.value.toLowerCase() === country); // Match country in lowercase
    
                console.log('IP Info Country:', response.data.country); // Should be 'KE'
                console.log('Detected Country:', detectedCountry); // Log the matched country in your countries array
    
                if (detectedCountry) {
                    setSelectedCountry(detectedCountry);  // Set the detected country for the dropdown
                    setSignupData(prev => ({
                        ...prev,
                        countryCode: detectedCountry.value  // Set the country code, e.g., 'ke' for backend
                    }));
                } else {
                    // Default to 'us' if no match found
                    setSelectedCountry({ value: 'us', label: 'United States' });
                    setSignupData(prev => ({ ...prev, countryCode: 'us' }));
                }
            })
            .catch(() => {
                // Default to 'US' if IPInfo request fails
                setSelectedCountry({ value: 'us', label: 'United States' });
                setSignupData(prev => ({ ...prev, countryCode: 'us' }));
            });
    }, [countries]);
    
    

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSignupData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePhoneNumberChange = (value) => {
        setSignupData(prevState => ({
            ...prevState,
            phoneNumber: value,
        }));
        validatePhoneNumber(value); // Validate phone number as the user types
    };

    const validatePhoneNumber = (value) => {
        try {
            if (!value || value.length < 3) {  // Check for empty or very short numbers
                setPhoneError('Phone number is too short.');
                setValidPhone(false);
                return;
            }
    
            const phoneNumber = parsePhoneNumberFromString(value, selectedCountry.value.toUpperCase());
    
            if (phoneNumber) {
                // Check if the number is valid and possible
                if (phoneNumber.isValid() && phoneNumber.isPossible()) {
                    setPhoneError('');
                    setValidPhone(true);
                } else if (!phoneNumber.isPossible()) {
                    setPhoneError(`The phone number is not possible for ${selectedCountry.label}. Please check the length.`);
                    setValidPhone(false);
                } else {
                    setPhoneError(`Invalid phone number for ${selectedCountry.label}. Please check the format.`);
                    setValidPhone(false);
                }
            } else {
                setPhoneError('Invalid phone number format.');
                setValidPhone(false);
            }
        } catch (error) {
            setPhoneError('Unexpected error. Please check your input.');
            setValidPhone(false);
        }
    };
    

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSignupData(prev => ({
            ...prev,
            countryCode: selectedOption.value,
            phoneNumber: '' // Reset the phone number when country changes
        }));
        setPhoneError(''); // Clear error on country change
    };

    useEffect(() => {
        // Revalidate the phone number when the country changes
        if (signupData.phoneNumber) {
            validatePhoneNumber(signupData.phoneNumber);
        }
    }, [selectedCountry]);

    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (signupData.password !== signupData.confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            const userData = {
                email: signupData.email,
                password: signupData.password,
                phoneNumber: signupData.phoneNumber,  // Phone number sent to the backend
                countryCode: signupData.countryCode   // Country code (e.g., 'US', 'KE', etc.) sent to the backend
            };

            const response = await api.post("/api/auth/signup", userData);

            if (response.status === 200 || response.status === 201) {
                setSignupData({ email: '', password: '', phoneNumber: '', confirmPassword: '', countryCode: 'us' });
                navigate('/login');
            } else {
                throw new Error('Unexpected response from server');
            }

        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message || 'Failed to sign up. Please try again.');
            } else {
                setError(error.message || 'Failed to sign up. Please try again.');
            }
            setLoading(false);
        }
    };



    return (
        <div className="min-h-screen w-full flex items-center justify-center bg-cover bg-center"
            style={{
                backgroundImage: "url('/auth_images/auth_background.jpg')",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
                <div className="relative z-10 p-8 rounded-lg max-w-md w-full space-y-8 bg-black bg-opacity-10 text-center">
                    <div className="max-w-md w-full space-y-8">
                        <div className="flex justify-center">
                            <img src="https://cdn-icons-png.flaticon.com/512/8910/8910788.png" alt="Login Icon" className="w-24 h-auto mb-1" />
                        </div>
                        <div className="text-center">
                            <h2 className="text-xs sm:text-2xl md:text-3xl font-extrabold text-emerald-600 mb-2">
                                Sign Up
                            </h2>
                        </div>

                        <form className="mt-8 space-y-6" onSubmit={handleSignup}>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <strong className="font-bold">Error!</strong>
                                    <span className="block sm:inline"> {error}</span>
                                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer" onClick={() => setError(null)}>
                                        <svg className="fill-current h-6 w-6 text-red-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <title>Close</title>
                                            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 0 1 1.697 0c.461.486.461 1.211 0 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                        </svg>
                                    </span>
                                </div>
                            )}

                            <div className="mb-3 relative">
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <AiOutlineMail className="absolute top-3 left-3 text-emerald-500" size="1.25em" />
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="text-xs sm:text-sm block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    placeholder="Email address"
                                    value={signupData.email}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-4 relative">
                                <label htmlFor="country" className="sr-only">Country</label>
                                <Select
                                    id="country"
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    options={countries}
                                    isSearchable={true}
                                    className="text-xs sm:text-sm block w-full pl-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    styles={{ menu: base => ({ ...base, textAlign: 'left' }) }}
                                    placeholder="Select your country"
                                />
                            </div>

                            <div className="mb-4 relative">
                                <label htmlFor="phone-number" className="sr-only">Phone Number</label>
                                <PhoneInput
                                    country={selectedCountry.value.toLowerCase()} // Sync the flag with the selected country
                                    value={signupData.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    inputStyle={{
                                        width: '100%',
                                        paddingLeft: '50px',
                                        height: '40px',
                                        fontSize: '14px',
                                    }}
                                    containerStyle={{ width: '100%' }}
                                    dropdownStyle={{ textAlign: 'left' }}
                                    inputProps={{
                                        name: 'phoneNumber',
                                        required: true,
                                        autoFocus: false,
                                    }}
                                    enableSearch={true}
                                    disableCountryCode={false}
                                    disableDropdown={true}
                                />
                                {phoneError && (<span className="text-red-600 text-xs mt-1 block">{phoneError}</span>)}
                            </div>

                            <div className="mb-4 relative">
                                <label htmlFor="password" className="sr-only">Password</label>
                                <AiOutlineLock className="absolute top-3 left-3 text-emerald-500" size="1.25em" />
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    className="text-xs sm:text-sm block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    placeholder="Password"
                                    value={signupData.password}
                                    onChange={handleInputChange}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute right-3 top-3 text-emerald-500"
                                >
                                    {showPassword ? <AiOutlineEyeInvisible size="1.25em" /> : <AiOutlineEye size="1.25em" />}
                                </button>
                            </div>

                            <div className="mb-4 relative">
                                <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                                <AiOutlineLock className="absolute top-3 left-3 text-emerald-500" size="1.25em" />
                                <input
                                    id="confirm-password"
                                    name="confirmPassword"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    required
                                    className="text-xs sm:text-sm block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500"
                                    placeholder="Confirm Password"
                                    value={signupData.confirmPassword}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-xs sm:text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 ${!validPhone ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                    disabled={!validPhone}
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        {loading ? <FaSpinner className="h-5 w-5 text-white animate-spin" /> : null}
                                    </span>
                                    {loading ? 'Signing up...' : 'Sign up'}
                                </button>
                            </div>
                            <div className="text-center">
                                <span className="text-xs sm:text-sm text-white">Already have an account?</span>
                                <Link to="/login" className="font-medium text-emerald-600 hover:text-emerald-500 underline ml-1">
                                    Login
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
