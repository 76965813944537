import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Lottie from "lottie-react";
import loadingAnimation from '../../lottie/loading.json';
import noDataAnimation from '../../lottie/noLinks.json';
import { useUser } from "../../context";
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';

const ConvertHistory = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [conversions, setConversions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!user || !user.token) {
            navigate('/login');
        }
    }, [user, navigate]);

    useEffect(() => {
        const fetchConversions = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/conversions', {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                setConversions(response.data);
            } catch (error) {
                console.error("Error fetching conversions: ", error);
            }
            setIsLoading(false);
        };

        fetchConversions();
    }, [user.token]);

    const getStatusIcon = () => {
        return <FaCheckCircle className="text-green-500" />;
    };

    const getStatusBackground = () => {
        return 'bg-green-50';
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg text-center">
            {isLoading ? (
                <div className="flex justify-center items-center py-4">
                    <Lottie animationData={loadingAnimation} style={{ width: 100, height: 100 }} />
                </div>
            ) : conversions.length > 0 ? (
                conversions.map((conversion, index) => (
                    <div
                        key={index}
                        className={`flex flex-row items-center justify-between p-2 mt-3 border rounded ${getStatusBackground()}`}
                    >
                        <div className="flex items-center space-x-3">
                            <div className="p-2 rounded-full bg-white shadow">
                                {getStatusIcon()}
                            </div>
                            <div className="text-left">
                                <p className="font-semibold text-sm">{conversion.transactionId}</p>
                                <p className="text-xs text-gray-500">
                                    {conversion.fromAmount} {conversion.fromCurrency} → {conversion.toAmount} {conversion.toCurrency}
                                </p>
                                <div className="flex items-center space-x-2 text-xs text-gray-500">
    <FiClock className="text-gray-400" />
    <p>
        {new Date(conversion.createdAt).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        })}
    </p>
</div>

                            </div>
                        </div>
                        <button
                            onClick={() => navigate(`/conversion-details/${conversion.transactionId}`)}
                            className="px-4 py-1 text-xs border border-emerald-500 text-emerald-500 rounded hover:bg-emerald-100 hover:text-emerald-600 ml-4"
                        >
                            View
                        </button>
                    </div>
                ))
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <Lottie animationData={noDataAnimation} style={{ width: 200, height: 200 }} />
                    <p className="text-gray-500 font-semibold mt-4">
                        No Conversion Records Found
                    </p>
                </div>
            )}
        </div>
    );
};

export default ConvertHistory;
