import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import api from '../../api';
import Lottie from 'lottie-react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { FaSpinner } from 'react-icons/fa';
import successAnimation from '../lottie/success-animation.json';
import successConfetti from '../lottie/success-confetti.json';
import phoneAnimation from '../lottie/change_phone_lottie.json';

const ChangeNumber = () => {
  const navigate = useNavigate();
  const locationState = useLocation();
  const [newPhone, setNewPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!locationState.state?.phoneNumber) {
      setError('Old phone number not loaded. Please try again.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await api.post('/api/auth/change-phone', {
        oldPhone: locationState.state.phoneNumber,
        newPhone,
      });

      if (response.status === 200) {
        setLoading(false);
        setSuccess(true);

        setTimeout(() => {
          navigate('/phone-verify', { state: { phoneNumber: newPhone } });
        }, 5000);
      } else {
        setLoading(false);
        setError('Failed to change phone number. Please try again.');
      }
    } catch (error) {
      setLoading(false);

      if (error.response?.status === 409) {
        setError('This phone number is already in use. Please try another.');
      } else {
        setError(
          error.response?.data?.message ||
            'Failed to change phone number. Please try again.'
        );
      }
    }
  };

  if (success) {
    return (
      <div className="flex flex-col items-center justify-center w-full p-4 bg-gradient-to-b from-emerald-100 to-white min-h-screen">
        <Confetti />
        <Lottie
          animationData={successConfetti}
          style={{ width: 250, height: 250 }}
        />
        <Lottie
          animationData={successAnimation}
          style={{ width: 200, height: 200 }}
        />
        <p className="text-2xl font-semibold text-emerald-700 mt-6 text-center">
          Your Phone Number Has Been Successfully Changed!
        </p>
        <button
          onClick={() =>
            navigate('/phone-verify', { state: { phoneNumber: newPhone } })
          }
          className="mt-8 px-6 py-3 rounded-full bg-emerald-500 text-white hover:bg-emerald-600 transition duration-300 shadow-lg transform hover:scale-105"
        >
          Proceed to Verification
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-6 bg-gradient-to-r from-green-200 to-blue-200">
      <div className="max-w-lg w-full space-y-6 bg-white p-8 rounded-2xl shadow-2xl">
        <div className="text-center">
          <Lottie
            animationData={phoneAnimation}
            style={{ width: 200, height: 200, margin: 'auto' }}
          />
          <h2 className="mt-2 text-xl font-extrabold text-gray-900">
            Update Your Phone Number
          </h2>
          <p className="mt-2 text-gray-600">
            Please enter your new phone number below.
          </p>
        </div>
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">Error! </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="oldPhone"
              className="block text-sm font-medium text-gray-700"
            >
              Current Phone Number
            </label>
            <input
              id="oldPhone"
              type="text"
              value={locationState.state?.phoneNumber || ''}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700 cursor-not-allowed"
              readOnly
            />
          </div>
          <div>
            <label
              htmlFor="newPhone"
              className="block text-sm font-medium text-gray-700"
            >
              New Phone Number
            </label>
            <input
              id="newPhone"
              type="tel"
              placeholder="(123) 456-7890"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 transition duration-300"
              required
            />
          </div>
          <div className="flex justify-between space-x-4 mt-8">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex-1 px-4 py-2 rounded-full border border-gray-300 text-gray-700 hover:bg-gray-100 transition duration-300"
            >
              Back
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`flex-1 px-4 py-2 rounded-full ${
                loading ? 'bg-emerald-300' : 'bg-emerald-600'
              } text-white flex items-center justify-center hover:bg-emerald-700 transition duration-300 shadow-lg transform hover:scale-105`}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Updating...
                </>
              ) : (
                'Update'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeNumber;
