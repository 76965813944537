import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import BankWithdrawal from './BankWithdrawal';
import PaypalWithdrawal from './PaypalWithdrawal';
import MobileWithdrawal from './MobileWithdrawal';
import BonusWithdrawal from './BonusWithdrawal';
import { useUser } from "../../context";

const Withdraw = () => {
    const { user } = useUser();
    const [activeTab, setActiveTab] = useState('bank');
    const navigate = useNavigate();
    const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
    const userBalance = user?.balance || 0;
    const userCountry = user?.country;

    // Set default active tab based on user's country
    useEffect(() => {
        const preferredCountries = ['KE', 'ZM', 'UG'];
        if (preferredCountries.includes(userCountry)) {
            setActiveTab('mobile');
        } else {
            setActiveTab('bank');
        }
    }, [userCountry]);

    useEffect(() => {
        if (userBalance > 50) {
            setIsBonusModalOpen(true);
        }
    }, [userBalance]);

    const closeModal = () => {
        setIsBonusModalOpen(false);
    };

    const tabOptions = [
        { label: 'Bank Transfer', value: 'bank' },
        { label: 'PayPal', value: 'paypal' },
        { label: 'Mobile Money', value: 'mobile' },
    ];

    const renderComponent = () => {
        switch (activeTab) {
            case 'bank':
                return <BankWithdrawal />;
            case 'paypal':
                return <PaypalWithdrawal />;
            case 'mobile':
                return <MobileWithdrawal />;
            case 'bonus':
                return <BonusWithdrawal />;
            default:
                return <BankWithdrawal />;
        }
    };

    const goToBonusWithdrawal = () => {
        setActiveTab('bonus');
        closeModal();
    };

    const goToHelpPage = () => {
        navigate('/help');
    };

    return (
        <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
            {/* Dropdown for small screens */}
            {activeTab !== 'bonus' && (
                <div className="flex flex-col sm:flex-row mb-4 border-b">
                    <Menu as="div" className="relative sm:hidden w-full mb-4">
                        <Menu.Button className="flex justify-between w-full px-4 py-2 border rounded text-sm font-medium text-gray-700 transition-colors duration-300 hover:bg-emerald-50">
                            {tabOptions.find(tab => tab.value === activeTab)?.label || 'Select Withdrawal Method'}
                            <ChevronDownIcon
                                className="w-5 h-5 ml-2 text-emerald-500 animate-bounce"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                        <Menu.Items className="absolute w-full mt-1 bg-white border rounded shadow-lg">
                            {tabOptions.map(tab => (
                                <Menu.Item key={tab.value}>
                                    {({ active }) => (
                                        <button
                                            onClick={() => setActiveTab(tab.value)}
                                            className={`${active ? 'bg-emerald-100' : ''
                                                } w-full text-left px-4 py-2 text-sm text-gray-700`}
                                        >
                                            {tab.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </Menu>

                    {/* Tabs for larger screens */}
                    <div className="hidden sm:flex space-x-4">
                        {tabOptions.map(tab => (
                            <button
                                key={tab.value}
                                onClick={() => setActiveTab(tab.value)}
                                className={`py-2 px-4 font-medium text-sm sm:text-base ${activeTab === tab.value
                                        ? 'border-b-2 border-emerald-500 text-emerald-600'
                                        : 'border-transparent hover:border-gray-300 hover:text-gray-600'
                                    }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}

           {/* Modal for Bonus Alert */}
{isBonusModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="relative bg-gradient-to-br from-transparent via-emerald-100 to-transparent bg-opacity-90 backdrop-filter backdrop-blur-md border border-emerald-200 rounded-2xl shadow-2xl p-8 max-w-md mx-auto">
            {/* Close button */}
            <button onClick={closeModal} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            {/* Content */}
            <div className="text-center">
               
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">Bonus About to Expire!</h2>
                <p className="text-gray-600 mb-6">Your bonus balance is about to expire. Withdraw it now to make the most of your rewards!</p>
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={goToBonusWithdrawal}
                        className="bg-gradient-to-r from-emerald-500 to-green-600 hover:from-emerald-600 hover:to-green-700 text-white py-2 px-6 rounded-full shadow-lg transition duration-200 ease-in-out text-sm font-medium"
                    >
                        Withdraw Now
                    </button>
                    <button
                        onClick={closeModal}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-6 rounded-full shadow-lg transition duration-200 ease-in-out text-sm font-medium"
                    >
                        Later
                    </button>
                </div>
            </div>
        </div>
    </div>
)}

            {/* Withdrawal Component */}
            {renderComponent()}

            {/* Help Center Link */}
            <div className="text-center mt-6">
                <p className="text-gray-600 text-sm mb-2">Need assistance with your transaction?</p>
                <button onClick={goToHelpPage} className="text-emerald-500 hover:text-emerald-600 text-sm underline">
                    Visit our Help Center
                </button>
            </div>

            {/* Additional Image for Small Devices, visible only for KE users */}
            {userCountry === 'KE' && (
                <div className="flex justify-center md:hidden lg:hidden">
                    <img
                        src="https://res.cloudinary.com/dx6jw8k0m/image/upload/v1722095939/Yellow_Colorful_Steps_to_Order_Online_Instagram_Post_odlky6.png"
                        alt="Transfer Icon"
                        className="w-70 h-auto mb-1"
                    />
                </div>
            )}
        </div>
    );
};

export default Withdraw;
